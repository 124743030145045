import React, {Fragment, useState, useEffect, useCallback} from 'react';
import {connect} from 'react-redux';
import Masonry from 'react-masonry-component';

import {useHistory, useParams} from 'react-router-dom';
import ServiceActions from '../../redux/actions/service';

import {
    HeaderList,
    CardProduct,
    Filter,
    Empty,
    Spinner,
    DropdownChangeName,
    DropdownChangePassword,
    DropdownConfirm
} from '@components';

const Products = ({
                      locale,
                      GetProductsCategories,
                      GetProducts,
                      CreateCase,
                      productsCategories,
                      products,
                      currency,
                      configuration,
                      visibilityBot,
                      ChangeVisibilityBot,
                      isReady,
                      ResetProductsReady,
                      ResetProductsCategoriesReady,
                  }) => {
    const [productsList, setProducts] = useState([]);

    const [subactionShow, setSubactionsShow] = useState(false);
    const [subactionTemplate, setsubactionTemplate] = useState(null);

    const [dropdownState, setDropdownState] = useState(false);

    const dropItemHandler = (item) => {
        if (item.url) {
            console.log(item);
            window.location.assign(item.url);
        }
    };

    const [isVisibleSpinner, setVisibleSpinner] = useState('');
    const history = useHistory();
    const params = useParams();
    const masonryOptions = {
        transitionDuration: 0,
    };
    const setFilter = useCallback((id) => {
        const goTo = (url) => {
            history.push(url);
        };
        let productsFiltered;

        if (id) {
            productsFiltered = products.filter((item) => item.metadata.category === id);

            setProducts(productsFiltered);
        } else {
            goTo('/products');
            setProducts(products);
        }
    }, [history, products]);
    const clickAskLawyer = () => {
        if (visibilityBot) {
            ChangeVisibilityBot(false);
        } else {
            ChangeVisibilityBot(true);
        }
    };
    useEffect(() => {
        GetProductsCategories();
        GetProducts();
    }, [GetProducts, GetProductsCategories]);

    useEffect(() => {
        if (params.categoryId) {
            setFilter(params.categoryId);
        } else {
            setProducts(products);
        }
    }, [params.categoryId, products, setFilter]);

    useEffect(() => {
        document.title = locale ? locale.tabs.legalServices : 'Legal Desk';
    }, [locale]);

    useEffect(() => {
        ResetProductsReady();
        ResetProductsCategoriesReady();
    }, [ResetProductsCategoriesReady, ResetProductsReady]);

    let optionsDropdown;

    if (locale) {
        optionsDropdown = {
            iconClass: 'b-icon b-icon__menu b-icon__menu_profile',
            isIcon: true,
            title: 'Actions',
            right: 0,
            subactionShow,
            subactionWidth: '320px',
            subactionTemplate,
            top: '100%',
            items: [
                {
                    title: locale.dropmenu.items.changeName,
                    isIcon: false,
                    subactionTemplate: (
                        <DropdownChangeName
                            locale={locale}
                            setDropdownState={setDropdownState}
                            configuration={configuration}
                            backOnClick={() => setSubactionsShow(false)}
                        />
                    ),
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    },
                },
                {
                    title: locale.dropmenu.items.changePassword,
                    isIcon: false,
                    subactionTemplate: (
                        <DropdownChangePassword
                            locale={locale}
                            setDropdownState={setDropdownState}
                            backOnClick={() => setSubactionsShow(false)}
                        />
                    ),
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    },
                },
                {
                    action: dropItemHandler,
                    url: '/admin/products',
                    isIcon: false,
                    title: locale.dropmenu.items.admin,
                    hidden: !configuration.user.isAdmin,
                },
                {
                    title: locale.dropmenu.items.logOut,
                    isIcon: false,
                    subactionTemplate: (
                        <DropdownConfirm
                            title={locale.dropmenu.items.logOut}
                            cancelText={locale.buttons.cancel}
                            saveText={locale.dropmenu.items.logOut}
                            message={locale.authentication.logout.confirm.replace(
                                /%name%/gi,
                                `<b>${configuration.user.first_name} ${configuration.user.last_name}</b>`
                            )}
                            backOnClick={() => setSubactionsShow(false)}
                            saveOnClick={() => {
                                setSubactionsShow(false);
                                setDropdownState(false);
                                window.location.href = '/authentication/account/logout';
                            }}
                        />
                    ),
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    },
                },
            ],
        };
    }

    return (
        <div>
            {locale && locale.card && (
                <Fragment>
                    <HeaderList
                        dropdownState={dropdownState}
                        setDropdownState={setDropdownState}
                        setSubactionsShow={setSubactionsShow}
                        onClick={() => clickAskLawyer()}
                        nameBtn={locale.tabs.askLawyer}
                        activeBtn={visibilityBot}
                        tabs={[
                            {
                                active: true,
                                name: locale.tabs.myCases,
                                url: '/cases',
                            },
                            {
                                active: false,
                                name: locale.tabs.legalServices,
                                url: '/products',
                            },
                            {
                                active: false,
                                name: locale.tabs.profile,
                                url: '/profile',
                            },
                        ]}
                        optionsDropdown={optionsDropdown}
                    />
                    <div className={visibilityBot ? 'l-content l-content__fill' : 'l-content'}>
                        <div className="b-content m-list__padding">
                            {isReady.productsCategories && productsCategories && (
                                <div className="b-filter">
                                    <ul className="b-filter-list">
                                        <li className="b-filter-item">
                                            <a
                                                onClick={() => setFilter()}
                                                className={
                                                    history.location.pathname === '/products'
                                                        ? 'b-filter-link b-filter-link__active'
                                                        : 'b-filter-link'
                                                }
                                            >
                                                {locale.filter.all}
                                            </a>
                                        </li>

                                        {productsCategories.map((item, index) => (
                                            <Filter tab={item} path="/products" onClick={() => setFilter(item._id)}/>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="b-content-card-wrapper">
                                {isReady.products && isReady.productsCategories && (
                                    <Masonry className="b-columns" options={masonryOptions} elementType={'ul'}>
                                        {productsList.length ? (
                                            productsList.map((item, index) => (
                                                <CardProduct
                                                    button={locale.card.getStarted}
                                                    soon={locale.card.soon}
                                                    currency={currency}
                                                    product={item}
                                                    onClick={() => {
                                                        CreateCase(item._id)
                                                            .then((data) => {
                                                                if (data) {
                                                                    setVisibleSpinner(false);
                                                                }
                                                            })
                                                            .catch((err) => {
                                                                console.log(err);
                                                            });
                                                        setVisibleSpinner(true);
                                                    }}
                                                />
                                            ))
                                        ) : (
                                            <Empty
                                                title={locale.card.emptyProductList}
                                                text={locale.card.emptyProductListDescription}
                                            />
                                        )}
                                    </Masonry>
                                )}
                            </div>
                        </div>
                    </div>
                    {isVisibleSpinner && <Spinner title={locale.card.loading}/>}
                </Fragment>
            )}
        </div>
    );
};

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        productsCategories: store.productsCategories,
        products: store.products,
        lang: store.lang,
        currency: store.currency,
        configuration: store.configuration,
        visibilityBot: store.visibilityBot,
        isReady: store.isReady,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetProductsCategories: () => dispatch(ServiceActions.GetProductsCategories()),
        GetProducts: () => dispatch(ServiceActions.GetProducts()),
        CreateCase: (productId) => dispatch(ServiceActions.CreateCase(productId)),
        ChangeVisibilityBot: (visibilityBot) => dispatch(ServiceActions.ChangeVisibilityBot(visibilityBot)),
        ResetProductsReady: () => dispatch(ServiceActions.SetProductsReady(false)),
        ResetProductsCategoriesReady: () => dispatch(ServiceActions.SetProductsCategoriesReady(false)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
