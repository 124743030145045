import React from 'react';
import {useFormik} from 'formik';
import {TableAdminLanguage, Title} from '@components';

const FormLanguageAdmin = ({locale, language, settings, onSubmit}) => {
    const {values, handleChange, handleSubmit} = useFormik({
        initialValues: {
            locale: language,
        },
        onSubmit: (values) => {
            onSubmit(settings.locale, values);
        },
    });

    return (
        <div>
            <Title
                title={locale.admin.menu.languages}
                buttonText={locale.admin.popup.actions.save}
                buttonClick={handleSubmit}
                buttonIcon='save'
            />
            {values.locale ? (
                <div className="b-form">
                    {values.locale.map((item, key) => (
                        <TableAdminLanguage key={key} localeKey={key} item={item} locale={locale} onChange={handleChange}/>
                    ))}
                </div>
            ) : (
                <></>
            )}
        </div>
    );
};

export default FormLanguageAdmin;
