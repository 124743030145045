import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import {Formatter} from '@utils';

const CardProduct = ({currency, product, soon, button, onClick}) => {

    const clickCard = (productId) => {
        onClick(productId);
    }
    return (
        <Fragment>
            <li className="b-columns-item">
                <a className="b-block b-block__card b-block__with_button masonry-content"
                   onClick={() => clickCard(product._id)}>
                    <div className="b-block-title">
                        {product.name}
                    </div>
                    {product?.price?.length && product?.price[0].price ? product.price.map((item, index) => (
                        <div className="b-block-price">
                            {Formatter.formatPrice(item, currency)}
                        </div>
                    )) : product.metadata.heading ? (
                        <div className="b-block-price">
                            {product.metadata.heading}
                        </div>
                    ) : (
                        <div className="b-block-price">
                            {soon}
                        </div>
                    )}
                    <div className="b-block-text">
                        {product.description}
                    </div>
                    <div className="b-button">
                        {button}
                    </div>
                </a>
            </li>
        </Fragment>
    )
}

CardProduct.propTypes = {
    /** Валюта rub | usd | eur */
    currency: PropTypes.string,
    /** Данные о продукте */
    product: PropTypes.object,
    /** Флаг "coming soon" */
    soon: PropTypes.string,
    /** Текст кнопки */
    button: PropTypes.string,
    /** Функция клика на карточку продукта */
    onClick: PropTypes.string
};

CardProduct.defaultProps = {
    currency: null,
    product: null,
    soon: null,
    button: null,
    onClick: null
};


export default CardProduct;
