import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

const Filter = ({tab, path, onClick}) => {
    const history = useHistory();
    const location = useLocation();
    const routeMatch = () => {
        if (tab._id) {
            return location.pathname.indexOf(tab._id) !== -1;
        } else {
            return location.pathname === path;
        }
    };
    const goTo = (tab) => {
        if (tab._id) {
            onClick(tab)
            history.push(`${path}/type/${tab._id}`);
        } else {
            history.push(`${path}`);
        }
    }

    return (
        <li className="b-filter-item">
            <a className={routeMatch() ? 'b-filter-link__active b-filter-link' : 'b-filter-link'}
               onClick={() => goTo(tab)}>
                {tab.name}
            </a>
        </li>
    )
}

export default Filter;
