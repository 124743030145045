import React, { Fragment, useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useOutsideClick } from '@utils';
import PropTypes from 'prop-types';

import { FixedSizeList as List } from 'react-window';

/**
 * Динамический селект.
 * Извлекает данные по переданному относительному URL и использует их в качестве options.
 *
 * @param url Относительная ссылка на API, по которой будут извлекаться значения
 * @param value
 * @param placeholder
 * @param setFieldValue Сеттер значения поля для Formik. Если передан, то и name также должно быть передано
 * @param name Имя поля в Formik. Обязательно, если используется setFieldValue
 * @param disabled boolean блокирует либо разблокирует селект
 * @param error boolean устанавливается formik используется при валидации
 * @returns {JSX.Element}
 * @constructor
 */
const SelectDynamic = ({ placeholder, url, value, setFieldValue, name, disabled, error }) => {
    const ref = useRef();
    const refButton = useRef();
    const [selectValue, setSelectValue] = useState(value);
    const [inputValue, setInputValue] = useState('');
    const [selectActive, setSelectActive] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState('');
    const [start, setStart] = useState(false);

    const [valuesFiltred, setValuesFiltred] = useState([]);

    const params = {
        params: {
            page: 1,
        },
    };

    useEffect(() => {
        setSelectValue(value);
    }, [value]);

    let count = 2,
        list = [];
    // Хук для обработки клика вне элемента
    useOutsideClick(ref, refButton, selectActive, () => {
        setSelectActive(false);
        setInputValue('');
        setLoading('');
    });

    const filterValues = (value) => {
        if (start) {
            let filtred;

            filtred = dataList.filter(
                (item) => (item.first_name + ' ' + item.last_name).toLowerCase().indexOf(value.toLowerCase()) + 1
            );
            setValuesFiltred(filtred);
        }
    };

    const Row = ({ index, isScrolling, style }) => (
        <li
            style={style}
            key={valuesFiltred[index]._id}
            className={
                selectValue && valuesFiltred[index]._id === selectValue._id
                    ? 'b-input-list-item active'
                    : 'b-input-list-item'
            }
            onClick={() => {
                setSelectValue(valuesFiltred[index]);
                setInputValue('');
                setSelectActive(false);
                setFieldValue(name, valuesFiltred[index]);
                setLoading('');
            }}
        >
            {valuesFiltred[index].first_name + ' ' + valuesFiltred[index].last_name}
        </li>
    );

    const getDataList = (url, params) => {
        setLoading('loading');
        axios
            .get(url, params)
            .then(({ data }) => {
                list = [...list, ...data.list];

                if (count > data.pages) {
                    setLoading('');
                    setStart(true);
                }

                if (count <= data.pages) {
                    params.params.page = count;
                    getDataList(url, params);
                    count++;
                }

                setDataList(list);
                setValuesFiltred(list);
            })
            .finally(() => {
                setLoading('');
            })
            .catch((err) => {
                console.log(err);
            });
    };

    return (
        <Fragment>
            <div
                ref={refButton}
                onClick={() => {
                    if (disabled) {
                        return false;
                    }
                    if (selectActive === false) {
                        setSelectActive(true);
                    }
                    if (!dataList.length) {
                        getDataList(url, params);
                    }
                }}
                className={
                    selectValue
                        ? selectActive
                            ? `b-selectbox edit active not-empty search ${loading}`
                            : `b-selectbox edit ${loading} not-empty`
                        : selectActive
                        ? `b-selectbox edit active search ${loading}`
                        : disabled
                        ? `b-selectbox ${error}`
                        : `b-selectbox edit ng-empty ${error}`
                }
            >
                <i className="b-icon b-icon__down" />
                {(selectActive || selectValue) && <div className="b-selectbox-subtitle">{placeholder}</div>}

                <div className="b-selectbox-title">
                    {selectValue ? `${selectValue.first_name} ${selectValue.last_name}` : !loading ? placeholder : ''}
                </div>
                <i
                    className="b-icon b-icon__down js-selectbox-icon"
                    onClick={() => {
                        setSelectActive(false);
                    }}
                />
                <i className="b-icon b-icon__clear_input js-selectbox-icon-clear" />
                <input
                    type="text"
                    className="b-selectbox-search js-selectbox-search"
                    onChange={(e) => {
                        filterValues(e.target.value);
                        setInputValue(e.target.value);
                    }}
                    value={inputValue}
                />
                <div className="b-spinner-input" />
                {dataList[0] && (
                    <div className="b-input-list" ref={ref}>
                        <ul className="b-input-list-inner">
                            {valuesFiltred.length ? (
                                <List
                                    className="List"
                                    itemCount={valuesFiltred.length}
                                    itemSize={40}
                                    height={416}
                                    width={1360}
                                >
                                    {Row}
                                </List>
                            ) : (
                                <div className="b-selectbox-list-empty">No matches</div>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

SelectDynamic.propType = PropTypes.shape({
    url: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    name: PropTypes.string,
    setFieldValue: PropTypes.func,
});

export default SelectDynamic;
