import React, {Fragment} from 'react';

import {
    Input,
    Spinner
} from '@components';

const FormProductAdmin = ({locale, formik, loading}) => {
    return (
        <Fragment>
            {loading && (
                <div className="b-modal-spinner">
                    <Spinner
                        title={locale.card.loading}
                    />
                </div>
            )}
            {locale && !loading && (<div className="b-form">
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__12">
                        <Input
                            name="name"
                            type="text"
                            error={!!formik.errors?.name}
                            placeholder={locale.admin.popup.product.title}
                            {...formik.getFieldProps('name')}
                        />
                    </div>
                </div>
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__12">
                        <Input
                            name="description"
                            type="text"
                            placeholder={locale.admin.popup.product.description}
                            {...formik.getFieldProps('description')}
                        />
                    </div>
                </div>
            </div>)}
        </Fragment>
    )
}

export default FormProductAdmin;
