import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const Title = ({title, buttonText, buttonClick, buttonIcon = 'create'}) => {
    const buttonClass = `b-button-${buttonIcon}`;

    return (
        <Fragment>
            <div className="b-block b-block__flex">
                <div className="b-block-title">{title}</div>
                {buttonText && (
                    <button className={buttonClass} onClick={buttonClick} type="button">
                        {buttonText}
                    </button>
                )}
            </div>
        </Fragment>
    );
};
Title.propTypes = {
    /** Текст заголовка */
    title: PropTypes.string,
    /** Текст кнопки (если != null будет отображаться кнопка справа в строке заголовка) */
    buttonText: PropTypes.string,
    /** Функция, которая вызывается при нажатии на кнопку */
    buttonClick: PropTypes.func,
    buttonIcon: PropTypes.string,
};

Title.defaultProps = {
    title: '',
    buttonText: null,
    buttonClick: null,
};

export default Title;
