import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { caseFilterSelector } from '../../redux/selectors/service/caseFilter';
import ServiceActions from '../../redux/actions/service';
import CaseFilterDictionaryLoader from './CaseFilterDictionaryLoader';

const CaseFilterLoader = ({ caseFilter, actions }) => {
    useEffect(() => {
        actions.LoadCaseFilter();
    }, []);

    return caseFilter ? <CaseFilterDictionaryLoader filters={caseFilter} /> : <></>;
};

export default connect(
    (store) => ({
        locale: store.locale,
        caseFilter: caseFilterSelector(store),
    }),
    (dispatch) => {
        return {
            actions: {
                LoadCaseFilter: () => dispatch(ServiceActions.LoadCaseFilter()),
            },
        };
    }
)(CaseFilterLoader);
