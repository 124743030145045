import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom';

import {ServiceActions} from '@redux';

import {
    HeaderCard,
    Document,
    Bot,
    DropdownConfirm
} from '@components'

import {CardServiceContainer} from '@containers';

const Service = ({locale, lang, configuration, caseItem, caseSummary, GetCaseItem, GetCaseSummary, DeleteCase, ToArchiveCase}) => {
    const params = useParams();

    const [subactionShow, setSubactionsShow] = useState(false);
    const [dropdownState, setDropdownState] = useState(false);
    const [subactionTemplate, setsubactionTemplate] = useState(null);

    const getTitleHeader = () => {
        let title = `${caseItem.user.first_name} ${caseItem.user.last_name}`;

        if (lang === 'ru') {
            title += `. ${caseItem.name}`;
        } else {
            title += `'s ${caseItem.name}`;
        }

        return title;
    }

    if (caseItem) {
        document.title = caseItem && caseItem.number ? caseItem.number : (caseItem?.name && caseItem ? caseItem?.name : 'LD');
    }

    useEffect(() => {
        document.title = locale && caseItem ? caseItem.number : (caseItem?.name && caseItem ? caseItem?.name : 'LD');
    }, [caseItem, locale]);

    useEffect(() => {
        if (params.id) {
            GetCaseItem(params.id);
        }
    }, [GetCaseItem, params]);

    useEffect(() => {
        GetCaseSummary();
    }, [GetCaseSummary])


    useEffect(() => {
        if (params.id) {
            caseItem = GetCaseItem(params.id);
        }
    }, [caseSummary, params])

    let optionsDropdown;

    if (locale && caseItem) {
        optionsDropdown = {
            title: 'Actions',
            isIcon: true,
            right: 12,
            subactionShow,
            subactionWidth: '320px',
            subactionTemplate,
            items: [
                {
                    title: locale.dropmenu.items.archive,
                    isIcon: false,
                    hidden: caseItem.isArchive,
                    subactionTemplate: <DropdownConfirm
                        title={locale.dropmenu.items.archive}
                        cancelText={locale.buttons.cancel}
                        saveText={locale.buttons.archive}
                        message={locale.archiveDescription.replace(/%number%/gi, caseItem.number).replace(/%name%/gi, caseItem.name)}
                        backOnClick={() => setSubactionsShow(false)}
                        saveOnClick={() => {
                            ToArchiveCase(caseItem._id);
                            setSubactionsShow(false);
                            setDropdownState(false);
                            setTimeout(() => {
                                window.location.href = '/cases';
                            }, 2000)
                        }}/>,
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    }
                },
                {
                    title: locale.dropmenu.items.delete,
                    isIcon: false,
                    subactionTemplate: <DropdownConfirm
                        title={locale.dropmenu.items.delete}
                        cancelText={locale.buttons.cancel}
                        saveText={locale.buttons.delete}
                        message={locale.deleteDescription.replace(/%number%/gi, caseItem.number).replace(/%name%/gi, caseItem.name)}
                        backOnClick={() => setSubactionsShow(false)}
                        saveOnClick={() => {
                            DeleteCase(caseItem._id);
                            setSubactionsShow(false);
                            setDropdownState(false);
                            setTimeout(() => {
                                window.location.href = '/cases';
                            }, 2000)
                        }}/>,
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    }
                }
            ]
        }
    }

    return (
        <div>
            {caseItem && locale && locale.card && (
                <Fragment>
                    {locale && caseItem && optionsDropdown && (
                        <HeaderCard
                            number={caseItem.number}
                            optionsDropdown={optionsDropdown}
                            title={getTitleHeader()}
                            dropdownState={dropdownState}
                            setDropdownState={setDropdownState}
                            setSubactionsShow={setSubactionsShow}
                            status={!caseItem.customStatus ? '' : caseItem.customStatus.name}
                        />
                    )}
                    {caseItem && caseItem.product.metadata.serviceName === 'doc.one' ? (
                        <div className="l-content">
                            <div className="b-content b-content__full_width">
                                <div className="b-content-wrapper">
                                    <Document
                                        configuration={configuration}
                                        documentId={caseItem.documentId}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : caseItem && caseItem.product.metadata.serviceName === 'form.one.bot' ? (
                        <div className="l-content  l-content__fill_bot">
                            <div className="b-content">
                                <div className="b-content-wrapper">
                                    <Bot
                                        configuration={configuration}
                                        isVisibleBot={false}
                                        isNotFixed={false}
                                        isBotOnly={true}
                                        caseDialogId={caseItem.dialogId}/>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="l-content  l-content__fill">
                            <div className="b-content">
                                <div className="b-content-card-wrapper">
                                    <CardServiceContainer locale={locale} caseItem={caseItem}
                                                          configuration={configuration}/>
                                </div>
                            </div>
                            <div className="b-bot__wrapper b-bot__wrapper--alwaysVisible">
                                <Bot
                                    configuration={configuration}
                                    isNotFixed={true}
                                    caseDialogId={caseItem.dialogId}/>
                            </div>
                        </div>
                    )}
                </Fragment>
            )}
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        lang: store.lang,
        configuration: store.configuration,
        caseItem: store.caseItem,
        caseSummary: store.caseSummary
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        GetCaseItem: (id) => dispatch(ServiceActions.GetCaseItem(id)),
        DeleteCase: (caseId) => dispatch(ServiceActions.DeleteCase(caseId)),
        ToArchiveCase: (caseId) => dispatch(ServiceActions.ToArchiveCase(caseId)),
        GetCaseSummary: () => dispatch(ServiceActions.GetCaseSummary())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Service);
