import React from 'react';
import PropTypes from 'prop-types';

const PaginationTable = ({
                             data,
                             tableRenderer,
                             page,
                             totalItems,
                             loading,
                             locale,
                             lang,
                             totalPages,
                             itemsOnPage,
                             onNextPageClick,
                             onPrevPageClick,
                         }) => {
    const prevPage = page - 1 <= 1 ? 1 : page - 1;
    const nextPage = page + 1 <= totalPages ? page + 1 : totalPages;
    const startCounter = page === 1 ? page : page * itemsOnPage - itemsOnPage + 1;
    let endCounter = page === 1 ? page * itemsOnPage : page * itemsOnPage;
    endCounter = endCounter >= totalItems ? totalItems : endCounter;

    const onClickNextPage = (event) => {
        onNextPageClick(event, nextPage);
    };

    const onClickPrevPage = (event) => {
        onPrevPageClick(event, prevPage);
    };

    return (
        <>
            {!loading && (
                <div className="b-admin-pagination-table">
                    <div className="b-admin-pagination-table-data">{tableRenderer(data, locale, lang)}</div>
                    <div className="b-admin-pagination-table-navigation">
                        <div className="b-admin-pagination-table-navigation-counter">
                            {startCounter} - {endCounter} {locale.component.table.of} {totalItems}
                        </div>
                        <div className="b-admin-pagination-table-navigation-buttons">
                            <button
                                className="b-admin-pagination-table-navigation-buttons-prev"
                                onClick={onClickPrevPage}
                            />
                            <button
                                className="b-admin-pagination-table-navigation-buttons-next"
                                onClick={onClickNextPage}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

PaginationTable.propTypes = {
    data: PropTypes.array,
    tableRenderer: PropTypes.func,
    totalItems: PropTypes.number,
    page: PropTypes.number,
    totalPages: PropTypes.number,
    itemsOnPage: PropTypes.number,
    loading: PropTypes.bool,
    locale: PropTypes.object,
    lang: PropTypes.string,
    onNextPageClick: PropTypes.func,
    onPrevPageClick: PropTypes.func,
};

export default PaginationTable;
