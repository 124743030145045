import React, {Fragment, useEffect} from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'

const ResetSuccessful = ({locale}) => {

    useEffect(() => {
        document.title = locale ? locale.authentication.recovery.recovery_successful : 'Legal Desk';
    }, [locale]);

    return (
        <Fragment>
            {locale && locale.authentication && (
                <div className="b-without-form">
                    <h3 className="b-form-title">
                        {locale.authentication.recovery.recovery_successful}
                    </h3>
                    <div className="b-form-text">
                        {locale.authentication.recovery.passwordReset}
                    </div>
                    <Link className="b-link b-link__action" to="/login">
                        {locale.authentication.recovery.continue}
                    </Link>
                </div>
            )}
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale
    }
}

export default connect(mapStateToProps)(ResetSuccessful);
