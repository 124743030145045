import React from 'react';

const Spinner = ({title}) => {
    return (
        <div className="b-spinner-container">
            <div className="b-spinner">
                <div className="b-spinner-inner"/>
                <div className="b-spinner-text">{title}</div>
            </div>
        </div>
    )
}

export default Spinner;
