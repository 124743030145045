import { localsName } from 'ejs';
import React, {Fragment, useState} from 'react';

/* eslint-disable jsx-a11y/anchor-has-content */

const Practice = ({block, configuration}) => {
    const [close, setClose] = useState(false);
    const toggleVisiblyBlock = () => {
        setClose(!close);
    };

    return (
        <Fragment>
            <div className="b-practice-area">
                <div
                    className={
                        close
                            ? 'b-section-title b-section-title-toggle b-section-title-toggle--close'
                            : 'b-section-title b-section-title-toggle'
                    }
                    onClick={toggleVisiblyBlock}
                >
                    {block.name}
                </div>
                <div className={close ? 'b-summary unvisibly' : 'b-summary'}>
                    {block.lines.map((line) => (
                        <div className="b-summary-row">
                            {line.fields.map((cell) => {
                                const value =
                                    cell.fieldType === 'TextArea'
                                        ? cell.value?.replace(/(?:\r\n|\r|\n)/g, '<br />')
                                        : cell.value;

                                const documentExists =
                                    cell.fieldType === 'Document' &&
                                    cell.documentId &&
                                    configuration &&
                                    configuration['case.one'];

                                return (
                                    <div
                                        className={
                                            cell.fieldType === 'TextArea'
                                                ? 'b-summary-cell b-summary-cell_textarea b-summary-cell__' + cell.width
                                                : 'b-summary-cell b-summary-cell__' + cell.width
                                        }
                                    >
                                        {documentExists && (
                                            <a
                                                className="b-icon b-icon__download"
                                                href={`https://${configuration['case.one']}/api/download?id=${
                                                    cell.documentId
                                                }`}
                                            />
                                        )}
                                        {cell.fieldType === 'Document' && !cell.documentId && (
                                            <div className="b-icon b-icon__download"/>
                                        )}
                                        {cell.fieldType !== 'RequiredBool' && (
                                            <div
                                                title={cell.name}
                                                className={
                                                    !cell.value
                                                        ? 'b-summary-cell-title b-summary-cell-title__placeholder'
                                                        : 'b-summary-cell-title'
                                                }
                                            >
                                                {cell.name}
                                            </div>
                                        )}
                                        {value && (
                                            <div title={value} className="b-summary-cell-value">
                                                {cell.fieldType === 'TextArea' && (
                                                    <p dangerouslySetInnerHTML={{__html: value}}/>
                                                )}
                                                {cell.fieldType === 'RequiredBool' && (
                                                    <div className="b-list-item-link">
                                                        <i className={value == 'true' ? 'b-block-icon b-block-icon__done' : 'b-block-icon'}></i>
                                                        <div className="b-list-item-title">
                                                            <span className="b-title-inner">{cell.name}</span>
                                                        </div>
                                                    </div>
                                                )}
                                                {cell.fieldType !== 'TextArea' && cell.fieldType !== 'RequiredBool' && cell.fieldType !== 'Bool' && value}
                                                {cell.fieldType === 'Bool' && value === 'true' ? 'Да' : cell.fieldType === 'Bool' ? 'Нет' : ''}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ))}
                </div>
            </div>
        </Fragment>
    );
};

export default Practice;
