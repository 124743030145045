import React, {useState} from 'react';
import {Input, Select, TableAdmin, Title, Spinner} from '@components';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {AdminActions} from '@redux';
import {useDispatch} from 'react-redux';

const getEmailValidation = (isActiveDirectoryUser) => {
    if (isActiveDirectoryUser) {
        return Yup.string().email();
    } else {
        return Yup.string()
            .email()
            .required();
    }
};

const FormUserAdmin = ({locale, user, userCases, isActiveDirectoryUser}) => {
    const dispatch = useDispatch();
    const [visibilitySpinner, setVisibilitySpinner] = useState(false);

    const validationSchema = Yup.object({
        first_name: Yup.string().required(),
        last_name: Yup.string().required(),
        local: Yup.object({
            email: getEmailValidation(isActiveDirectoryUser),
        }),
    });

    const {values, errors, handleChange, handleSubmit, setFieldValue} = useFormik({
        initialValues: user,
        validationSchema,
        onSubmit: (values) => {
            setVisibilitySpinner(true);
            dispatch(AdminActions.UpdateUser(values)).finally(() => {
                setTimeout(() => {
                    setVisibilitySpinner(false);
                }, 500);
            });
        },
    });

    return (
        <>
            {user && (
                <div className="b-form">
                    <Title
                        title={`${locale?.admin.card.user.title} ${user.first_name} ${user.last_name}`}
                        buttonText={locale?.admin.popup.actions.save}
                        buttonClick={handleSubmit}
                        buttonIcon="save"
                    />
                    <div className="b-form-row">
                        <div className="b-form-cell b-form-cell__4">
                            <Input
                                name="first_name"
                                type="text"
                                placeholder={locale?.admin.card.user.first_name}
                                value={values.first_name}
                                onChange={handleChange}
                                error={errors.first_name}
                            />
                        </div>
                        <div className="b-form-cell b-form-cell__4">
                            <Input
                                name="last_name"
                                type="text"
                                placeholder={locale?.admin.card.user.last_name}
                                value={values.last_name}
                                onChange={handleChange}
                                error={errors.last_name}
                            />
                        </div>
                        <div className="b-form-cell b-form-cell__4">
                            <Input
                                name="local.email"
                                type="email"
                                placeholder={locale?.admin.card.user.email}
                                value={values.local.email}
                                onChange={handleChange}
                                error={errors.local?.email}
                            />
                        </div>
                    </div>
                    <div className="b-form-row">
                        <div className="b-form-cell b-form-cell__8">
                            <Select
                                name="status"
                                dataList={{
                                    active: locale?.admin.userstatus.active,
                                    archive: locale?.admin.userstatus.archive,
                                }}
                                value={values.status}
                                placeholder={locale?.admin.card.user.status}
                                setFieldValue={setFieldValue}
                            />
                        </div>
                        <div className="b-form-cell b-form-cell__4">
                            <Select
                                name="isAdmin"
                                dataList={{
                                    administrator: locale?.admin.userstatus.administrator,
                                    usual: locale?.admin.userstatus.usual,
                                }}
                                value={values.isAdmin ? 'administrator' : 'usual'}
                                placeholder={locale?.admin.card.user.role}
                                setFieldValue={(name, value) => {
                                    const formatValue = value === 'administrator';

                                    return setFieldValue(name, formatValue);
                                }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {userCases && (
                <div className="ld-form">
                    <Title title={locale?.admin.card.user.titleCase}/>
                    {userCases.map((userCase) => (
                        <TableAdmin
                            id={userCase._id}
                            data={{
                                name: {
                                    type: 'text',
                                    data: userCase.name,
                                    width: 450,
                                },
                                caseNumber: {
                                    type: 'text',
                                    width: 110,
                                    data: userCase.number,
                                },
                                status: {
                                    type: 'text',
                                    width: 110,
                                    data: userCase.isArchive
                                        ? locale?.admin.userstatus.archive
                                        : locale?.admin.userstatus.active,
                                },
                                created: {
                                    type: 'date',
                                    width: 110,
                                    data: userCase.createdAt,
                                },
                            }}
                        />
                    ))}
                </div>
            )}
            {visibilitySpinner && <Spinner title={locale.card.loading}/>}
        </>
    );
};

export default FormUserAdmin;
