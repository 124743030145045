import React, {Fragment, useEffect, useState, useCallback, useRef} from 'react';
import {connect} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useFormik, useFormikContext} from 'formik';

import {
    Title,
    TableAdmin,
    Popup,
    FormProductAdmin,
    FormDelete,
    Filter,
    Spinner,
} from '@components';

import {Api} from '@utils';

import {AdminActions} from '@redux';
import update from 'immutability-helper';

const Products = ({
                      GetProducts,
                      GetBotsAndPatterns,
                      DeleteProduct,
                      locale,
                      products,
                      botsAndPatterns,
                      lang,
                      InsertProduct,
                      productsCategories,
                      isReady,
                      GetProductsCategories
                  }) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [entityId, setEntityId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [titlePopUp, setTitlePopUp] = useState('');
    const history = useHistory();
    const [productsList, setProducts] = useState([]);
    const params = useParams();

    let initialValues = {
        name: '',
        description: '',
        caption: '',
        heading: '',
        price: [{
            price: ''
        }],
        metadata: {
            serviceName: 'doc.one',
            createType: 'manual',
        }
    };

    const setFilter = useCallback((id) => {
        let productsFiltered;

        if (id) {
            productsFiltered = products.filter(item => item.metadata.category === id);

            setProducts(productsFiltered);
        } else {
            setProducts(products);
            history.push('/products');
        }
    }, [history, products])

    const validate = values => {
        const errors = {
            name: false,
            metadata: {
                category: false,
                projectType: false,
                botId: false,
            },
            price: [{
                price: false
            }]
        };

        if (!values?.name) {
            errors.name = true;
        } else {
            delete errors.name;
        }

        if (!values?.metadata?.category) {
            errors.metadata.category = true;
        } else {
            delete errors.metadata.category;
        }

        if (!/^\d+$/.test(values?.price[0]?.price) && values?.price[0]?.price) {
            errors.price[0].price = true;
        } else {
            delete errors.price;
        }

        if (values?.metadata?.serviceName !== 'doc.one' && values?.metadata?.createType === 'auto') {
            if (!values?.metadata?.projectType) {
                errors.metadata.projectType = true;
            } else {
                delete errors.metadata.projectType;
            }
        }

        if (values?.metadata?.createType === 'manual') {
            delete errors.metadata.projectType;
        }

        if (values?.metadata?.serviceName === 'doc.one') {
            delete errors.metadata.projectType;
        }

        if (!values?.metadata?.botId) {
            errors.metadata.botId = true;
        } else {
            delete errors.metadata.botId;
        }

        if (errors.metadata && Object.keys(errors.metadata).length === 0) {
            delete errors.metadata;
        }

        return errors;
    };

    var formik = useFormik({
        isSubmitting: false,
        validateOnChange: false,
        initialValues: initialValues,
        validate
    });

    const getBotsAndPatterns = (id) => {
        let category = botsAndPatterns.find(item => item._id === id);

        return category ? category.name : '';
    }
    const onRemove = (id, name) => {
        setOpenPopup(true);
        setEntityId(id);
        setAction('delete');
        setTitlePopUp(name);
    }

    useEffect(() => {
        GetProducts();
        GetBotsAndPatterns();
        GetProductsCategories();
    }, [GetBotsAndPatterns, GetProducts, GetProductsCategories]);

    useEffect(() => {
        if (params.categoryId && products) {
            setFilter(params.categoryId);
        } else {
            setProducts(products);
        }
    }, [params.categoryId, products, setFilter]);

    /*useEffect(() => {
        formik.setFieldValue('metadata.botId', '');
    },[formik.values.metadata.serviceName]); */

    const [action, setAction] = useState('delete');

    if (locale) {
        var actions = {
            update: {
                titlePopUp: locale.admin.popup.product.product,
                buttonAdd: locale.buttons.save,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormProductAdmin
                    locale={locale}
                    entityId={entityId}
                    formik={formik}
                    loading={loading}
                />,
                submitHandle: () => {
                    formik
                        .validateForm()
                        .then((result) => {
                            if (Object.keys(result).length === 0) {
                                Api.UpdateProduct(formik.values)
                                    .then(({response, data}) => {
                                        setOpenPopup(false);
                                        formik.setValues(null);
                                        formik.resetForm({values: ''});
                                    })
                                    .catch(({response}) => {
                                        console.log(response);
                                    })
                            }
                        })
                }
            },
            insert: {
                titlePopUp: locale.admin.popup.product.newProduct,
                buttonAdd: locale.buttons.add,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormProductAdmin
                    locale={locale}
                    entityId={entityId}
                    formik={formik}
                    loading={loading}
                />,
                submitHandle: () => {
                    formik
                        .validateForm()
                        .then((result) => {
                            if (Object.keys(result).length === 0) {
                                InsertProduct(formik.values);
                                setOpenPopup(false);
                                formik.setValues(null);
                                formik.resetForm({values: ''});
                            }
                        })
                }
            },
            delete: {
                titlePopUp: locale.admin.popup.product.delete.replace(/%product%/gi, titlePopUp),
                buttonAdd: locale.buttons.delete,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormDelete
                    locale={locale}
                />,
                submitHandle: (productId) => {
                    DeleteProduct(productId);
                    setOpenPopup(false);
                }
            }
        };
    }

    const sortCards = (a, b) => {
        if (a.position > b.position) {
            return 1;
        } else {
            return -1;
        }
    }

    function isDataReady() {
        return botsAndPatterns && products;
    }

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        const dragCard = products[dragIndex];
        let oldPosition = products[dragIndex].position,
            newPosition = products[hoverIndex].position;

        setProducts(update(products, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, dragCard],
            ],
        }));

        products[hoverIndex].position = oldPosition;
        products[dragIndex].position = newPosition;

        setProducts(products);
    }, [products]);

    const updatePosition = (index) => {
        Api.UpdateProductPosition(products[index]._id, products[index])
            .then((response) => {
                console.log(response);
            }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <Fragment>
            {locale && locale.card && (
                <div>
                    {isReady.productsCategories && productsCategories && (
                        <div className="b-filter">
                            <ul className="b-filter-list">
                                <li className="b-filter-item">
                                    <a
                                        onClick={() => setFilter()}
                                        className={history.location.pathname === '/products' ? 'b-filter-link b-filter-link__active' : 'b-filter-link'}>
                                        {locale.filter.all}
                                    </a>
                                </li>
                                {productsCategories.map((item, index) => (
                                    <Filter
                                        key={item._id}
                                        tab={item}
                                        path="/products"
                                        onClick={() => setFilter(item._id)}
                                    />
                                ))}
                            </ul>
                        </div>
                    )}
                    <Title
                        title={locale.admin.menu.products}
                        buttonText={locale.admin.popup.product.newProduct}
                        buttonClick={() => {
                            formik.setValues(null);
                            formik.resetForm({values: ''});
                            setOpenPopup(true);
                            setAction('insert');
                        }}
                    />
                    {isDataReady() ? productsList.sort(sortCards).map((item, index) => (
                        <TableAdmin
                            position={item.position}
                            index={index}
                            key={item._id}
                            id={item._id}
                            moveCard={moveCard}
                            title={locale.admin.popup.product.product}
                            product={item}
                            category={item.metadata ? getBotsAndPatterns(item.metadata.botId) : ''}
                            lang={lang}
                            locale={locale}
                            onRemove={() => onRemove(item._id, item.name)}
                            updatePosition={updatePosition}
                            onClick={() => {
                                setEntityId(item._id);
                                setAction('update');
                                setOpenPopup(true);
                                setLoading(true);

                                Api.GetProduct(item._id)
                                    .then(({response, data}) => {
                                        setLoading(false);
                                        formik.setValues(data);
                                    })
                                    .catch(({response, error}) => {
                                        console.log(error);
                                    })
                            }}
                        />
                    )) : (
                        <Spinner
                            title={locale.card.loading}
                        />
                    )}
                    <Popup
                        form={actions[action].tplForm}
                        formik={formik}
                        title={actions[action].titlePopUp}
                        locale={locale}
                        entityId={entityId}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        buttonAdd={actions[action].buttonAdd}
                        buttonCancel={actions[action].buttonCancel}
                        onAdd={() => actions[action].submitHandle(entityId)}
                    />
                </div>
            )}
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        lang: store.lang,
        botsAndPatterns: store.botsAndPatterns,
        products: store.products,
        productsCategories: store.productsCategories,
        isReady: store.isReady
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        GetProducts: () => dispatch(AdminActions.GetProducts()),
        GetBotsAndPatterns: () => dispatch(AdminActions.GetBotsAndPatterns()),
        DeleteProduct: (productId) => dispatch(AdminActions.DeleteProduct(productId)),
        InsertProduct: (values) => dispatch(AdminActions.InsertProduct(values)),
        GetProductsCategories: () => dispatch(AdminActions.GetProductsCategories()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);
