import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ServiceActions } from '@redux';
import { DropdownCheckbox } from '../../components/Dropdown/DropdownCheckbox';
import {
    productTypesSelector,
    productsSelector,
    productStatusesSelector,
    productCategoriesSelector,
} from '../../redux/selectors/service/dictionary';

/**
 * Сброс фильтра на значение по умолчанию, если не выбрано ни одно значение из фильтра.
 * Ситуация возникает, когда при смене родительского фильтра, напрмиер категории, в подгруженном фильтре нет совпадений
 * с выбранными ранее фильтрами
 *
 * @param filter
 * @param dictionary
 * @returns {[(*&{checked: boolean}), ...*]|*}
 */
function resetUncheckedFilter(filter, dictionary) {
    if (!filter.find((item) => item.checked)) {
        return [{ ...filter[0], checked: true }, ...dictionary.slice(1)];
    } else {
        return filter;
    }
}

/**
 * Создание справочника фильтра методом объединение ранее установленных фильтр с подгруженным справочником
 *
 * @param filter
 * @param dictionary
 * @returns {*}
 */
function mapFilterToDictionary(filter, dictionary) {
    const result = dictionary.map((dictionaryItem) => {
        const filterItem = filter.find((filterItem) => filterItem.id === dictionaryItem.id);
        if (filterItem && filterItem.checked) {
            return { ...dictionaryItem, ...filterItem };
        } else {
            return { ...dictionaryItem };
        }
    });

    return result;
}

const filterChecked = (item) => item.id !== 0 && item.checked;

/**
 * Формирование объекта с массивом идентификаторов выбранных значений в фильтра
 * для последующей передачи их в API.
 *
 * @param category
 * @param type
 * @param product
 * @param statuses
 * @returns {{types: boolean, statuses: boolean, categories: boolean, products: boolean}}
 */
function processFilters(category, type, product, statuses) {
    const categoryFilter = category.filter(filterChecked);
    const typeFilter = type.filter(filterChecked);
    const productFilter = product.filter(filterChecked);
    const statusesFilter = statuses.filter(filterChecked);

    return {
        categories: categoryFilter.length > 0 && categoryFilter.map((category) => category.id),
        types: typeFilter.length > 0 && typeFilter.map((type) => type.id),
        products: productFilter.length > 0 && productFilter.map((product) => product.id),
        statuses: statusesFilter.length > 0 && statusesFilter.map((status) => status.id),
    };
}

/**
 * Формирование параметров запроса к API.
 *
 * @param values
 * @param name
 * @returns {*}
 */
function buildRequestQueryParameters(values, name) {
    return values
        .filter((value) => value)
        .reduce((result, value) => [...result, `${name}[]=${value}`], [])
        .join('&');
}

/**
 * Формирование строки запроса к API.  Используется для мемоизации строки запроса.
 *
 * @param categories
 * @param types
 * @param products
 * @param statuses
 * @returns {string}
 */
function createCaseSearchQuery({ categories, types, products, statuses }) {
    return [
        categories && buildRequestQueryParameters(categories, 'category'),
        types && buildRequestQueryParameters(types, 'serviceName'),
        products && buildRequestQueryParameters(products, 'product'),
        statuses && buildRequestQueryParameters(statuses, 'customStatus'),
    ]
        .filter((queryParam) => queryParam)
        .join('&');
}

const CaseFilter = ({ locale, actions, dictionary, filters }) => {
    const [category, setCategory] = useState(mapFilterToDictionary(filters.category, dictionary.categories));
    const [type, setType] = useState(mapFilterToDictionary(filters.type, dictionary.productTypes));
    const [product, setProduct] = useState(mapFilterToDictionary(filters.product, dictionary.products));
    const [status, setStatus] = useState(mapFilterToDictionary(filters.status, dictionary.productStatuses));

    const filter = useMemo(() => processFilters(category, type, product, status), [category, type, product, status]);
    const caseSearchQuery = useMemo(() => createCaseSearchQuery(filter), [filter]);

    useEffect(() => {
        actions.GetCases(caseSearchQuery, {
            category,
            type,
            product,
            status,
        });
    }, [caseSearchQuery]);

    const handleReset = () => {
        const categoriesFilter = [{ ...category[0], checked: true }, ...dictionary.categories.slice(1)];
        const productTypesFilter = [{ ...type[0], checked: true }, ...dictionary.productTypes.slice(1)];
        const productsFilter = [{ ...product[0], checked: true }, ...dictionary.products.slice(1)];
        const productStatusFilter = [{ ...status[0], checked: true }, ...dictionary.productStatuses.slice(1)];
        setCategory(categoriesFilter);
        setType(productTypesFilter);
        setProduct(productsFilter);
        setStatus(productStatusFilter);
    };

    useEffect(() => {
        setStatus(
            resetUncheckedFilter(
                mapFilterToDictionary(filters.status, dictionary.productStatuses),
                dictionary.productStatuses
            )
        );
    }, [dictionary.productStatuses]);

    useEffect(() => {
        setProduct(
            resetUncheckedFilter(mapFilterToDictionary(filters.product, dictionary.products), dictionary.products)
        );
    }, [dictionary.products]);

    return (
        <>
            <li className="b-filter-item">
                <a className="b-filter-link">
                    <DropdownCheckbox
                        name={locale.admin.popup.category.category}
                        placeholder={'Choose Category'}
                        values={category}
                        multiple={true}
                        onChange={(categoryList) => setCategory(categoryList)}
                    />
                </a>
            </li>
            <li className="b-filter-item">
                <a className="b-filter-link">
                    <DropdownCheckbox
                        name={locale.filter.filterType}
                        placeholder={'Choose Type'}
                        values={type}
                        multiple={true}
                        onChange={(typeList) => setType(typeList)}
                    />
                </a>
            </li>
            <li className="b-filter-item">
                <a className="b-filter-link">
                    <DropdownCheckbox
                        name={locale.admin.popup.product.product}
                        placeholder={'Choose product'}
                        values={product}
                        multiple={true}
                        onChange={(productList) => setProduct(productList)}
                    />
                </a>
            </li>
            <li className="b-filter-item">
                <a className="b-filter-link">
                    <DropdownCheckbox
                        name={locale.tabs.status}
                        placeholder={'Choose Status'}
                        values={status}
                        multiple={true}
                        onChange={(statusList) => setStatus(statusList)}
                    />
                </a>
            </li>
            <li className="b-filter-item">
                <a className="b-filter-link" onClick={handleReset}>
                    <span className="b-reset-filter">{locale.filter.resetFilter}</span>
                </a>
            </li>
        </>
    );
};

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        dictionary: {
            products: productsSelector(store),
            productTypes: productTypesSelector(store),
            productStatuses: productStatusesSelector(store),
            categories: productCategoriesSelector(store),
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: {
            GetCases: (filter, filterObject) => dispatch(ServiceActions.GetCases(filter, filterObject)),
            GetCasesCategories: () => dispatch(ServiceActions.GetCasesCategories()),
            GetProductTypes: () => dispatch(ServiceActions.GetProductTypes()),
            GetProductStatuses: (productId) => dispatch(ServiceActions.GetProductStatusList(productId)),
            GetProductsByCategoryAndType: (category, type) =>
                dispatch(ServiceActions.GetProductsByCategoryAndType(category, type)),
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseFilter);
