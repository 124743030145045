import React, { Fragment } from 'react';

import { Input, SelectDynamic, Select, Spinner } from '@components';

const FormProductAdmin = ({ locale, entityId, formik, loading }) => {
    return (
        <Fragment>
            {loading && (
                <div className="b-modal-spinner">
                    <Spinner title={locale.card.loading} />
                </div>
            )}
            {locale && !loading && formik?.values && (
                <div className="b-form">
                    <div className="b-form-row">
                        <div className="b-form-cell b-form-cell__6">
                            <Input
                                name="name"
                                type="text"
                                error={!!formik.errors?.name}
                                placeholder={locale.admin.popup.product.title}
                                {...formik.getFieldProps('name')}
                            />
                        </div>
                        <div className="b-form-cell b-form-cell__6">
                            <Input
                                name="caption"
                                type="text"
                                placeholder={locale.admin.popup.product.caption}
                                {...formik.getFieldProps('caption')}
                            />
                        </div>
                    </div>
                    <div className="b-form-row">
                        <div className="b-form-cell b-form-cell__6">
                            <SelectDynamic
                                placeholder={locale.admin.popup.product.category}
                                url="/api/admin/v1.0/suggest/category"
                                name="metadata.category"
                                error={formik.errors?.metadata?.category ? 'error' : ''}
                                setFieldValue={formik.setFieldValue}
                                {...formik.getFieldProps('metadata.category')}
                            />
                        </div>
                        <div className="b-form-cell b-form-cell__3">
                            <Input
                                name="metadata.heading"
                                type="text"
                                placeholder={locale.admin.popup.product.heading}
                                {...formik.getFieldProps('metadata.heading')}
                            />
                        </div>
                        <div className="b-form-cell b-form-cell__3">
                            <Input
                                name="price[0].price"
                                type="text"
                                error={
                                    formik.errors &&
                                    formik.errors.price &&
                                    formik.errors.price[0] &&
                                    formik.errors.price[0].price
                                        ? locale.errors.numbersOnly
                                        : false
                                }
                                placeholder={locale.admin.popup.product.price}
                                {...formik.getFieldProps('price[0].price')}
                            />
                        </div>
                    </div>
                    <div className="b-form-row">
                        <div className="b-form-cell b-form-cell__12">
                            <Input
                                name="description"
                                type="text"
                                placeholder={locale.admin.popup.product.description}
                                {...formik.getFieldProps('description')}
                            />
                        </div>
                    </div>
                    <div className="b-form-row">
                        <div className="b-form-cell b-form-cell__3">
                            <Select
                                placeholder={locale.admin.popup.product.service}
                                name="metadata.serviceName"
                                dataList={{
                                    'form.one': locale.admin.popup.product.suggest.service.caseOneFormOne,
                                    'form.one.bot': locale.admin.popup.product.suggest.service.formOneBotOnly,
                                    'doc.one': locale.admin.popup.product.suggest.service.docOne,
                                }}
                                value="doc.one"
                                setFieldValue={formik.setFieldValue}
                                {...formik.getFieldProps('metadata.serviceName')}
                            />
                        </div>
                        {formik?.values?.metadata?.serviceName !== 'doc.one' && (
                            <Fragment>
                                {formik?.values?.metadata?.serviceName === 'form.one' && (
                                    <div className="b-form-cell b-form-cell__3">
                                        <SelectDynamic
                                            placeholder={locale.admin.popup.product.practicearea}
                                            url={'/api/admin/v1.0/suggest/practiceArea?productId=' + entityId}
                                            name="metadata.projectType"
                                            setFieldValue={formik.setFieldValue}
                                            error={formik.errors?.metadata?.projectType ? 'error' : ''}
                                            {...formik.getFieldProps('metadata.projectType')}
                                        />
                                    </div>
                                )}
                                {formik?.values?.metadata?.serviceName === 'form.one' && (
                                    <div className="b-form-cell b-form-cell__2">
                                        <SelectDynamic
                                            placeholder={locale.admin.popup.product.statusField}
                                            url={`/api/admin/v1.0/caseone-dictionary-blocks/?caseTypeId=${formik?.values?.metadata?.projectType?._id}`}
                                            name="statusField"
                                            setFieldValue={formik.setFieldValue}
                                            error={formik.errors?.statusField ? 'error' : ''}
                                            {...formik.getFieldProps('statusField')}
                                        />
                                    </div>
                                )}
                                <div
                                    className={
                                        formik?.values?.metadata?.serviceName === 'form.one'
                                            ? 'b-form-cell b-form-cell__2'
                                            : 'b-form-cell b-form-cell__9'
                                    }
                                >
                                    <SelectDynamic
                                        placeholder={locale.admin.popup.product.botid}
                                        url={'/api/admin/v1.0/suggest/bot?productId=' + entityId}
                                        name="metadata.botId"
                                        setFieldValue={formik.setFieldValue}
                                        error={formik.errors?.metadata?.botId ? 'error' : ''}
                                        {...formik.getFieldProps('metadata.botId')}
                                    />
                                </div>
                                {formik?.values?.metadata?.serviceName === 'form.one' && (
                                    <div className="b-form-cell b-form-cell__2">
                                        <Select
                                            placeholder={locale.admin.popup.product.creatcase}
                                            name="metadata.createType"
                                            dataList={{
                                                auto: locale.admin.popup.product.auto,
                                                manual: locale.admin.popup.product.manual,
                                            }}
                                            setFieldValue={formik.setFieldValue}
                                            {...formik.getFieldProps('metadata.createType')}
                                        />
                                    </div>
                                )}
                            </Fragment>
                        )}
                        {formik?.values?.metadata?.serviceName === 'doc.one' && (
                            <div className="b-form-cell b-form-cell__9">
                                <SelectDynamic
                                    placeholder={locale.admin.popup.product.template}
                                    url={'/api/admin/v1.0/suggest/doc?productId=' + entityId}
                                    name="metadata.botId"
                                    error={formik.errors?.metadata?.botId ? 'error' : ''}
                                    setFieldValue={formik.setFieldValue}
                                    {...formik.getFieldProps('metadata.botId')}
                                />
                            </div>
                        )}
                    </div>
                    <div className="b-form-row">
                        <div className="b-form-cell b-form-cell__6">
                            <SelectDynamic
                                placeholder={locale.admin.popup.product.folder}
                                url="/api/admin/v1.0/suggest/folder"
                                name="metadata.folder"
                                setFieldValue={formik.setFieldValue}
                                {...formik.getFieldProps('metadata.folder')}
                            />
                        </div>
                        <div className="b-form-cell b-form-cell__6">
                            <SelectDynamic
                                placeholder={locale.admin.popup.product.subFolder}
                                url={'/api/admin/v1.0/suggest/folder?parentId=' + formik?.values?.metadata?.folder?._id}
                                name="metadata.subFolder"
                                disabled={!formik?.values?.metadata?.folder?.hasChildren}
                                setFieldValue={formik.setFieldValue}
                                {...formik.getFieldProps('metadata.subFolder')}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export default FormProductAdmin;
