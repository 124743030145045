import React, {Fragment, useState, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

import {Dropdown, DropdownConfirm, Spinner} from '@components';

const Card = ({locale, id, title, number, date, numberImage, typeImage, DeleteCase, ToArchiveCase, status}) => {
    const history = useHistory();
    const [isVisibleSpinner, setVisibleSpinner] = useState(false);
    const refCard = useRef();
    const [subactionShow, setSubactionsShow] = useState(false);
    const [dropdownState, setDropdownState] = useState(false);
    const [subactionTemplate, setsubactionTemplate] = useState(null);

    const clickCard = () => {
        history.push(`/cases/${id}/service`);
    };
    const getClass = (number, type) => {
        let extraClass = '';

        if (type === 'doc.one') {
            extraClass = ' b-case-image__doc';
        }

        if (type === 'form.one.bot') {
            extraClass = ' b-case-image__form-one-bot';
        }

        return 'b-case-image b-case-image__' + number + extraClass;
    };
    const options = {
        title: 'Actions',
        isIcon: true,
        right: 12,
        subactionShow,
        subactionWidth: '320px',
        subactionTemplate,
        items: [
            {
                title: locale.dropmenu.items.archive,
                isIcon: false,
                subactionTemplate: (
                    <DropdownConfirm
                        title={locale.dropmenu.items.archive}
                        cancelText={locale.buttons.cancel}
                        saveText={locale.buttons.archive}
                        message={locale.archiveDescription
                            .replace(/%number%/gi, `<b>${number}</b>`)
                            .replace(/%name%/gi, `<b>${title}</b>`)}
                        backOnClick={() => setSubactionsShow(false)}
                        saveOnClick={() => {
                            ToArchiveCase(id);
                            setVisibleSpinner(true);
                            setTimeout(() => {
                                setVisibleSpinner(false);
                            }, 2000);
                            setSubactionsShow(false);
                            setDropdownState(false);
                        }}
                    />
                ),
                action: (item) => {
                    setsubactionTemplate(item.subactionTemplate);
                    setSubactionsShow(true);
                },
            },
            {
                title: locale.dropmenu.items.delete,
                isIcon: false,
                subactionTemplate: (
                    <DropdownConfirm
                        title={locale.dropmenu.items.delete}
                        cancelText={locale.buttons.cancel}
                        saveText={locale.buttons.delete}
                        message={locale.deleteDescription
                            .replace(/%number%/gi, `<b>${number}</b>`)
                            .replace(/%name%/gi, `<b>${title}</b>`)}
                        backOnClick={() => setSubactionsShow(false)}
                        saveOnClick={() => {
                            DeleteCase(id);
                            setVisibleSpinner(true);
                            setTimeout(() => {
                                setVisibleSpinner(false);
                            }, 3000);
                            setSubactionsShow(false);
                            setDropdownState(false);
                        }}
                    />
                ),
                action: (item) => {
                    setsubactionTemplate(item.subactionTemplate);
                    setSubactionsShow(true);
                },
            },
        ],
    };

    return (
        <Fragment>
            <li className="b-columns-item b-columns-item__stretch">
                <a className="b-block b-block__card" onClick={clickCard} ref={refCard}>
                    <div className={getClass(numberImage, typeImage)}>
                        <h4 className="b-case-title">{title}</h4>
                        {!status ? '' : <div className="b-block__status"><p>{status}</p></div>}
                    </div>
                    <div className="b-actions-container">
                        <Dropdown
                            options={options}
                            setSubactionsShow={setSubactionsShow}
                            refBlock={refCard}
                            dropdownState={dropdownState}
                            setDropdownState={setDropdownState}
                        />
                        <p className="b-case-number">{number}</p>
                        <p className="b-case-date">{date}</p>
                    </div>
                </a>
            </li>
            {isVisibleSpinner && <Spinner title={locale.card.loading}/>}
        </Fragment>
    );
};

Card.propTypes = {
    /** Объект локализации */
    locale: PropTypes.object,
    /** Идентификатор дела */
    id: PropTypes.string,
    /** Заголовок */
    title: PropTypes.string,
    /** Статус дела */
    status: PropTypes.string,
    /** Номер дела */
    number: PropTypes.string,
    /** Дата дела */
    date: PropTypes.string,
    /** Число от 1 до 9 для отображения градиента */
    numberImage: PropTypes.string,
    /** Тип картинки  */
    typeImage: PropTypes.string,
};

Card.defaultProps = {
    locale: null,
    id: null,
    title: null,
    status: null,
    number: 'No number',
    date: null,
    numberImage: null,
    typeImage: null,
};

export default Card;
