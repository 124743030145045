import React from 'react';
import {
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import {
    LoginADContainer
} from '@containers';

// Basename router set at app.authAD.js file [/authentication/account]

const AuthADRoutes = (
    <Switch>
        <Route
            path="/login"
            component={LoginADContainer}/>
        <Redirect from='/' to='/login'/>
    </Switch>
);

export default AuthADRoutes;
