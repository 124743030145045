import React, { useState, useRef } from 'react';
import { CheckboxList } from '../Input/CheckboxList';
import useOnClickOutSide from '../../hooks/useOnClickOutSide';

/**
 * Компонент рендера списка в виде чекбоксов с множественным или единственным выбором.
 *
 * @param name Название списка
 * @param placeholder Дефолтное название значения списка
 * @param values Функция должна возвращать массив объектов. Обязательные поля объектов: name, checked
 * @param onChange Хэндлер изменения состояния чекбоксов. Функция принимаем массив объектов. Обязательные поля: name, checked
 * @param multiple Значение типа boolean для управления возможностью множественного выбора. По умолчанию имеет значение false
 * @returns {JSX.Element}
 * @constructor
 */
export const DropdownCheckbox = ({ name, placeholder, values, onChange, multiple = false }) => {
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef();
    useOnClickOutSide(ref, () => setIsOpen(false));

    const handleClick = () => {
        setIsOpen(!isOpen);
    };

    const checkboxesName = values.filter((t) => t.checked).map((checkbox) => checkbox.name);

    const numberSelectCheckboxes = checkboxesName.length - 1 > 0 ? `+ ${checkboxesName.length - 1}` : '';

    return (
        <>
            <div className="b-dropdown__status" onClick={handleClick} title={checkboxesName}>
                {name}:&nbsp;
                {checkboxesName ? (
                    <span className='status__name'>
                            {checkboxesName[0]} <span className="b-dropdown__number">{numberSelectCheckboxes}</span>
                        </span>
                ) : (
                    placeholder
                )}
            </div>
            {isOpen ? (
                <div ref={ref} className=
                    {
                        isOpen && values.length >= 5 ? 'b-dropdown__list active b-dropdown__height' : 'b-dropdown__list active'
                    }
                >
                    <div className="b-dropdown__items">
                        <CheckboxList values={values} multiple={multiple} onChange={onChange} />
                    </div>
                </div>
            ) : null}
        </>
    );
};
