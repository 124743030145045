import React, {Fragment, useState, useEffect} from "react";
import {connect} from 'react-redux'
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {Api} from '@utils';
import {Input} from '@components';

const Login = ({locale}) => {
    const [errorType, setErrorType] = useState('');

    const validationSchema = Yup.object({
        email: Yup.string().min(5).required(),
        password: Yup.string().min(3).required(),
    });

    const formik = useFormik({
        isSubmitting: false,
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: values => {
            formik.isSubmitting = true;
            Api.Login(values)
                .then(() => {
                    setErrorType('');
                    // TODO: Redirect to service
                    window.location.assign('/cases');
                })
                .catch(({response}) => {
                    formik.setSubmitting(false);

                    if (response.data.error === 'multiplyAccount') {
                        locale.authentication.error.login.multiplyAccount = locale.authentication.error.login.multiplyAccount.replace('%domains%', response.data.domains)
                    }
                    setErrorType(response.data.error);
                })

        },
    });

    useEffect(() => {
        document.title = locale ? locale.authentication.signIn.title : 'Legal Desk';
    }, [locale]);

    return (
        <Fragment>
            {locale && locale.authentication && (
                <Fragment>
                    <h3 className="b-form-title">
                        {locale.authentication.signIn.title} LDAP
                    </h3>
                    <div className="b-form-text"/>
                    <form onSubmit={formik.handleSubmit}>
                        <Input
                            placeholder={locale.inputs.emailAD}
                            type="text"
                            name="email"
                            error={errorType && errorType !== 'password' ? locale.authentication.error.login[errorType] : null}
                            {...formik.getFieldProps('email')} />
                        <Input
                            placeholder={locale.inputs.password}
                            type="password"
                            name="password"
                            error={errorType === 'password' ? locale.authentication.error.login.password : null}
                            {...formik.getFieldProps('password')} />
                        <div className="b-actions">
                            <button className="b-button b-button__uppercase" type="submit"
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                <div
                                    className="b-button-text">{formik.isSubmitting ? locale.authentication.controls.signInLoading : locale.authentication.controls.signIn}</div>
                            </button>
                        </div>
                    </form>
                </Fragment>
            )}
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale
    }
}

export default connect(mapStateToProps)(Login);
