import React from 'react';

export const CheckboxList = ({ values, multiple, onChange }) => {
    return (
        <>
            {values.map((checkboxValue, checkboxIndex) => (
                <div className="b-dropdown__item">
                    <label className={!checkboxValue.checked ? 'label' : 'label active-label'} key={checkboxValue.id}>
                        <input
                            key={checkboxValue.id}
                            type="checkbox"
                            checked={checkboxValue.checked}
                            onChange={(e) => {
                                const checked = e.target.checked;
                                if (multiple) {
                                    const checkboxList = values.map((value, valueIndex) =>
                                        valueIndex === checkboxIndex ? { ...value, checked } : { ...value }
                                    );

                                    // Если выбран первый чекбокс по умолчанию, то нужно сбросить все остальные
                                    if (checkboxIndex === 0) {
                                        checkboxList[0].checked = checked;
                                        for (let i = 1; i < checkboxList.length; i += 1) {
                                            checkboxList[i].checked = false;
                                        }
                                    }

                                    // Если отжаты все чекбоксы, то нужно включить первый по умолчанию.
                                    if (!checkboxList.some((checkbox) => checkbox.checked === true)) {
                                        checkboxList[0].checked = true;
                                    }

                                    // Если выбран хотя бы один checkbox, то нужно выключить первый по умолчанию
                                    if (
                                        checkboxList
                                            .slice(1, checkboxList.length)
                                            .some((checkbox) => checkbox.checked === true)
                                    ) {
                                        checkboxList[0].checked = false;
                                    }

                                    onChange(checkboxList);
                                } else if (!values[checkboxIndex].checked) {
                                    const checkboxList = values.map((checkbox) => ({ ...checkbox, checked: false }));
                                    checkboxList[checkboxIndex].checked = checked;
                                    onChange(checkboxList);
                                }
                            }}
                        />
                        <span>{checkboxValue.name}</span>
                    </label>
                </div>
            ))}
        </>
    );
};
