import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import {NavLink, useParams, useLocation} from 'react-router-dom';

import {ServiceActions} from '@redux';

import {Title, TableTasks, TableFiles, TableFolders, Practice, Empty} from '@components';

const CardService = ({
                         locale,
                         caseItem,
                         caseSummary,
                         GetCaseServiceData,
                         UploadFileToFolder,
                         DeleteFile,
                         GetCaseSummary,
                         ChangeTaskStatus,
                         ClearSelectedData,
                         tasks,
                         files,
                         folders,
                         configuration,
                     }) => {
    const depth = 1;
    const params = useParams();
    const location = useLocation();
    const getLocation = () => {
        return location.pathname.indexOf('summary') !== -1;
    };
    const clickUploadFile = (folderId) => {
        let file = document.createElement('input');

        file.setAttribute('type', 'file');
        file.click();
        file.addEventListener('change', () => uploadFile(folderId, file));
    };
    const uploadFile = (folderId, file) => {
        if (folderId && file.files.length) {
            let fileData = file.files[0];
            let formData = new FormData();

            formData.append('file', fileData);
            formData.append('documentFolderId', folderId);

            UploadFileToFolder(formData, caseItem._id, caseItem.documentFolderId);
        }
    };
    const deleteFile = (fileId) => {
        if (fileId) {
            DeleteFile(fileId, caseItem._id, caseItem.documentFolderId);
        }
    };
    const renderFolders = (folder, index) => {
        return (
            <TableFolders
                folder={folder}
                index={index}
                locale={locale}
                onUpload={clickUploadFile}
                deleteFile={deleteFile}
                depth={depth}
            />
        );
    };

    useEffect(() => {
        if (params.id) {
            GetCaseSummary(params.id);
        }
    }, [GetCaseSummary, params]);

    useEffect(() => {
        GetCaseServiceData(caseItem._id, caseItem.documentFolderId);
        return () => {
            ClearSelectedData();
        };
    }, [ClearSelectedData, GetCaseServiceData, caseItem._id, caseItem.documentFolderId]);
    return (
        <div>
            {locale && locale.card && (
                <Fragment>
                    <div className="b-filter">
                        <ul className="b-filter-list">
                            <li className="b-filter-item">
                                <NavLink
                                    to={`/cases/${caseItem._id}/service`}
                                    className="b-filter-link"
                                    activeClassName="b-filter-link__active"
                                >
                                    {locale.card.service}
                                </NavLink>
                            </li>
                            {caseSummary && caseSummary.list && caseSummary.list.length  && caseSummary.list[0].caseId === caseItem._id ? (
                                <li className="b-filter-item">
                                    <NavLink
                                        to={`/cases/${caseItem._id}/summary`}
                                        className="b-filter-link"
                                        activeClassName="b-filter-link__active"
                                    >
                                        {locale.card.summary}
                                    </NavLink>
                                </li>
                            ) : (
                                <Fragment/>
                            )}
                        </ul>
                    </div>
                    {getLocation() ? (
                        <div>
                            {caseSummary && caseSummary.list && caseSummary.list.length  && caseSummary.list[0].caseId === caseItem._id ? (
                                caseSummary.list.map((item) => <Practice block={item} configuration={configuration}/>)
                            ) : (
                                <Empty title={locale.card.emptyCaseList}/>
                            )}
                        </div>
                    ) : (
                        <Fragment>
                            <Title title={locale.tasks.upcomingTasks}/>
                            {tasks && tasks.length ? (
                                <ul className="b-list">
                                    {tasks.map((item, index) => (
                                        <TableTasks
                                            item={item}
                                            onClick={() => {
                                                ChangeTaskStatus(item._id);
                                            }}
                                        />
                                    ))}
                                </ul>
                            ) : (
                                <div className="b-empty-list">{locale.tasks.emptyList}</div>
                            )}
                            <Title
                                buttonClick={() => clickUploadFile(caseItem.documentFolderId)}
                                buttonText={locale.documents.upload}
                                title={locale.documents.uploadFiles}
                            />
                            <div className="b-list">
                                {folders && folders.length ? (
                                    folders.map((item, index) => <ul>{renderFolders(item, index)}</ul>)
                                ) : (
                                    <Fragment/>
                                )}
                                {files && files.length ? (
                                    <ul>
                                        {files.map((item, index) => (
                                            <TableFiles
                                                file={item}
                                                deleteFile={() => deleteFile(item._id)}
                                                index={index}
                                                locale={locale}
                                            />
                                        ))}
                                    </ul>
                                ) : (
                                    <Fragment/>
                                )}

                                {(!files && !folders) || (!files.length && !folders.length) ? (
                                    <div className="b-empty-list">{locale.documents.emptyList}</div>
                                ) : (
                                    <Fragment/>
                                )}
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </div>
    );
};

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        caseItem: store.caseItem,
        tasks: store.tasks,
        folders: store.folders,
        files: store.files,
        caseSummary: store.caseSummary,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetCaseServiceData: (id, documentFolderId) => dispatch(ServiceActions.GetCaseServiceData(id, documentFolderId)),
        ChangeTaskStatus: (taskId) => dispatch(ServiceActions.ChangeTaskStatus(taskId)),
        SetTaskStatus: (task) => dispatch(ServiceActions.SetTaskStatus(task)),
        GetCaseSummary: (id) => dispatch(ServiceActions.GetCaseSummary(id)),
        UploadFileToFolder: (options, id, documentFolderId) =>
            dispatch(ServiceActions.UploadFileToFolder(options, id, documentFolderId)),
        DeleteFile: (fileId, caseId, documentFolderId) =>
            dispatch(ServiceActions.DeleteFile(fileId, caseId, documentFolderId)),
        ClearSelectedData: () => dispatch(ServiceActions.ClearSelectedData()),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CardService);
