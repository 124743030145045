import React, {Fragment, useState, useEffect} from 'react';
import {connect} from 'react-redux';
import ServiceActions from '../../redux/actions/service';

import {
    HeaderList,
    Bot,
    DropdownChangeName,
    DropdownChangePassword,
    DropdownConfirm
} from '@components'

const Consultation = ({locale, configuration, ChangeVisibilityBot, visibilityBot}) => {
    const [subactionShow, setSubactionsShow] = useState(false);
    const [subactionTemplate, setsubactionTemplate] = useState(null);

    const [dropdownState, setDropdownState] = useState(false);

    const dropItemHandler = (item) => {
        if (item.url) {
            console.log(item);
            window.location.assign(item.url);
        }
    }

    const clickAskLawyer = () => {
        if (visibilityBot) {
            ChangeVisibilityBot(false);
        } else {
            ChangeVisibilityBot(true);
        }
    }
    useEffect(() => {
        document.title = locale ? locale.tabs.askLawyer : 'Legal Desk';
    }, [locale]);

    let optionsDropdown;

    if (locale) {
        optionsDropdown = {
            iconClass: 'b-icon b-icon__menu b-icon__menu_profile',
            title: 'Actions',
            isIcon: true,
            right: 0,
            subactionShow,
            subactionWidth: '320px',
            subactionTemplate,
            top: '100%',
            items: [
                {
                    title: locale.dropmenu.items.changeName,
                    isIcon: false,
                    subactionTemplate: <DropdownChangeName
                        locale={locale}
                        setDropdownState={setDropdownState}
                        configuration={configuration}
                        backOnClick={() => setSubactionsShow(false)}
                    />,
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    }
                },
                {
                    title: locale.dropmenu.items.changePassword,
                    isIcon: false,
                    subactionTemplate: <DropdownChangePassword
                        locale={locale}
                        setDropdownState={setDropdownState}
                        backOnClick={() => setSubactionsShow(false)}
                    />,
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    }
                },
                {
                    action: dropItemHandler,
                    url: '/admin/products',
                    isIcon: false,
                    title: locale.dropmenu.items.admin,
                    hidden: !configuration.user.isAdmin
                },
                {
                    title: locale.dropmenu.items.logOut,
                    isIcon: false,
                    subactionTemplate: (
                        <DropdownConfirm
                            title={locale.dropmenu.items.logOut}
                            cancelText={locale.buttons.cancel}
                            saveText={locale.dropmenu.items.logOut}
                            message={locale.authentication.logout.confirm.replace(
                                /%name%/gi,
                                `<b>${configuration.user.first_name} ${configuration.user.last_name}</b>`
                            )}
                            backOnClick={() => setSubactionsShow(false)}
                            saveOnClick={() => {
                                setSubactionsShow(false);
                                setDropdownState(false);
                                window.location.href = '/authentication/account/logout';
                            }}
                        />
                    ),
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    },
                },
            ]
        }
    }

    return (
        <div>
            {locale && locale.card && (
                <Fragment>
                    <HeaderList
                        dropdownState={dropdownState}
                        setDropdownState={setDropdownState}
                        setSubactionsShow={setSubactionsShow}
                        onClick={() => clickAskLawyer()}
                        nameBtn={locale.tabs.askLawyer}
                        activeBtn={visibilityBot}
                        tabs={[
                            {
                                active: true,
                                name: locale.tabs.myCases,
                                url: '/cases'
                            },
                            {
                                active: false,
                                name: locale.tabs.legalServices,
                                url: '/products'
                            },
                            {
                                active: false,
                                name: locale.tabs.profile,
                                url: '/profile'
                            }
                        ]}
                        optionsDropdown={optionsDropdown}
                    />
                    <div className={visibilityBot ? 'l-content l-content__fill' : 'l-content'}>
                        <div className="b-content b-content__full">
                            <div className="b-content-wrapper">
                                <div className="b-main-content b-main-content__white b-main-content__full">
                                    <Bot
                                        isNotFixed={true}
                                        configuration={configuration}
                                        isVisibleBot={visibilityBot}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        configuration: store.configuration,
        visibilityBot: store.visibilityBot
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        ChangeVisibilityBot: (visibilityBot) => dispatch(ServiceActions.ChangeVisibilityBot(visibilityBot))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(Consultation);
