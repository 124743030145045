import React, {Fragment, useState} from 'react';

import {
    TableFiles
} from '@components';

const TableFolders = ({folder, index, onUpload, deleteFile, locale, depth}) => {
    // Переменная depth нужны для подсчета количества уровней вложенности, default = 1
    let depthChildren = depth;
    const [isOpen, setOpen] = useState(folder.open);
    const onClickFolder = () => {
        setOpen(!isOpen);
    }
    // С помощью этой функции рекурсивно будем рендерить папки и файлы нижних уровней
    const renderFolders = (folders) => {
        depthChildren += 1;
        return folders.map((item, index) => (
            <ul>
                <TableFolders
                    folder={item}
                    index={index}
                    onUpload={onUpload}
                    deleteFile={deleteFile}
                    locale={locale}
                    depth={depthChildren}/>
            </ul>
        ))
    }
    // Расчет отсутпа в зависимости от уровня вложенности папки
    const styleFolder = depth > 1 ? {
        paddingLeft: `${20 + (16 * (depth - 1))}px`
    } : {};
    const styleEmptyOrFile = depth > 1 ? {
        paddingLeft: `${36 + (16 * (depth - 1))}px`
    } : {};
    return (
        <Fragment>
            <li className="b-list-item b-list-item__folder clickable" onClick={() => onClickFolder()}
                style={styleFolder}>
                <div className="b-list-item-link">
                    <i className={isOpen ? 'b-icon b-icon__folder open' : 'b-icon b-icon__folder'}/>
                    <div className="b-list-item-title ng-binding">{folder.name}</div>
                </div>
                <span className="b-link b-link__right ng-binding" onClick={() => onUpload(folder._id)}>
                    {locale.documents.upload}
                </span>
            </li>
            {isOpen && folder.folders && folder.folders.length ? (
                <li className="b-list">
                    {renderFolders(folder.folders)}
                </li>
            ) : (
                <Fragment/>
            )}
            {isOpen && folder.files && folder.files.length ? (
                <li className="b-list">
                    {
                        folder.files.map((item, index) => (
                            <ul>
                                <TableFiles
                                    file={item}
                                    deleteFile={() => deleteFile(item._id)}
                                    index={index}
                                    locale={locale}
                                    style={styleEmptyOrFile}
                                />
                            </ul>
                        ))
                    }
                </li>
            ) : (
                <Fragment/>
            )}
            {isOpen && !folder.files.length && !folder.folders.length ? (
                <li className="b-list">
                    <ul className="b-list">
                        <li className="b-list-item b-list-item__empty" style={styleEmptyOrFile}>
                            <div className="b-list-item-link">
                                <i className="b-icon b-icon__doc"/>
                                <h4 className="b-list-item-title">{locale.documents.isEmpty}</h4>
                            </div>
                        </li>
                    </ul>
                </li>
            ) : (
                <Fragment/>
            )}
        </Fragment>

    )
}

export default TableFolders;
