import React from 'react';
import {SelectInfinity} from '@components';

/**
 * Форма выбора пользователя для добавления к группе
 *
 * @param locale Объект локализации
 * @param formik Объект Formik, инициализированный хуком useFormik в родительском контейнере.
 * @returns {JSX.Element}
 * @constructor
 */
const FormAssignUserGroup = ({locale, formik}) => {
    const {values, setFieldValue} = formik;

    return (
        <div className="b-form">
            <div className="b-form-row">
                <div className="b-form-cell b-form-cell__12">
                    {/*<SelectDynamicHighLoad
                        url="/api/admin/v1.0/users/withoutgroups"
                        name="user"
                        placeholder={locale.admin.popup.group.user}
                        setFieldValue={setFieldValue}
                        value={values.user}
                    />*/}
                    <SelectInfinity
                        url="/api/admin/v1.0/users/withoutgroups"
                        name="user"
                        placeholder={locale.admin.popup.group.user}
                        setFieldValue={setFieldValue}
                        value={values.user}
                    />
                </div>
            </div>
        </div>
    );
};

export default FormAssignUserGroup;
