import { useEffect } from 'react';

// Хук для обработки клика вне элемента
const useOutsideClick = (ref, refButton, isOpen, callback) => {
    useEffect(() => {
        const handleClick = (e) => {
            if (!ref?.current?.contains(e.target) && !refButton?.current?.contains(e.target)) {
                callback();
            }
        };
        if (isOpen) {
            document.addEventListener('mousedown', handleClick);
        } else {
            document.removeEventListener('mousedown', handleClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleClick);
        };
    }, [isOpen]);
};

export default useOutsideClick;
