import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Title, TableAdmin, PaginationTable } from '@components';
import { AdminActions } from '@redux';
import {
    usersListSelector,
    usersTotalSelector,
    usersPagesSelector,
    usersItemsOnPageSelector,
} from '@redux/selectors/admin/users';
import { localeSelector, langSelector } from '@redux/selectors/locale';
import IconSearch from '../../assets/images/icons/icon_search.svg';
import IconSearchActive from '../../assets/images/icons/icon_search_active.svg';
import useDebounce from '../../hooks/useDebounce';

const getEmailOrLogin = (local) => {
    const { email, loginAD } = local;
    if (email) {
        return email;
    }

    if (loginAD) {
        return loginAD;
    }

    return '';
};

const Users = () => {
    const limit = 15;
    const dispatch = useDispatch();
    const { page } = useParams();
    const locale = useSelector(localeSelector);
    const lang = useSelector(langSelector);
    const users = useSelector(usersListSelector) || [];
    const usersTotalNumber = useSelector(usersTotalSelector);
    const usersPagesNumber = useSelector(usersPagesSelector);
    const itemsOnPage = useSelector(usersItemsOnPageSelector);
    const history = useHistory();

    // Навигация
    const onNextPageClick = (event, page) => {
        history.push(`/users/list/${page}`);
    };

    const onPrevPageClick = (event, page) => {
        history.push(`/users/list/${page}`);
    };

    const routeToUser = (id) => {
        history.push(`/users/${id}/card`);
    };

    // Импорт пользователей из csv
    const inputImport = useRef();
    const importDialog = () => {
        inputImport.current.click();
    };
    const onImportSuccess = () => {
        dispatch(AdminActions.GetUsers(page, limit));
    };
    const onChangeImportFile = (e) => {
        dispatch(AdminActions.ImportUsers(e.currentTarget.files, onImportSuccess));
        e.currentTarget.files = null;
    };
    const onClickImportFile = (e) => {
        e.currentTarget.value = null;
    };

    // Поиск пользователя по имени, фамилии, email
    const [focusSearch, setFocusSearch] = useState(false);
    const onFocusSearch = () => setFocusSearch(true);
    const onBlurSearch = () => setFocusSearch(false);
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const searchHandle = (event) => {
        setSearchTerm(event.target.value);
    };
    const clearSearchTerm = () => {
        setSearchTerm('');
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            dispatch(AdminActions.SearchUsers(debouncedSearchTerm, page, limit));
        } else {
            dispatch(AdminActions.GetUsers(page, limit));
        }
    }, [debouncedSearchTerm, page, dispatch]);

    return (
        users &&
        locale && (
            <>
                <div className="b-action-panel">
                    <Title title={locale.admin.menu.users} />
                    <div className="b-action-panel">
                        <div className="b-search-input">
                            <img
                                className="search-icon"
                                src={focusSearch ? IconSearchActive : IconSearch}
                                alt="Поиск"
                            />
                            <input
                                value={searchTerm}
                                autoComplete="off"
                                name="userSearch"
                                placeholder={focusSearch || 'Поиск...'}
                                onChange={searchHandle}
                                onFocus={onFocusSearch}
                                onBlur={onBlurSearch}
                            />
                            {debouncedSearchTerm && <button className="search-clear" onClick={clearSearchTerm} />}
                            {!debouncedSearchTerm && <div className="search-clear-none" />}
                        </div>
                        <button className="b-button-create" onClick={importDialog} type="button">
                            {locale.admin.users.import}
                        </button>
                    </div>
                </div>
                <input
                    className="b-import-users"
                    type="file"
                    accept=".csv"
                    ref={inputImport}
                    onChange={onChangeImportFile}
                    onClick={onClickImportFile}
                />

                <PaginationTable
                    data={users}
                    lang={lang}
                    locale={locale}
                    loading={false}
                    page={Number(page)}
                    totalItems={usersTotalNumber}
                    totalPages={usersPagesNumber}
                    itemsOnPage={itemsOnPage}
                    onNextPageClick={onNextPageClick}
                    onPrevPageClick={onPrevPageClick}
                    tableRenderer={(data, locale, lang) => {
                        return data.map((user) => (
                            <TableAdmin
                                key={`users-table-user-${user._id}`}
                                id={user._id}
                                data={{
                                    name: {
                                        type: 'title',
                                        data: user.first_name + ' ' + user.last_name,
                                    },
                                    email: {
                                        type: 'text',
                                        width: 180,
                                        data: user.local && getEmailOrLogin(user.local),
                                    },
                                    status: {
                                        type: 'text',
                                        width: 100,
                                        data:
                                            user.status === 'active'
                                                ? locale.admin.userstatus.active
                                                : locale.admin.userstatus.inactive,
                                    },
                                    role: {
                                        type: 'text',
                                        width: 100,
                                        data: user.isAdmin
                                            ? locale.admin.userstatus.administrator
                                            : locale.admin.userstatus.usual,
                                    },
                                    created: {
                                        type: 'date',
                                        data: user.dueDate,
                                    },
                                }}
                                onClick={() => routeToUser(user._id)}
                                lang={lang}
                            />
                        ));
                    }}
                />
            </>
        )
    );
};

export default Users;
