import React, {Fragment, useState, useEffect} from "react";
import {Link, useHistory} from 'react-router-dom';
import {connect} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {Api} from '@utils';
import {Input} from '@components';

const Reset = ({locale}) => {
    const [errorType, setErrorType] = useState('');
    const history = useHistory();

    const validationSchema = Yup.object({
        email: Yup.string().email().min(5).required()
    });

    const formik = useFormik({
        isSubmitting: false,
        initialValues: {
            email: ''
        },
        validationSchema,
        onSubmit: values => {
            formik.isSubmitting = true;
            Api.Reset(values)
                .then(() => {
                    setErrorType('');
                    // Redirect to service
                    history.push('/fogot/complete')
                })
                .catch(({response}) => {
                    formik.setSubmitting(false);
                    setErrorType(response.data.error);
                })

        },
    });

    useEffect(() => {
        document.title = locale ? locale.authentication.fogotPassword.title : 'Legal Desk';
    }, [locale]);

    return (
        <Fragment>
            {locale && locale.authentication && (
                <Fragment>
                    <h3 className="b-form-title">
                        {locale.authentication.fogotPassword.title}
                    </h3>
                    <div className="b-form-text">
                        {locale.authentication.fogotPassword.description}
                    </div>
                    <form name="submitForm" onSubmit={formik.handleSubmit}>
                        <Input
                            placeholder={locale.inputs.email}
                            type="text"
                            name="email"
                            error={errorType ? locale.authentication.error.login[errorType] : null}
                            {...formik.getFieldProps('email')} />
                        <div className="b-actions">
                            <button className="b-button b-button__uppercase" type="submit"
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                <div
                                    className="b-button-text">{formik.isSubmitting ? locale.authentication.controls.fogotPasswordLoading : locale.authentication.controls.fogotPassword}</div>
                            </button>
                        </div>
                    </form>

                    <Link className="b-link b-link__action" to="/login">
                        {locale.authentication.fogotPassword.action}
                    </Link>
                </Fragment>
            )}
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale
    }
}

export default connect(mapStateToProps)(Reset);
