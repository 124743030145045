import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const Empty = ({title, text}) => {
    return (
        <Fragment>
            <div className="b-empty">
                <div className="b-empty-container">
                    <i className="b-icon b-icon__empty"/>
                    <div className="b-empty-text">
                        {title}
                    </div>
                    <div className="b-empty-description">
                        {text}
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

Empty.propTypes = {
    /** Заголовок */
    title: PropTypes.string,
    /** Текст */
    text: PropTypes.string
};

Empty.defaultProps = {
    title: null,
    text: null
};

export default Empty;
