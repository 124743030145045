import React from 'react';

const DropdownConfitm = ({title, message, cancelText, saveText, backOnClick, saveOnClick}) => {
    return (
        <div className="b-dropmenu-subactions show">
            <div className="b-form">
                <div className="b-dropmenu-subactions-title">
                    <i className="b-icon b-icon__back_blue" onClick={backOnClick}/>
                    {title}
                </div>
                <div className="b-dropmenu-subactions-content">
                    <p dangerouslySetInnerHTML={{__html: message}}/>
                </div>
                <div className="b-dropmenu-subactions-actions">
                    <button className="b-button b-button__cancel" onClick={backOnClick}>
                        {cancelText}
                    </button>
                    <button className="b-button b-button__confirm" onClick={saveOnClick}>
                        {saveText}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default DropdownConfitm;
