const initialState = {
    locale: null,
    currency: 'rub',
    isCustomInstances: false,
    lang: 'ru',
    cases: [],
    links: null,
    caseItem: null,
    archiveCases: [],
    casesCategories: [],
    productsCategories: [],
    products: [],
    createdCase: {},
    profileSummary: null,
    caseSummary: null,
    tasks: null,
    folders: null,
    files: null,
    configuration: null,
    visibilityBot: false,
    isReady: {
        products: false,
        productsCategories: false,
        cases: false,
        casesCategories: false,
    },
    dictionary: {
        ready: false,
        productCategories: [],
        productTypes: [],
        productStatuses: [],
        products: [],
    },
    caseFilter: null,
};

export function rootReducer(state = initialState, { type, payload }) {
    switch (type) {
        case 'SET_SETTINGS':
            return {
                ...state,
                ...payload,
            };
        case 'SET_CASES':
            return {
                ...state,
                cases: payload.list,
                isReady: {
                    ...state.isReady,
                    cases: true,
                },
            };
        case 'SET_LINKS':
            return {
                ...state,
                links: payload.list,
            };
        case 'SET_CASE_ITEM':
            if (payload.redirectUrl) {
                window.location.href = '/cases';
            }
            return {
                ...state,
                caseItem: payload,
            };
        case 'SET_CASE_SUMMARY':
            return {
                ...state,
                caseSummary: payload,
            };
        case 'SET_TASK_STATUS':
            return {
                ...state,
                tasks: state.tasks.map((task) => (task._id === payload._id ? { ...task, ...payload } : task)),
            };
        case 'SET_ARCHIVE_CASES':
            return {
                ...state,
                archiveCases: payload.list,
            };
        case 'SET_CASE_SERVICEDATA':
            return {
                ...state,
                tasks: payload.tasks,
                folders: payload.folders,
                files: payload.files,
            };
        case 'CLEAR_SELECTED_DATA':
            return {
                ...state,
                caseSummary: null,
                caseItem: null,
                tasks: null,
                folders: null,
                files: null,
            };
        case 'SET_CASES_CATEGORIES':
            return {
                ...state,
                casesCategories: payload.list,
                isReady: {
                    ...state.isReady,
                    casesCategories: true,
                },
            };
        case 'SET_PRODUCTS_CATEGORIES':
            return {
                ...state,
                productsCategories: payload.list,
                isReady: {
                    ...state.isReady,
                    productsCategories: true,
                },
            };
        case 'SET_CATEGORY_ID':
            let filteredCases = state.cases.filter((item) => item.product.metadata.category === payload);
            return {
                ...state,
                cases: filteredCases,
            };
        case 'SET_PRODUCTS':
            return {
                ...state,
                products: payload.list,
                isReady: {
                    ...state.isReady,
                    products: true,
                },
            };
        case 'SET_CREATE_CASE':
            window.location.href = '/cases/' + payload._id + '/service';
            return {
                ...state,
                createdCase: payload,
            };
        case 'SET_PROFILE_SUMMARY':
            return {
                ...state,
                profileSummary: payload.list,
            };
        case 'SET_DELETE_CASE':
            return {
                ...state,
                cases: state.cases.filter((item) => item._id !== payload),
            };
        case 'SET_ARCHIVE_CASE':
            return {
                ...state,
                // eslint-disable-next-line array-callback-return
                cases: state.cases.filter((item) => {
                    if (item._id === payload) {
                        state.archiveCases.push(item);
                    } else {
                        return true;
                    }
                }),
            };
        case 'SET_VISIBILITY_BOT':
            return {
                ...state,
                visibilityBot: payload,
            };
        case 'SET_TASKS':
            return {
                ...state,
                tasks: payload,
            };
        case 'SET_FOLDERS':
            return {
                ...state,
                folders: payload,
            };
        case 'SET_FILES':
            return {
                ...state,
                files: payload,
            };
        case 'SET_USER':
            return {
                ...state,
                configuration: {
                    ...state.configuration,
                    user: payload,
                },
            };
        case 'SET_PRODUCTS_READY':
            return {
                ...state,
                isReady: {
                    ...state.isReady,
                    products: payload,
                },
            };
        case 'SET_PRODUCTS_CATEGORIES_READY':
            return {
                ...state,
                isReady: {
                    ...state.isReady,
                    productsCategories: payload,
                },
            };
        case 'SET_CASES_READY':
            return {
                ...state,
                isReady: {
                    ...state.isReady,
                    cases: payload,
                },
            };
        case 'SET_CASES_CATEGORIES_READY':
            return {
                ...state,
                isReady: {
                    ...state.isReady,
                    casesCategories: payload,
                },
            };
        case 'SET_PRODUCTS_DICTIONARY':
            return {
                ...state,
                dictionary: {
                    ...state.dictionary,
                    products: payload,
                },
            };
        case 'SET_PRODUCT_TYPES_DICTIONARY':
            return {
                ...state,
                dictionary: {
                    ...state.dictionary,
                    productTypes: payload,
                },
            };
        case 'SET_PRODUCT_STATUSES_DICTIONARY':
            return {
                ...state,
                dictionary: {
                    ...state.dictionary,
                    productStatuses: payload,
                },
            };
        case 'SET_PRODUCT_CATEGORIES_DICTIONARY':
            return {
                ...state,
                dictionary: {
                    ...state.dictionary,
                    productCategories: payload,
                },
            };
        case 'SET_CASE_FILTER':
            return {
                ...state,
                caseFilter: {
                    ...payload,
                },
            };
        case 'SET_CASE_FILTER_DICTIONARIES':
            return {
                ...state,
                dictionary: {
                    ...payload,
                },
            };
        default:
            return state;
    }
}
