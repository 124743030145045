import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {AdminActions} from '@redux';
import {Title, Input, TableAdmin, Dialog, PaginationTable} from '@components';
import FormAssignUserGroup from './FormAssignUserGroup';

const FormGroupAdmin = ({
                            group,
                            usersGroups,
                            productsGroups,
                            locale,
                            onSubmit,
                            addUserToGroup,
                            deleteUserFromGroup,
                            onNextPageClick,
                            onPrevPageClick,
                            totalUsers,
                            usersPage,
                            totalUsersPage,
                            itemsOnUsersPage,
                        }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            dispatch(AdminActions.SetGroup(null));
        };
    }, [dispatch]);

    const validationSchema = Yup.object({
        name: Yup.string()
            .max(56)
            .required(),
        description: Yup.string().max(512),
    });

    const [isAssignUserGroupOpen, setIsAssignUserGroupOpen] = useState(false);

    const {values, errors, handleSubmit, handleChange, setFieldValue, getFieldProps} = useFormik({
        validationSchema,
        initialValues: group,
        onSubmit,
        enableReinitialize: true,
    });

    useEffect(() => {
        setFieldValue('users', group.users);
    }, [setFieldValue, group.users]);

    const assignUserGroupFormik = useFormik({
        initialValues: {
            user: '',
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            addUserToGroup(values);
            setIsAssignUserGroupOpen(false);
        },
    });

    const getProductName = (productId) => {
        const product = productsGroups.find((product) => product._id === productId);

        return product && product.name;
    };

    const availableProducts = productsGroups.map((product) => product._id);

    const toggleAccess = (productId) => {
        const products = Object.assign({}, values.products, {
            [productId]: !values.products[productId],
        });
        setFieldValue('products', products);
    };

    const addUser = () => {
        setIsAssignUserGroupOpen(true);
    };

    const users = values.users
        .map((userId) => {
            return usersGroups.find((user) => user._id === userId);
        })
        .filter((user) => user);

    const groupHasUsers = users.length > 0;

    return (
        <>
            <div className="b-form">
                <Title
                    title={group.name}
                    buttonText={locale.admin.popup.actions.save}
                    buttonClick={handleSubmit}
                    buttonIcon="save"
                />
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__4">
                        <Input
                            name="name"
                            type="text"
                            value={group.name}
                            placeholder={locale.admin.popup.group.name}
                            error={errors.name}
                            onChange={handleChange}
                            {...getFieldProps('name')}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__8">
                        <Input
                            name="description"
                            type="text"
                            value={group.description}
                            placeholder={locale.admin.popup.group.description}
                            error={errors.description}
                            onChange={handleChange}
                            {...getFieldProps('description')}
                        />
                    </div>
                </div>
            </div>
            <div className="ld-form">
                <Title title={locale.admin.menu.products}/>
                {Object.keys(values.products).map((productId) => {
                    return (
                        availableProducts.includes(productId) && (
                            <TableAdmin
                                key={productId}
                                id={productId}
                                data={{
                                    name: {
                                        type: 'text',
                                        width: 750,
                                        data: getProductName(productId),
                                    },
                                    access: {
                                        type: 'text',
                                        width: 210,
                                        data:
                                            values.products[productId] === true
                                                ? locale.admin.table.accessAllowed
                                                : locale.admin.table.accessDenied,
                                    },
                                }}
                                onClick={toggleAccess}
                            />
                        )
                    );
                })}
            </div>
            <div className="ld-form">
                <Title
                    title={locale.admin.menu.users}
                    buttonClick={() => {
                        addUser();
                        assignUserGroupFormik.resetForm();
                    }}
                    buttonText={locale.admin.popup.group.addUser}
                />
                {groupHasUsers && (
                    <PaginationTable
                        data={users}
                        page={usersPage}
                        itemsOnPage={itemsOnUsersPage}
                        totalPages={totalUsersPage}
                        totalItems={totalUsers}
                        loading={false}
                        locale={locale}
                        onPrevPageClick={onPrevPageClick}
                        onNextPageClick={onNextPageClick}
                        tableRenderer={(data) => {
                            return data.map((user) => (
                                <TableAdmin
                                    key={user._id}
                                    id={user._id}
                                    data={{
                                        name: {
                                            type: 'text',
                                            width: 750,
                                            data: user.first_name + ' ' + user.last_name,
                                        },
                                        email: {
                                            type: 'text',
                                            width: 210,
                                            data: user.local.email,
                                        },
                                    }}
                                    onRemove={deleteUserFromGroup}
                                />
                            ));
                        }}
                    />
                )}
            </div>
            {isAssignUserGroupOpen && (
                <Dialog
                    title={locale.admin.popup.group.addUser}
                    locale={locale}
                    isOpen={isAssignUserGroupOpen}
                    onClose={() => setIsAssignUserGroupOpen(false)}
                    onSubmit={assignUserGroupFormik.handleSubmit}
                >
                    <FormAssignUserGroup locale={locale} formik={assignUserGroupFormik}/>
                </Dialog>
            )}
        </>
    );
};

export default FormGroupAdmin;
