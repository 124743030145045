import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const Button = ({type, text, disabled, style, icon}) => {
    return (
        <Fragment>
            {icon === 'facebook' ? (
                <button
                    className="b-button b-button__blue"
                    disabled={disabled}>
                    <i className="b-icon b-icon__facebook"/>
                    <span className="g-vmiddle">
                        {text}
                    </span>
                </button>
            ) : (
                <button
                    className="b-button b-button__uppercase"
                    type={type}
                    style={style}
                    disabled={disabled}>
                    <div className="b-button-text">
                        {text}
                    </div>
                </button>
            )}
        </Fragment>
    )
}

Button.propTypes = {
    /** Тип кнопки {button|submit|reset} */
    type: PropTypes.string,
    /** Текст кнопки */
    text: PropTypes.string,
    /** Флаг заблокированной кнопки, если true - кнопка заблокирована */
    disabled: PropTypes.bool,
    /** Объект с описанием кастомных стилей кнопки */
    style: PropTypes.object,
    /** Тип иконки, если не "null" - отобразится кнопка с иконкой */
    icon: PropTypes.string
};

Button.defaultProps = {
    type: 'submit',
    text: null,
    disabled: false,
    style: null,
    icon: null
};

export default Button;
