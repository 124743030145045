import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

const ButtonIcon = ({type, text, disabled, active, onClick, style, icon}) => {
    return (
        <Fragment>
            <div
                className={active ? 'b-menu-icon b-menu-icon_askLawyer active' : 'b-menu-icon b-menu-icon_askLawyer'}
                onClick={() => onClick()}>
                <i className="b-icon b-icon__menu b-icon__menu_askLawyer" />
                {text}
            </div>
        </Fragment>
    )
}
ButtonIcon.propTypes = {
    /** Тип кнопки {button|submit|reset} */
    type: PropTypes.string,
    /** Текст кнопки */
    text: PropTypes.string,
    /** Флаг заблокированной кнопки, если true - кнопка заблокирована */
    disabled: PropTypes.bool,
    /** Фдаг активной кнопки, если true - кнопка активна */
    active: PropTypes.bool,
    /** Функция нажатия на кнопку */
    onClick: PropTypes.func,
    /** Объект с описанием кастомных стилей кнопки */
    style: PropTypes.object,
    /** Тип иконки, если не "null" - отобразится кнопка с иконкой */
    icon: PropTypes.string
};

ButtonIcon.defaultProps = {
    type: 'submit',
    text: null,
    disabled: false,
    active: false,
    onClick: null,
    style: null,
    icon: null
};


export default ButtonIcon;
