import {createStore, applyMiddleware, compose} from 'redux'
import thunk from "redux-thunk";
import {rootReducer} from '../reducers/auth'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];

const AuthStore = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default AuthStore;
