import React, {Fragment, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';

/* eslint-disable jsx-a11y/anchor-has-content */

import {Dropdown} from '@components';

const HeaderCard = ({
                        title,
                        number,
                        showBack,
                        setSubactionsShow,
                        optionsDropdown,
                        style,
                        setDropdownState,
                        dropdownState,
                        status,
                    }) => {
    const history = useHistory();
    const refBlock = useRef();

    const goTo = (url) => {
        if (url) {
            history.push(url);
        } else {
            history.goBack();
        }
    }

    return (
        <Fragment>
            <div className="l-header" style={style}>
                <div className="b-header">
                    {showBack ? (
                        <a onClick={() => goTo()} className="b-link-back"/>
                    ) : (
                        <a onClick={() => goTo('/cases')} className="b-logo"/>
                    )}
                    <h3 className="b-header-title">
                        {number && (
                            <div className="b-number">
                                {number}
                            </div>
                        )}
                        {!showBack && (
                            <div className="b-header-title-text">
                                {title}
                            </div>
                        )}
                    </h3>
                    {!status
                        ? ''
                        : <div className="b-header__status">
                           <p>{status}</p>
                          </div>
                    }
                    <div className="b-menu-icon" ref={refBlock}>
                        <Dropdown
                            options={optionsDropdown}
                            setSubactionsShow={setSubactionsShow}
                            refBlock={refBlock}
                            dropdownState={dropdownState}
                            setDropdownState={setDropdownState}/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

HeaderCard.propTypes = {
    /** Заголовок */
    title: PropTypes.string,
    /** Статус дела */
    status: PropTypes.string,
    /** Номер дела */
    number: PropTypes.string,
    /** Флаг отображения кнопки назад */
    showBack: PropTypes.bool,
    /** Флаг отображения шаблона поддействия */
    setSubactionsShow: PropTypes.func,
    /** Опции выпадающего списка */
    optionsDropdown: PropTypes.object,
    /** Кастомные стили */
    style: PropTypes.object
};

HeaderCard.defaultProps = {
    title: null,
    status: null,
    number: null,
    showBack: null,
    setSubactionsShow: null,
    optionsDropdown: null,
    style: null,
};

export default HeaderCard;
