import React from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';

const Menu = ({tab}) => {
    const history = useHistory();
    const routeMatch = useRouteMatch(tab.url)
    const goTo = (url) => {
        history.push(url);
    }

    return (
        <li className="b-tabs-item">
            <a onClick={() => goTo(tab.url)} className={routeMatch ? 'b-link active' : 'b-link'}>
                {tab.name}
            </a>
        </li>
    )
}

export default Menu;
