import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {
    CasesContainer,
    ConsultationContainer,
    CaseServiceContainer,
    ProductsServiceContainer,
    ProfileContainer,
} from '@containers';

const ServiceRoutes = (
    <Switch>
        <Route path="/cases" exact component={CasesContainer}/>
        <Route path="/cases/consultation" exact component={ConsultationContainer}/>
        <Route path="/cases/:id/service" exact component={CaseServiceContainer}/>
        <Route path="/cases/:id/summary" exact component={CaseServiceContainer}/>
        <Route path="/cases/type/:categoryId" exact component={CasesContainer}/>
        <Route path="/products" exact component={ProductsServiceContainer}/>
        <Route path="/products/type/:categoryId" exact component={ProductsServiceContainer}/>
        <Route path="/profile" component={ProfileContainer}/>
        <Redirect from="/" to="/cases"/>
    </Switch>
);

export default ServiceRoutes;
