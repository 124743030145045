import React, { Fragment, useRef, useEffect, useState } from 'react';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ServiceActions } from '@redux';

import {
    Dropdown,
    ButtonIcon,
    Menu
} from '@components';

/* eslint-disable jsx-a11y/anchor-has-content */

const HeaderList = ({
                        nameBtn,
                        activeBtn,
                        optionsDropdown,
                        showBack,
                        tabs,
                        onClick,
                        setDropdownState,
                        dropdownState,
                        setSubactionsShow,
                        links,
                        GetLinks
                    }) => {
    const history = useHistory();
    const refBlock = useRef();
    const goTo = (url) => {
        if (url) {
            history.push(url);
        } else {
            history.goBack();
        }
    }

    useEffect(() => {
        GetLinks();
    }, [GetLinks])

    return (
        <Fragment>
            <div className="l-header">
                <div className="b-header">
                    {showBack ? (
                        <a onClick={() => goTo()} className="b-link-back"/>
                    ) : (
                        <a onClick={() => goTo('/cases')} className="b-logo"/>
                    )}
                    {tabs && (
                        <div className="b-tabs">
                            <ul className="b-tabs-wrapper g-menu-animation">
                                {tabs.map((tab, indexTab) => (
                                    <Menu tab={tab} key={indexTab}/>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className="links-header">
                        {links && links.map(item => {
                            if (item.openLink === "Открыть в новом окне") {
                                return <a key={item._id} className="h-link" href={item.link} target="_blank">{item.nameLink}</a>
                            }
                            return <a key={item._id} className="h-link" href={item.link}>{item.nameLink}</a>
                        })}
                    </div>
                    <ButtonIcon text={nameBtn} active={activeBtn} onClick={onClick}/>
                    <div className="b-menu-icon" ref={refBlock}>
                        <Dropdown
                            options={optionsDropdown}
                            refBlock={refBlock}
                            dropdownState={dropdownState}
                            setDropdownState={setDropdownState}
                            setSubactionsShow={setSubactionsShow}/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

HeaderList.propTypes = {
    /** Текст кнопки консультации */
    nameBtn: PropTypes.string,
    /** Флаг активности кнопки консультации */
    activeBtn: PropTypes.bool,
    /** Опции выпадающего списка */
    optionsDropdown: PropTypes.object,
    /** Флаг отображения кнопки назад */
    showBack: PropTypes.bool,
    /** Массив элементов меню */
    tabs: PropTypes.array
};

HeaderList.defaultProps = {
    nameBtn: null,
    activeBtn: null,
    optionsDropdown: null,
    showBack: null,
    tabs: null
};

const mapStateToProps = store => {
    return {
        links: store.links
    }
}

const mapDispatchToProps = dispatch => {
    return {
        GetLinks: () => dispatch(ServiceActions.GetLinks())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderList);
