import React, {Fragment, useState, useEffect} from "react";
import {connect} from 'react-redux'
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {Api, Formatter} from '@utils';
import {Input} from '@components';

const Registration = ({locale, loginFB, allowRestrictEmailSignUp}) => {
    const [errorType, setErrorType] = useState('');

    const loginFBHandler = () => {
        window.location.href = '/authentication/account/signup/facebook';
    };

    const validationSchema = Yup.object({
        email: Yup.string().email().min(5).required(),
        first_name: Yup.string().min(2).required(),
        last_name: Yup.string().min(2).required(),
        password: Yup.string().min(3).required()
    });

    const formik = useFormik({
        isSubmitting: false,
        initialValues: {
            email: '',
            first_name: '',
            last_name: '',
            password: ''
        },
        validationSchema,
        onSubmit: values => {
            formik.isSubmitting = true;
            Api.SignUp(values)
                .then(() => {
                    setErrorType('');

                    if (allowRestrictEmailSignUp) {
                        window.location.assign('/authentication/account/confirm');
                    } else {
                        window.location.assign('/cases');
                    }
                })
                .catch(({response}) => {
                    formik.setSubmitting(false);

                    if (response.status === 404) {
                        window.location.assign('/cases/consultation');
                    }

                    setErrorType(response?.data?.error);
                })

        },
    });

    useEffect(() => {
        document.title = locale ? locale.authentication.signUp.title : 'Legal Desk';
    }, [locale]);

    return (
        <Fragment>
            {locale && locale.authentication && (
                <Fragment>
                    <h3 className="b-form-title">
                        {locale.authentication.signUp.title}
                    </h3>
                    <div className="b-form-text">
                        {Formatter.formatLink(locale.authentication.signUp.description)}
                    </div>
                    <form name="submitForm" onSubmit={formik.handleSubmit} autoComplete="off"
                          className='form-registration'>
                        <Input
                            placeholder={locale.inputs.email}
                            type="text"
                            name="email"
                            error={(errorType && errorType === 'email') || (errorType && errorType === 'emailNotAllowed') ? locale.authentication.error.signup[errorType] : null}
                            {...formik.getFieldProps('email')} />
                        <Input
                            placeholder={locale.inputs.firstName}
                            type="text"
                            name="first_name"
                            error={errorType && errorType === 'first_name' ? locale.authentication.error.signup[errorType] : null}
                            {...formik.getFieldProps('first_name')} />
                        <Input
                            placeholder={locale.inputs.lastName}
                            type="text"
                            name="last_name"
                            error={errorType && errorType === 'last_name' ? locale.authentication.error.signup[errorType] : null}
                            {...formik.getFieldProps('last_name')} />
                        <Input
                            placeholder={locale.inputs.password}
                            type="password"
                            name="password"
                            error={errorType && errorType === 'password' ? locale.authentication.error.signUp[errorType] : null}
                            {...formik.getFieldProps('password')} />
                        <div className="b-actions">
                            <button className="b-button b-button__uppercase b-registration-local" type="submit"
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                <div
                                    className="b-button-text">{formik.isSubmitting ? locale.authentication.controls.signUpLoading : locale.authentication.controls.signUp}</div>
                            </button>
                            {loginFB && (
                                <button onClick={() => loginFBHandler()}
                                        className="b-button b-button__blue b-registration-facebook">
                                    <i className="b-icon b-icon__facebook"/>
                                    <span className="g-vmiddle">{locale.authentication.controls.signUpFacebook}</span>
                                </button>
                            )}
                        </div>
                    </form>
                </Fragment>
            )}
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        loginFB: store.loginFB,
        allowRestrictEmailSignUp: store.allowRestrictEmailSignUp
    }
}

export default connect(mapStateToProps)(Registration);
