import React, {Fragment, useState, useEffect} from "react";
import {Link, useHistory, useParams} from 'react-router-dom';
import {connect} from 'react-redux';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {Api} from '@utils';
import {Input} from '@components';

const ResetRecovery = ({locale}) => {
    const [errorType, setErrorType] = useState('');
    const history = useHistory();
    const {key} = useParams();

    const validationSchema = Yup.object({
        newPassword: Yup.string().min(3).required(),
        reenterNewPassword: Yup.string().min(3).required()
    });

    const formik = useFormik({
        isSubmitting: false,
        initialValues: {
            newPassword: '',
            reenterNewPassword: ''
        },
        validationSchema,
        onSubmit: values => {
            formik.isSubmitting = true;
            Api.Recovery(values, key)
                .then((response) => {
                    setErrorType('');
                    // Redirect to overdue
                    if (response.data && response.data.error) {
                        if (response.data.error === 'overdue') {
                            history.push('/password/recovery/overdue')
                        }
                    } else {
                        history.push('/password/successful');
                    }
                })
                .catch((response) => {
                    if (!response) {
                        console.log(response)
                    }
                    console.log(response)
                    formik.setSubmitting(false);
                    setErrorType(response.data.error);
                })
        }
    });

    useEffect(() => {
        document.title = locale ? locale.authentication.recovery.title : 'Legal Desk';
    }, [locale]);

    return (
        <Fragment>
            {locale && locale.authentication && (
                <div className="b-recovery-form">
                    <h3 className="b-form-title">
                        {locale.authentication.recovery.title}
                    </h3>
                    <form name="submitForm" onSubmit={formik.handleSubmit}>
                        <Input
                            placeholder={locale.authentication.recovery.password}
                            type="password"
                            name="newPassword"
                            error={errorType === 'newPassword' ? locale.authentication.error.recovery.newPassword : null}
                            {...formik.getFieldProps('newPassword')} />
                        <Input
                            placeholder={locale.authentication.recovery.confirmPassword}
                            type="password"
                            name="reenterNewPassword"
                            error={errorType === 'reenterNewPassword' ? locale.authentication.error.recovery.reenterNewPassword : null}
                            {...formik.getFieldProps('reenterNewPassword')} />
                        <div className="b-actions">
                            <button className="b-button b-button__uppercase" type="submit"
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                <div className="b-button-text">
                                    {locale.authentication.recovery.setPasswordButton}
                                </div>
                            </button>
                        </div>
                    </form>
                    <Link className="b-link b-link__action" to="/login">
                        {locale.authentication.recovery.backToSignIn}
                    </Link>
                </div>
            )}
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale
    }
}

export default connect(mapStateToProps)(ResetRecovery);
