import React, {Fragment} from 'react';

import { Input, Spinner, Select } from '@components';

const FormLinkHeaderAdmin = ({ locale, formik, loading }) => {
    return (
        <Fragment>
            {loading && (
                <div className="b-modal-spinner">
                    <Spinner title={locale.card.loading} />
                </div>
            )}
            {locale && !loading && formik?.values && (<div className="b-form">
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__4">
                        <Input
                            name="nameLink"
                            type="text"
                            error={!!formik.errors?.nameLink}
                            placeholder={locale.admin.popup.link.nameLink}
                            {...formik.getFieldProps('nameLink')}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__4">
                        <Input
                            name="link"
                            type="text"
                            error={!!formik.errors?.link}
                            placeholder={locale.admin.popup.link.link}
                            {...formik.getFieldProps('link')}
                        />
                    </div>
                    <div className='b-form b-form-cell__4'>
                        <Select
                            name="openLink"
                            dataList={{
                                'Открыть в новом окне': locale.dropdown.selectLink._blank,
                                'Открыть в этом же окне': locale.dropdown.selectLink.default
                            }}
                            placeholder="Открыть ссылку"
                            value="Открыть ссылку"
                            setFieldValue={formik.setFieldValue}
                            {...formik.getFieldProps('openLink')}
                        />
                    </div>
                </div>
            </div>)}
        </Fragment>
    )
}

export default FormLinkHeaderAdmin;
