import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

/* eslint-disable jsx-a11y/anchor-has-content */

const HeaderAdmin = ({title, showBack, tabs}) => {
    return (
        <Fragment>
            <div className="l-header" style={{height: '64px'}}>
                <div className="b-header">
                    {showBack ? <a className="b-link-back"/> : <a href="/cases" className="b-logo"/>}
                    {title ? <span className="b-header__label">{title}</span> : <Fragment/>}
                    {tabs && (
                        <div className="b-tabs">
                            <ul className="b-tabs-wrapper g-menu-animation">
                                {tabs.map((tab, indexTab) => {
                                    if (tab.url.startsWith('/users/list')) {
                                        return (
                                            <li key={indexTab} className="b-tabs-item">
                                                <NavLink
                                                    to={tab.url}
                                                    className="b-link"
                                                    isActive={() =>
                                                        window.location.pathname.startsWith('/admin/users/')
                                                    }
                                                >
                                                    {tab.text}
                                                </NavLink>
                                            </li>
                                        );
                                    } else {
                                        return (
                                            <li key={indexTab} className="b-tabs-item">
                                                <NavLink to={tab.url} className="b-link">
                                                    {tab.text}
                                                </NavLink>
                                            </li>
                                        );
                                    }
                                })}
                            </ul>
                        </div>
                    )}
                    {/* <div className="b-menu-icon" ref={refBlock}>
                        <Dropdown options={optionsDropdown} refBlock={refBlock} />
                    </div> */}
                </div>
            </div>
        </Fragment>
    );
};

HeaderAdmin.propTypes = {
    /** Текст кнопки консультации */
    nameBtn: PropTypes.string,
    /** Флаг активности кнопки консультации */
    activeBtn: PropTypes.bool,
    /** Опции выпадающего списка */
    optionsDropdown: PropTypes.object,
    /** Флаг отображения кнопки назад */
    showBack: PropTypes.bool,
    /** Массив элементов меню */
    tabs: PropTypes.array,
};

HeaderAdmin.defaultProps = {
    nameBtn: null,
    activeBtn: null,
    optionsDropdown: null,
    showBack: null,
    tabs: null,
};

export default HeaderAdmin;
