import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Title, TableAdmin, Popup, FormCategoryAdmin, FormDelete } from '@components';

import { Api } from '@utils';
import { AdminActions } from '@redux';

const Categories = ({ GetCategories, locale, lang, categories, DeleteCategory, InsertCategory }) => {
    useEffect(() => {
        GetCategories();
    }, [GetCategories]);

    const validationSchema = Yup.object({
        name: Yup.string().required(),
        description: Yup.string(),
    });

    const [openPopup, setOpenPopup] = useState(false);
    const [entityId, setEntityId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [titlePopUp, setTitlePopUp] = useState('');

    const onRemove = (id, name) => {
        setOpenPopup(true);
        setEntityId(id);
        setAction('delete');
        setTitlePopUp(name);
    };

    const [action, setAction] = useState('delete');

    let initialValues = {
        name: '',
        description: '',
    };

    const formik = useFormik({
        isSubmitting: false,
        validateOnChange: false,
        initialValues: initialValues,
        validationSchema,
    });

    if (locale) {
        var actions = {
            update: {
                titlePopUp: locale.admin.popup.category.category,
                buttonAdd: locale.buttons.save,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormCategoryAdmin locale={locale} entityId={entityId} formik={formik} loading={loading} />,
                submitHandle: () => {
                    formik.validateForm().then((result) => {
                        if (Object.keys(result).length === 0) {
                            Api.UpdateCategory(formik.values)
                                .then(({ response, data }) => {
                                    setOpenPopup(false);
                                    formik.setValues(null);
                                    formik.resetForm();
                                })
                                .catch(({ response }) => {
                                    console.log(response);
                                });
                        }
                    });
                },
            },
            insert: {
                titlePopUp: locale.admin.popup.category.newCategory,
                buttonAdd: locale.buttons.add,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormCategoryAdmin locale={locale} entityId={entityId} formik={formik} loading={loading} />,
                submitHandle: () => {
                    formik.validateForm().then((result) => {
                        if (Object.keys(result).length === 0) {
                            InsertCategory(formik.values);
                            setOpenPopup(false);
                            formik.setValues(null);
                            formik.resetForm();
                        }
                    });
                },
            },
            delete: {
                titlePopUp: locale.admin.popup.category.delete.replace(/%category%/gi, titlePopUp),
                buttonAdd: locale.buttons.delete,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormDelete locale={locale} />,
                submitHandle: (categoryId) => {
                    DeleteCategory(categoryId);
                    setOpenPopup(false);
                },
            },
        };
    }

    return (
        <Fragment>
            {locale && locale.card && (
                <div>
                    <Title
                        title={locale.admin.menu.categories}
                        buttonText="Добавить категорию"
                        buttonClick={() => {
                            formik.resetForm();
                            formik.setValues({
                                name: '',
                                description: '',
                            });
                            formik.values = {
                                name: '',
                                description: '',
                            };

                            setOpenPopup(true);
                            setAction('insert');
                        }}
                    />
                    {categories ? (
                        categories.map((item) => (
                            <ul className="b-table" key={item._id}>
                                <TableAdmin
                                    data={{
                                        name: {
                                            type: 'title',
                                            data: item.name,
                                        },
                                        description: {
                                            type: 'text',
                                            width: 300,
                                            data: item.description,
                                        },
                                        ...(item.createdAt && {
                                            created: {
                                                type: 'date',
                                                data: item.createdAt,
                                            },
                                        }),
                                    }}
                                    id={item._id}
                                    lang={lang}
                                    onRemove={() => onRemove(item._id, item.name)}
                                    onClick={() => {
                                        setOpenPopup(true);
                                        setEntityId(item._id);
                                        setLoading(true);
                                        setAction('update');

                                        Api.GetCategory(item._id)
                                            .then(({ response, data }) => {
                                                setLoading(false);
                                                formik.setValues(data);
                                            })
                                            .catch(({ response, error }) => {
                                                console.log(error);
                                            });
                                    }}
                                />
                            </ul>
                        ))
                    ) : (
                        <></>
                    )}
                    <Popup
                        form={actions[action].tplForm}
                        formik={formik}
                        title={actions[action].titlePopUp}
                        locale={locale}
                        entityId={entityId}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        buttonAdd={actions[action].buttonAdd}
                        buttonCancel={actions[action].buttonCancel}
                        onAdd={() => actions[action].submitHandle(entityId)}
                    />
                </div>
            )}
        </Fragment>
    );
};

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        lang: store.lang,
        categories: store.categories,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetCategories: () => dispatch(AdminActions.GetCategories()),
        DeleteCategory: (categoryId) => dispatch(AdminActions.DeleteCategory(categoryId)),
        InsertCategory: (category) => dispatch(AdminActions.InsertCategory(category)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
