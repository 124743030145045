import React from 'react';
import {useFormik} from 'formik';
import {Api} from '@utils';

import {Input} from '@components';

const DropdownChangeName = ({locale, backOnClick, configuration, setDropdownState}) => {
    const validate = values => {
        const errors = {};

        if (!values.first_name) {
            errors.first_name = 'Required';
        }

        if (!values.last_name) {
            errors.last_name = 'Required';
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            first_name: configuration.user.first_name,
            last_name: configuration.user.last_name
        },
        validate,
        validateOnChange: false,
        onSubmit: values => {
            values = {...configuration.user, ...values};

            Api.ChangeUserName(values)
                .then(() => {
                    setDropdownState(false);
                    formik.setSubmitting(true);
                    configuration.user.first_name = values.first_name;
                    configuration.user.last_name = values.last_name;
                })
                .catch(() => {
                    formik.setSubmitting(false);
                })
        },
    });

    return (
        <div className="b-dropmenu-subactions show">
            <div className="b-form">
                <div className="b-dropmenu-subactions-title">
                    <i className="b-icon b-icon__back_blue" onClick={backOnClick}/>
                    {locale.dropmenu.items.changeName}
                </div>
                <form name="subactionForm" onSubmit={formik.handleSubmit}>
                    <Input
                        name="first_name"
                        type="text"
                        placeholder={locale.inputs.firstName}
                        error={formik.errors.first_name ? locale.authentication.error.signup.first_name : null}
                        {...formik.getFieldProps('first_name')}/>
                    <Input
                        name="last_name"
                        type="text"
                        placeholder={locale.inputs.lastName}
                        error={formik.errors.last_name ? locale.authentication.error.signup.last_name : null}
                        {...formik.getFieldProps('last_name')}/>
                    <div className="b-dropmenu-subactions-actions">
                        <button className="b-button b-button__cancel" onClick={backOnClick}>
                            {locale.buttons.cancel}
                        </button>
                        <button className="b-button b-button__confirm" type="submit">
                            {locale.buttons.save}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DropdownChangeName;
