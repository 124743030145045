import React from 'react';
import {
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import {
    LoginContainer,
    RegistrationContainer,
    ResetContainer,
    ConfirmContainer,
    ResetCompleteContainer,
    ResetRecoveryContainer,
    ResetRecoverySuccessfulContainer,
    ResetRecoveryOverdueContainer
} from '@containers';

// Basename router set at app.auth.js file [/authentication/account]

const AuthRoutes = (
    <Switch>
        <Route
            path="/login"
            component={LoginContainer}/>
        <Route
            path="/signup"
            component={RegistrationContainer}/>
        <Route
            path="/confirm"
            exact
            component={ConfirmContainer}/>
        <Route
            path="/fogot"
            exact
            component={ResetContainer}/>
        <Route
            path="/fogot/complete"
            exact
            component={ResetCompleteContainer}/>
        <Route
            path="/password/recovery/overdue"
            exact
            component={ResetRecoveryOverdueContainer}/>
        <Route
            path="/password/recovery/:key"
            exact
            component={ResetRecoveryContainer}/>
        <Route
            path="/password/successful"
            exact
            component={ResetRecoverySuccessfulContainer}/>
        <Redirect from='/' to='/login'/>
    </Switch>
);

export default AuthRoutes;
