import React, {Fragment} from 'react';

import {
    Spinner,
    CheckBox
} from '@components';

const FormBlocksAdmin = ({locale, loading, list}) => {
    return (
        <Fragment>
            {loading && (
                <div className="b-modal-spinner">
                    <Spinner
                        title={locale.card.loading}
                    />
                </div>
            )}
            {!loading && list && (
                <div className="b-form">
                    <ul className="b-list">
                        {
                            list.blocks.map((block) => (
                                <CheckBox
                                    item={block}
                                />
                            ))
                        }
                    </ul>
                </div>
            )}
        </Fragment>
    )
}

export default FormBlocksAdmin;
