import React, {Fragment, useState} from 'react';

const Checkbox = ({item}) => {
    const [active, setActive] = useState(item.active);

    return (
        <Fragment>
            <li className="b-list-item" onClick={() => {
                setActive(!active);
                item.active = !item.active;
            }}>
                <div className="b-list-item-link">
                    <i className={active ? 'b-block-icon b-block-icon__done' : 'b-block-icon'}/>
                    <div className="b-list-item-title">
                        <span className="b-title-inner">
                            {item.name}
                        </span>
                    </div>
                </div>
            </li>
        </Fragment>
    );
};

export default Checkbox;
