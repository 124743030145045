import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Title, TableAdmin, FormLinkHeaderAdmin, FormDelete, Popup } from '@components';
import { useFormik } from 'formik';

import {AdminActions} from '@redux';
import * as Yup from 'yup';
import { Api } from '@utils';

const Links = ({ locale, links, GetLinks, CreateLink, DeleteLink, InsertLink }) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [entityId, setEntityId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [titlePopUp, setTitlePopUp] = useState('');
    const [action, setAction] = useState('delete');

    useEffect(() => {
        GetLinks();
    }, [GetLinks]);

    const onRemove = (id, name) => {
        setOpenPopup(true);
        setEntityId(id);
        setAction('delete');
        setTitlePopUp(name);
    }

    const linkValidationSchema = Yup.object({
        nameLink: Yup.string()
            .required(),
        link: Yup.string()
            .required(),
        openLink: Yup.string()
            .required()
    });

    const initialValues = {
        nameLink: '',
        link: '',
        openLink: locale.dropdown.selectLink
    }

    const formik = useFormik({
        isSubmitting: false,
        validateOnChange: false,
        initialValues: initialValues,
        linkValidationSchema,
    })

    if (locale) {
        var actions = {
            update: {
                titlePopUp: 'Изменить ссылку',
                buttonAdd: locale.buttons.save,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormLinkHeaderAdmin locale={locale} formik={formik} loading={loading} entityId={entityId}/>,
                submitHandle: () => {
                    formik.validateForm().then(result => {
                        if (Object.keys(result).length === 0) {
                            Api.UpdateLink(formik.values)
                                .then(() => {
                                    setOpenPopup(false);
                                    formik.setValues(null)
                                    formik.resetForm()
                                })
                                .catch(({ error }) => {
                                    console.log(error)
                                })
                        }
                    })
                }
            },
            create: {
                titlePopUp: locale.admin.popup.link.newLink,
                buttonAdd: locale.buttons.add,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormLinkHeaderAdmin locale={locale} loading={loading} formik={formik} entityId={entityId} />,
                submitHandle: () => {
                    formik.validateForm().then(result => {
                        if (Object.keys(result).length === 0) {
                            InsertLink(formik.values);
                            setOpenPopup(false)
                            formik.setValues(null)
                            formik.resetForm()
                        }
                    })
                }
            },
            delete: {
                titlePopUp: locale.admin.popup.link.delete.replace(/%link%/gi, titlePopUp),
                buttonAdd: locale.buttons.delete,
                buttonCancel: locale.admin.popup.actions.cancel,
                tplForm: <FormDelete locale={locale} />,
                submitHandle: (linkId) => {
                    DeleteLink(linkId);
                    setOpenPopup(false);
                }
            }
        }
    }

    return(
        <>
            {locale && locale.card && (
                <div className="ld-form">
                    <Title
                        title={locale.admin.menu.link}
                        buttonText={locale.admin.popup.link.newLink}
                        buttonClick={() => {
                            formik.resetForm()
                            formik.setValues({
                                nameLink: '',
                                link: '',
                                openLink: locale.dropdown.selectLink
                            });
                            formik.values = {
                                nameLink: '',
                                link: '',
                                openLink: locale.dropdown.selectLink
                            }

                            setOpenPopup(true)
                            setAction('create')
                        }}
                    />
                    {links ? (
                        links.map(item => (
                            <TableAdmin
                                data={{
                                    nameLink: {
                                        type: 'title',
                                        data: item.nameLink
                                    },
                                    link: {
                                        type: 'text',
                                        data: item.link
                                    },
                                    openLink: {
                                        type: 'text',
                                        data: item.openLink
                                    }
                                }}
                                id={item._id}
                                key={item._id}
                                onRemove={() => onRemove(item._id, item.nameLink)}
                                onClick={() => {
                                    setOpenPopup(true);
                                    setEntityId(item._id)
                                    setLoading(true)
                                    setAction('update')

                                    Api.GetLink(item._id)
                                        .then(({ data }) => {
                                            setLoading(false)
                                            formik.setValues(data)
                                        })
                                        .catch(({ error }) => {
                                            console.log(error)
                                        })
                                }}
                            />
                        ))
                    ): (
                        <></>
                    )}
                    <Popup
                        form={actions[action].tplForm}
                        formik={formik}
                        openPopup={openPopup}
                        title={actions[action].titlePopUp}
                        setOpenPopup={setOpenPopup}
                        locale={locale}
                        entityId={entityId}
                        buttonAdd={actions[action].buttonAdd}
                        buttonCancel={actions[action].buttonCancel}
                        onAdd={() => actions[action].submitHandle(entityId)}
                    />
                </div>
            )}
        </>
    )
};

const mapStateToProps = store => {
    return {
        locale: store.locale,
        links: store.links
    }
};

const mapDispatchToProps = dispatch => {
    return {
        GetLinks: () => dispatch(AdminActions.GetLinks()),
        InsertLink: (link) => dispatch(AdminActions.InsertLink(link)),
        DeleteLink: (linkId) => dispatch(AdminActions.DeleteLink(linkId))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Links);
