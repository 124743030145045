import React, {Fragment} from 'react';

const Document = ({configuration, documentId}) => {
    const documentUrl = `https://${configuration['doc.one']}/iframe/document?document_id=${documentId}`;
    return (
        <Fragment>
            <div className="b-main-content b-main-content__full">
                <iframe title="title4" className="b-bot-frame" src={documentUrl}/>
            </div>
        </Fragment>
    )
}

export default Document;
