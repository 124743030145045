import {createStore, applyMiddleware, compose} from 'redux'
import thunk from "redux-thunk";
import {rootReducer} from '../reducers/service'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];

const ServiceStore = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

export default ServiceStore;
