import React, { Fragment, useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useOutsideClick } from '@utils';
import useDebounce from '../../hooks/useDebounce';

const SelectInfinity = ({ placeholder, url, value, setFieldValue, name, disabled, error }) => {
    const ref = useRef();
    const refButton = useRef();
    const [selectValue, setSelectValue] = useState(value);
    const [inputValue, setInputValue] = useState('');
    const [selectActive, setSelectActive] = useState(false);
    const [dataList, setDataList] = useState([]);
    // TODO: нужно исследовать, функция setPage уже не используется
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [requestPermission, setRequestPermission] = useState(true);
    const [searchString, setSearchString] = useState('');
    const count = useRef(1);

    const [loading, setLoading] = useState('');

    // Хук для обработки клика вне элемента
    useOutsideClick(ref, refButton, selectActive, () => {
        setSelectActive(false);
        setInputValue('');
        setLoading('');
        setSearchString('');
        count.current = 1;
    });

    function scroll(element) {
        if (
            element.scrollTop + element.clientHeight >= element.scrollHeight &&
            count.current <= pages &&
            requestPermission
        ) {
            getDataList(url, params);

            if (count.current === pages) {
                setRequestPermission(false);
            }

            count.current++;
            params.params.page = count.current;
            setPage(count.current);
        }
    }

    const getDataList = useCallback((url, params) => {
        let list = [];
        setLoading('loading');
        axios
            .get(url, params)
            .then(({ data }) => {
                if (searchString) {
                    if (count.current === 1) {
                        list = data.list;
                    } else {
                        list = [...dataList, ...data.list];
                    }
                } else {
                    if (count.current === 1) {
                        list = data.list;
                    } else {
                        list = [...dataList, ...data.list];
                    }
                }

                setPages(data.pages);
                if (count.current > data.pages) {
                    setLoading('');
                }

                if (count.current <= data.pages) {
                    //params.params.page = count.current;
                    setPage(count.current);
                    //count.current++;
                }

                setDataList(list);
            })
            .finally(() => {
                setLoading('');
            })
            .catch((err) => {
                console.log(err);
            });
    }, [dataList, searchString]);

    const debouncedSearchTerm = useDebounce(searchString, 500);

    // TODO: использовать мемоизацию, проверить бескоченоный скролл
    const params = {
        params: {
            page: 1,
            search: debouncedSearchTerm,
        },
    };

    useEffect(() => {
        if (debouncedSearchTerm) {
            setLoading(true);
            count.current = 1;
            getDataList(url, params);
        } else {
            getDataList(url, params);
        }
    }, [debouncedSearchTerm, url]);

    return (
        <Fragment>
            <div
                ref={refButton}
                onClick={() => {
                    if (disabled) {
                        return false;
                    }
                    if (selectActive === false) {
                        setSelectActive(true);
                    }
                    if (!dataList.length && !inputValue) {
                        getDataList(url, params);
                    }
                }}
                className={
                    selectValue
                        ? selectActive
                            ? `b-selectbox edit active not-empty search ${loading}`
                            : `b-selectbox edit ${loading} not-empty`
                        : selectActive
                        ? `b-selectbox edit active search ${loading}`
                        : disabled
                        ? `b-selectbox ${error}`
                        : `b-selectbox edit ng-empty ${error}`
                }
            >
                <i className="b-icon b-icon__down" />
                {(selectActive || selectValue) && <div className="b-selectbox-subtitle">{placeholder}</div>}

                <div className="b-selectbox-title">{selectValue ? selectValue : placeholder}</div>
                <i
                    className="b-icon b-icon__down js-selectbox-icon"
                    onClick={() => {
                        setSelectActive(false);
                    }}
                />
                <i className="b-icon b-icon__clear_input js-selectbox-icon-clear" />
                <input
                    type="text"
                    className="b-selectbox-search js-selectbox-search"
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        setSearchString(e.target.value);
                    }}
                    value={inputValue}
                />
                <div className="b-spinner-input" />
                {dataList[0] && (
                    <div className="b-input-list" ref={ref} onScroll={(e) => scroll(e.target)}>
                        <ul className="b-input-list-inner">
                            {dataList.length ? (
                                dataList.map((item, index) => (
                                    <li
                                        key={index}
                                        className={
                                            item._id === selectValue._id
                                                ? 'b-input-list-item active'
                                                : 'b-input-list-item'
                                        }
                                        onClick={() => {
                                            setSelectValue(`${item.first_name} ${item.last_name}`);
                                            setInputValue('');
                                            setFieldValue(name, item);
                                            setSelectActive(false);
                                        }}
                                    >
                                        {item.first_name + ' ' + item.last_name}
                                    </li>
                                ))
                            ) : (
                                <div className="b-selectbox-list-empty">No matches</div>
                            )}
                        </ul>
                    </div>
                )}
            </div>
        </Fragment>
    );
};

export default SelectInfinity;
