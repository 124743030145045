import React from 'react';
import { Link } from 'react-router-dom';

const Formatter = {
    formatLink: (input) => {
        // Форматирует текст с встроенными ссылками в виде [link]link|textLink[/link]
        let links = input.match(/\[link\](.+?)\[\/link\]/gi);
        let outputText;
        let outputLink;

        if (links && links.length) {
            links.forEach((link) => {
                let linkArray = link
                    .replace(/\[link\]/, '')
                    .replace(/\[\/link\]/, '')
                    .split('|');
                outputLink = (
                    <Link className="b-link" to={linkArray[0]}>
                        {linkArray[1]}
                    </Link>
                );

                outputText = input.replace(link, '');
            });
        }

        return (
            <div>
                {outputText}
                {outputLink}
            </div>
        );
    },
    formatDate: (date, lang) => {
        const dateRegExp = new RegExp('\\d{4}-\\d{2}-\\d{2}');
        const checkDate = dateRegExp.test(date);
        if (checkDate) {
            return new Intl.DateTimeFormat(lang, {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
            }).format(+new Date(date));
        } else {
            return '';
        }
    },
    formatPrice: (item, currency) => {
        var result = '';

        if (currency === 'usd') {
            result += '$';

            result += parseFloat(item.price)
                .toFixed(2)
                .replace(/./g, function (c, i, a) {
                    return i && c !== '.' && (a.length - i) % 3 === 0 ? ',' + c : c === ',' ? ',' : c;
                });
        } else if (currency === 'eur') {
            result += parseFloat(item.price)
                .toFixed(2)
                .replace(/./g, function (c, i, a) {
                    return i && c !== '.' && (a.length - i) % 3 === 0 ? '.' + c : c === '.' ? ',' : c;
                });

            result += '€';
        } else {
            result += parseFloat(item.price)
                .toFixed(2)
                .replace(/./g, function (c, i, a) {
                    return i && c !== '.' && (a.length - i) % 3 === 0 ? ' ' + c : c === '.' ? '.' : c;
                });
            result += '₽';
        }

        return result;
    },
};

export default Formatter;
