import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Input = ({placeholder, type, name, value, onChange, style, error, disabled = false}) => {
    const [isFocus, setIsFocus] = useState(false);
    const [inputFocus, setInputFocus] = useState(false);

    return (
        <div
            className={typeof error == 'string' && error ? 'b-input error' : (error && !value ? 'b-input error' : 'b-input')}>
            <label className="b-input-container">
                <input
                    autoComplete="off"
                    name={name}
                    type={type}
                    style={style}
                    className={!value ? 'b-input-field ng-empty' : 'b-input-field'}
                    placeholder={placeholder}
                    value={value}
                    disabled={disabled}
                    onChange={onChange}
                    onKeyDown={(e) => setInputFocus(false)}
                    onFocus={() => {
                        setIsFocus(true);
                        setInputFocus(true)
                    }}
                    onBlur={() => setInputFocus(false)}/>
                {(value || isFocus) && (
                    <div className="b-input-title">{placeholder}</div>
                )}
                {error && typeof error == 'string' && inputFocus && (
                    <div className="b-input-error">
                        <div className="b-input-error-text">{error}</div>
                    </div>
                )}
            </label>
        </div>
    );
};

Input.propTypes = {
    /** Текст placeholder */
    placeholder: PropTypes.string,
    /** Атрибут type */
    type: PropTypes.string,
    /** Атрибут name */
    name: PropTypes.string,
    /** Атрибут value */
    value: PropTypes.string,
    /** Функция, которая вызывается при изменении значения поля */
    onChange: PropTypes.func,
    /** ОФункция, которая вызывается при потере фокуса */
    onBlur: PropTypes.func,
    /** Кастомные стили поля */
    style: PropTypes.object,
    /** Текст ошибки, если != null, то будет выводить ошибку */
    error: PropTypes.string,
};

Input.defaultProps = {
    placeholder: null,
    type: null,
    name: null,
    value: null,
    onChange: null,
    onBlur: null,
    style: null,
    error: null,
};

export default Input;
