import React, {useRef} from 'react';
import PropTypes from 'prop-types';

import {useOutsideClick} from '@utils';

const Dropdown = ({options, setSubactionsShow, refBlock, dropdownState, setDropdownState}) => {
    // const [dropdownState, setDropdownState] = useState(false);
    const ref = useRef();
    const refButton = useRef();
    const getMenuList = (e) => {
        // e.stopPropagation();
        if (refBlock && refBlock.current) {
            if (refBlock.current.classList.contains('open') || refBlock.current.classList.contains('active')) {
                refBlock.current.classList.remove('open');
                refBlock.current.classList.remove('active');
                setSubactionsShow(false);
            } else {
                refBlock.current.classList.add('open');
                refBlock.current.classList.add('active');
            }
        }
        setDropdownState(!dropdownState);
    }

    // Хук для обработки клика вне элемента
    useOutsideClick(ref, refButton, dropdownState, () => {
        getMenuList();
    });

    // Добавляем стили из options
    const styles = {
        right: options.right || options.right === 0 ? options.right : 'auto',
        top: options.top || options.top === 0 ? options.top : 'calc(100% - 6px)',
        left: options.left || options.left === 0 ? options.left : 'auto',
        width: options.width || options.width === 0 ? options.width : (options.subactionShow ? options.subactionWidth : '192px')
    }
    const iconClass = options.iconClass || 'b-icon b-icon__dots';
    const itemHandler = (e, item) => {
        // e.stopPropagation();
        item.action(item);
    }

    return (
        <div onClick={(e) => e.stopPropagation()}>
            {options && options.isIcon && (
                <i
                    className={dropdownState ? iconClass + ' active' : iconClass}
                    onClick={getMenuList}
                    ref={refButton}
                    style={{opacity: "1"}}
                />
            )}
            {options && !options.isIcon && (
                <span onClick={getMenuList} ref={refButton}>{options.title}</span>
            )}
            <div className={dropdownState ? 'b-dropmenu animation' : 'b-dropmenu'} style={styles}>
                {!options.subactionShow ? (
                    <div className="b-dropmenu-container" ref={ref}>
                        <ul className="b-dropmenu-list">
                            {options.items.map((item, index) => {
                                return (
                                    <div className="b-dropmenu-list-item">
                                        {!item.disabled && !item.hidden && (
                                            <div className="b-dropmenu-list-item-link"
                                                 onClick={(e) => itemHandler(e, item)}>
                                            <span>
                                                {item.title}
                                            </span>
                                                {item.isIcon && (
                                                    <i className="b-icon icon-arrow-right"/>
                                                )}
                                            </div>
                                        )}
                                        {item.disabled && (
                                            <div
                                                className="b-dropmenu-list-item-link b-dropmenu-list-item-link__disabled">
                                                {item.title}
                                            </div>
                                        )}
                                    </div>
                                )
                            })}
                        </ul>
                    </div>
                ) : (
                    <div ref={ref}>
                        {options.subactionTemplate}
                    </div>
                )}
            </div>
        </div>
    )
}

Dropdown.propTypes = {
    /** Флаг видимости шаблона поддействия */
    setSubactionsShow: PropTypes.bool,
    /** Ссылка на родительский элемент  */
    refBlock: PropTypes.element,
    /**
     * Опции выпадающего списка (описание смотри выше)
     */
    options: PropTypes.shape({
        title: PropTypes.string,
        subactionTemplate: PropTypes.element,
        subactionShow: PropTypes.bool,
        subactionWidth: PropTypes.string,
        isIcon: PropTypes.bool,
        items: PropTypes.array,
        right: PropTypes.string,
        top: PropTypes.string,
        left: PropTypes.string,
        width: PropTypes.string,
    })
};

Dropdown.defaultProps = {
    options: null,
    setSubactionsShow: false,
    refBlock: null
};

export default Dropdown;
