import React, {Fragment} from 'react';
import {useHistory} from 'react-router-dom';

const Table = ({id, name, number, created, status, typeImage}) => {
    const history = useHistory();
    const goTo = () => {
        history.push(`/cases/${id}/service`)
    }

    const getClass = (type) => {
        let extraClass = '';

        if (type === 'doc.one') {
            extraClass = ' icon__doc';
        }

        if (type === 'form.one.bot') {
            extraClass = ' icon__form';
        }

        return 'b-archive-link' + extraClass;
    };

    return (
        <Fragment>
            <li className="b-archive-item">
                <a onClick={() => goTo()} className={getClass(typeImage)}>
                    <h3 className="b-archive-case-title">
                        {name}
                    </h3>
                    {!status
                        ? ''
                        : <div className="b-block__status">
                            <p>{status}</p>
                          </div>
                    }
                    <p className="b-archive-number">
                        {number}
                    </p>
                    <p className="b-archive-date">
                        {created}
                    </p>
                </a>
            </li>
        </Fragment>
    )
}

export default Table;
