import React, {useState} from 'react';
import {useFormik} from 'formik';
import {Api} from '@utils';

import {Input} from '@components';

const DropdownChangePassword = ({locale, backOnClick, setDropdownState}) => {
    const [errorType, setErrorType] = useState('');

    const validate = values => {
        const errors = {};

        setErrorType('');
        if (!values.currentPassword) {
            errors.currentPassword = 'Required';
        } else if (values.currentPassword.length < 6) {
            errors.currentPassword = 'Must be 6 characters or more';
        }

        if (!values.newPassword) {
            errors.newPassword = 'Required';
        } else if (values.newPassword.length < 6) {
            errors.newPassword = 'Must be 6 characters or more';
        }

        if (!values.reenterNewPassword) {
            errors.reenterNewPassword = 'Required';
        } else if (values.reenterNewPassword.length < 6) {
            errors.reenterNewPassword = 'Must be 6 characters or more';
        } else if (values.newPassword !== values.reenterNewPassword && (values.newPassword.length >= values.reenterNewPassword.length || values.reenterNewPassword.length >= values.newPassword.length)) {
            errors.newPassword = locale.authentication.error.recovery.newPassword;
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            reenterNewPassword: '',
        },
        validate,
        validateOnChange: false,
        onSubmit: values => {
            Api.ChangeUserPassword(values)
                .then(() => {
                    setErrorType('');
                    setDropdownState(false);
                    formik.setSubmitting(true);
                })
                .catch(({response}) => {
                    formik.setSubmitting(false);
                    setErrorType(response.data.error.input || response.data.error);

                    if (response.data.error.input) {
                        setErrorType(response.data.error.input);
                    } else if (response.data.error) {
                        setErrorType(response.data.error);
                    } else {
                        setErrorType('');
                    }
                })
        }
    });

    return (
        <div className="b-dropmenu-subactions show">
            <div className="b-form">
                <div className="b-dropmenu-subactions-title">
                    <i className="b-icon b-icon__back_blue" onClick={backOnClick}/>
                    {locale.dropmenu.items.changePassword}
                </div>
                <form name="subactionForm" onSubmit={formik.handleSubmit}>
                    <Input
                        placeholder={locale.inputs.currentPassword}
                        error={formik.touched.currentPassword && formik.errors.currentPassword ? (formik.errors.currentPassword) : (errorType === 'currentPassword' ? locale.authentication.error.recovery.currentPassword : '')}
                        name="currentPassword"
                        type="password"
                        {...formik.getFieldProps('currentPassword')}/>
                    <Input
                        placeholder={locale.inputs.newPassword}
                        error={formik.touched.newPassword && formik.errors.newPassword ? (formik.errors.newPassword) : null}
                        name="newPassword"
                        type="password"
                        {...formik.getFieldProps('newPassword')}/>
                    <Input
                        placeholder={locale.inputs.reEnterNewPassword}
                        error={formik.touched.reenterNewPassword && formik.errors.reenterNewPassword ? (formik.errors.reenterNewPassword) : null}
                        name="reenterNewPassword"
                        type="password"
                        {...formik.getFieldProps('reenterNewPassword')}/>
                    <div className="b-dropmenu-subactions-actions">
                        <button className="b-button b-button__cancel" onClick={backOnClick}>
                            {locale.buttons.cancel}
                        </button>
                        <button className="b-button b-button__confirm" type="submit">
                            {locale.buttons.save}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default DropdownChangePassword;
