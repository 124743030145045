import React, {Fragment, useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import {useFormik} from 'formik';
import * as Yup from 'yup';

import {Api, Formatter} from '@utils';
import {Input} from '@components';

const Login = ({locale, loginFB}) => {
    const [errorType, setErrorType] = useState('');

    const loginFBHandler = () => {
        window.location.href = '/authentication/account/signup/facebook';
    };

    const validationSchema = Yup.object({
        email: Yup.string().email().min(5).required(),
        password: Yup.string().min(3).required(),
    });

    const formik = useFormik({
        isSubmitting: false,
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema,
        onSubmit: values => {
            formik.isSubmitting = true;
            Api.Login(values)
                .then(({data}) => {
                    setErrorType('');
                    const {first_login} = data;
                    const redirectionPath = first_login ? '/cases/consultation/' : '/cases';
                    window.location.assign(redirectionPath);
                })
                .catch(({response}) => {
                    formik.setSubmitting(false);
                    setErrorType(response.data.error);
                })

        },
    });

    useEffect(() => {
        document.title = locale ? locale.authentication.signIn.title : 'Legal Desk';
    }, [locale]);

    return (
        <Fragment>
            {locale && locale.authentication && (
                <Fragment>
                    <h3 className="b-form-title">
                        {locale.authentication.signIn.title}
                    </h3>
                    <div className="b-form-text">
                        {Formatter.formatLink(locale.authentication.signIn.description)}
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <Input
                            placeholder={locale.inputs.email}
                            type="text"
                            name="email"
                            error={(errorType && errorType === 'email') || (errorType && errorType === 'notConfirmed') || (errorType && errorType === 'archive') ? locale.authentication.error.login[errorType] : null}
                            {...formik.getFieldProps('email')} />
                        <Input
                            placeholder={locale.inputs.password}
                            type="password"
                            name="password"
                            error={errorType === 'password' ? locale.authentication.error.login.password : null}
                            {...formik.getFieldProps('password')} />
                        <div className="b-actions">
                            <button className="b-button b-button__uppercase" type="submit"
                                    disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}>
                                <div
                                    className="b-button-text">{formik.isSubmitting ? locale.authentication.controls.signInLoading : locale.authentication.controls.signIn}</div>
                            </button>
                            {loginFB && (
                                <button onClick={() => loginFBHandler()} className="b-button b-button__blue">
                                    <i className="b-icon b-icon__facebook"/>
                                    <span className="g-vmiddle">{locale.authentication.controls.signInFacebook}</span>
                                </button>
                            )}
                        </div>
                    </form>

                    <Link className="b-link b-link__action" to="/fogot">
                        {locale.authentication.signIn.action}
                    </Link>
                </Fragment>
            )}
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        loginFB: store.loginFB
    }
}

export default connect(mapStateToProps)(Login);
