import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ServiceActions } from '@redux';
import CaseFilter from './CaseFilter';
import {
    productsSelector,
    productStatusesSelector,
    productTypesSelector,
    productCategoriesSelector,
    caseFilterDictionariesReadySelector,
} from '../../redux/selectors/service/dictionary';

/**
 * Функция перевода объекта с id в массив идентификаторов.
 * Используется в .reduce массива с объектами.
 *
 * @param result
 * @param current
 * @returns {*[]|*}
 */
function transformFilterObjectToArrayId(result, current) {
    if (current.id !== 0 && current.checked) {
        return [...result, current.id];
    } else {
        return result;
    }
}

/**
 * Формирует список продуктов, которые выбраны в фильтры, для передачи в вызов API.
 *
 * @param product Фильтр продуктов
 * @param products Справочник продуктов
 * @returns {any[]}
 */
function getStatusDictionaryByProduct(product, products) {
    const dictionaryMap = new Map();
    product.forEach((productFilterItem) => {
        if (productFilterItem.id !== 0 && productFilterItem.checked) {
            const checkedProductEntity = products.find((productEntity) => productFilterItem.id === productEntity.id);
            if (checkedProductEntity?.statusField?.dictionary) {
                dictionaryMap.set(checkedProductEntity.statusField?.dictionary, true);
            }
        }
    });
    return Array.from(dictionaryMap.keys());
}

const CaseFilterDictionaryLoader = ({ filters, dictionary, actions }) => {
    useEffect(() => {
        actions.GetCaseFilterDictionaries(filters);
    }, []);

    // При изменения фильтра продуктов нужно подгрузить справочник статусов
    useEffect(() => {
        actions.GetProductStatuses(getStatusDictionaryByProduct(filters.product, dictionary.products));
    }, [filters.product, dictionary.products]);

    // При изменении фильтров категории и типа нужно обновить список продуктов
    useEffect(() => {
        const categoryId = filters.category.reduce(transformFilterObjectToArrayId, []);
        const typeId = filters.type.reduce(transformFilterObjectToArrayId, []);

        actions.GetProductsByCategoryAndType(categoryId, typeId);
    }, [filters.category, filters.type]);

    return dictionary.ready && <CaseFilter filters={filters} />;
};

export default connect(
    (store) => ({
        dictionary: {
            products: productsSelector(store),
            productTypes: productTypesSelector(store),
            productStatuses: productStatusesSelector(store),
            categories: productCategoriesSelector(store),
            ready: caseFilterDictionariesReadySelector(store),
        },
    }),
    (dispatch) => ({
        actions: {
            GetProductStatuses: (productId) => dispatch(ServiceActions.GetProductStatusList(productId)),
            GetCaseFilterDictionaries: (filters) => dispatch(ServiceActions.GetCaseFilterDictionaries(filters)),
            GetProductsByCategoryAndType: (category, type) =>
                dispatch(ServiceActions.GetProductsByCategoryAndType(category, type)),
        },
    })
)(CaseFilterDictionaryLoader);
