import React, { Fragment, useRef, useState } from 'react';
import { Formatter } from '@utils';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './Constants';
import { Dropdown, DropdownConfirm } from '@components';

const TableAdminProducts = ({
    position,
    index,
    product,
    data,
    id,
    onRemove,
    onClick,
    category,
    lang,
    locale,
    moveCard,
    updatePosition,
}) => {
    const ref = useRef(null);
    const refCard = useRef();
    const [subactionShow, setSubactionsShow] = useState(false);
    const [dropdownState, setDropdownState] = useState(false);
    const [subactionTemplate, setsubactionTemplate] = useState(null);

    const [, drop] = useDrop({
        accept: ItemTypes.CARD,
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        },
        drop(item, monitor) {
            updatePosition(item.index);
        },
    });

    const [{ isDragging }, drag] = useDrag({
        item: { type: ItemTypes.CARD, id, index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    drag(drop(ref));
    let options = {};
    if (locale) {
        options = {
            title: 'Actions',
            isIcon: true,
            right: 12,
            subactionShow,
            subactionWidth: '320px',
            subactionTemplate,
            items: [
                {
                    title: locale.dropmenu.items.delete,
                    isIcon: false,
                    subactionTemplate: (
                        <DropdownConfirm
                            title={locale.dropmenu.items.delete}
                            cancelText={locale.buttons.cancel}
                            saveText={locale.buttons.delete}
                            message={locale.dropmenu.items.delete + '?'}
                            backOnClick={() => setSubactionsShow(false)}
                            saveOnClick={() => {
                                onRemove(product._id);
                                setDropdownState(false);
                            }}
                        />
                    ),
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    },
                },
            ],
        };
    }

    const generateColumn = (col) => {
        let style = {};
        if (col.width) {
            style.width = col.width ? col.width + 'px' : 'auto';
        }

        switch (col.type) {
            case 'title':
                return (
                    <h3 className="b-table-title" style={style} title={col.data}>
                        {col.data}
                    </h3>
                );
            case 'text':
                return (
                    <p className="b-table-text" style={style} title={col.data}>
                        {col.data}
                    </p>
                );
            case 'date':
                return (
                    <h3 className="b-table-date" style={style} title={col.data}>
                        {Formatter.formatDate(col.data, lang)}
                    </h3>
                );

            default:
                break;
        }
    };

    const rowProps = {
        ...(onClick && { onClick: () => onClick(id) }),
    };

    return (
        <Fragment>
            {product ? (
                <li
                    ref={ref}
                    key={id}
                    className={isDragging ? 'b-products-item b-products-item_dragging' : 'b-products-item'}
                >
                    <a
                        onClick={() => onClick(id)}
                        className={
                            product.metadata.serviceName === 'form.one.bot'
                                ? 'b-products-link icon__form'
                                : product.metadata.serviceName === 'doc.one'
                                ? 'b-products-link icon__doc'
                                : 'b-products-link'
                        }
                    >
                        <h3 className="b-products-case-title" title={product.name}>
                            {product.name}
                        </h3>
                        <p className="b-products-category" title={category}>
                            {category}
                        </p>
                        <p className="b-products-date">
                            {product.createdAt && Formatter.formatDate(product.createdAt, lang)}
                        </p>
                    </a>
                    {onRemove ? (
                        <>
                            <div className="b-products-item-delete">
                                <Dropdown
                                    options={options}
                                    setSubactionsShow={setSubactionsShow}
                                    refBlock={refCard}
                                    dropdownState={dropdownState}
                                    setDropdownState={setDropdownState}
                                />
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                </li>
            ) : (
                <li key={id} className="b-table-item">
                    <a className="b-table-link" {...rowProps}>
                        {Object.keys(data).map((item, index) => generateColumn(data[item]))}
                    </a>
                    {onRemove ? <div className="b-list-item-remove" onClick={() => onRemove(id)} /> : <></>}
                </li>
            )}
        </Fragment>
    );
};

export default TableAdminProducts;
