import React, {Fragment} from 'react';

const TableTasks = ({item, index, windowMin, onClick}) => {
    return (
        <Fragment>
            <li className="b-list-item">
                <div className="b-list-item-link">
                    <i
                        className={item.sysName === 'Closed' ? 'b-task-icon b-task-icon__done' : 'b-task-icon'}
                        onClick={() => onClick(item, index)}
                    />
                    <div className="b-list-item-title">
                        <a
                            className={item.sysName === 'Closed' ? 'b-title-inner g-line-through' : 'b-title-inner'}
                            onClick={() => onClick(item, index)}
                        >
                            {item.name}
                        </a>
                        {item.description && (
                            <div
                                className={
                                    item.sysName === 'Closed'
                                        ? 'b-list-item-description g-line-through'
                                        : 'b-list-item-description'
                                }
                            >
                                <p
                                    className="b-task-description"
                                    dangerouslySetInnerHTML={{
                                        __html: item.description.replace(/(?:\r\n|\r|\n)/g, '<br>'),
                                    }}
                                />
                            </div>
                        )}
                        {item.files && item.files.length > 0 && !windowMin && (
                            <div className="b-list-item-files">
                                {item.files.map((file) => (
                                    <div className="b-list-item-files-item" key={file._id}>
                                        <a target="_blank" className="b-list-item-files-item-link">
                                            <i className="b-icon b-icon__file"/>
                                            {file.name}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                        {item.files && item.files.length > 0 && windowMin && (
                            <div className="b-list-item-files">
                                {item.files.map((file) => (
                                    <div className="b-list-item-files-item" key={file._id}>
                                        <a target="_blank" className="b-list-item-files-item-link">
                                            <i className="b-icon b-icon__file"/>
                                            {file.name}
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </li>
        </Fragment>
    );
};

export default TableTasks;
