import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormLanguageAdmin, Spinner} from '@components';

import {AdminActions, languageSelector, localeSelector, settingsSelector} from '@redux';

const Language = () => {
    const dispatch = useDispatch();
    const [visibilitySpinner, setVisibilitySpinner] = useState(false);

    useEffect(() => {
        dispatch(AdminActions.GetLanguage());
        dispatch(AdminActions.GetAdminSettings());
    }, [dispatch]);

    const locale = useSelector(localeSelector);
    const language = useSelector(languageSelector);
    const settings = useSelector(settingsSelector);

    const onSubmit = (lang, values) => {
        setVisibilitySpinner(true);
        dispatch(AdminActions.UpdateLanguage(lang, values.locale))
            .finally(() => {
                setTimeout(() => {
                    setVisibilitySpinner(false);
                }, 500);
            });
    };

    return (
        <Fragment>
            {settings && language && locale && locale.card && (
                <>
                    <FormLanguageAdmin locale={locale} language={language} settings={settings} onSubmit={onSubmit}/>
                    {visibilitySpinner && (
                        <Spinner title={locale.card.loading}/>
                    )}
                </>
            )}
        </Fragment>
    );
};

export default Language;
