import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useFormik} from 'formik';
import {Title, TableAdmin, Dialog, Input} from '@components';

import {AdminActions} from '@redux';
import * as Yup from 'yup';

const Groups = ({GetGroups, locale, lang, groups, CreateGroup, DeleteGroup}) => {
    useEffect(() => {
        GetGroups();
    }, [GetGroups]);

    const [isAddGroupDialogOpen, setIsAddGroupDialogOpen] = useState(false);
    const [deleteGroup, setDeleteGroup] = useState(null);

    const openAddGroupDialog = () => setIsAddGroupDialogOpen(true);
    const closeAddGroupDialog = () => setIsAddGroupDialogOpen(false);

    const onRemoveGroup = (id) => {
        const group = groups.find((group) => group._id === id);
        setDeleteGroup(group);
    };

    const closeDeleteGroupDialog = () => {
        setDeleteGroup(null);
    };

    const deleteGroupSubmit = () => {
        DeleteGroup(deleteGroup._id);
        setDeleteGroup(null);
    };

    const history = useHistory();
    const routeToGroup = (id) => history.push(`/groups/${id}/card`);

    const groupValidationSchema = Yup.object({
        name: Yup.string()
            .max(56)
            .required(),
        description: Yup.string().max(512),
    });

    const addGroupFormik = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: groupValidationSchema,
        onSubmit: (values) => {
            CreateGroup(values);
            closeAddGroupDialog();
        },
    });

    return (
        <Fragment>
            {locale && locale.card && (
                <div>
                    <Title
                        title={locale.admin.menu.group}
                        buttonText={locale.admin.popup.group.newGroup}
                        buttonClick={openAddGroupDialog}
                    />
                    {groups &&
                    groups.map((item) => (
                        <TableAdmin
                            data={{
                                name: {
                                    type: 'title',
                                    data: item.name,
                                },
                                description: {
                                    type: 'text',
                                    width: 200,
                                    data: item.description,
                                },
                                count: {
                                    type: 'text',
                                    width: 100,
                                    data: item.count,
                                },
                                ...(item.createdAt && {
                                    created: {
                                        type: 'date',
                                        data: item.createdAt,
                                    },
                                }),
                            }}
                            id={item._id}
                            lang={lang}
                            onRemove={onRemoveGroup}
                            onClick={routeToGroup}
                        />
                    ))}
                    <Dialog
                        locale={locale}
                        title={locale.admin.popup.group?.newGroup}
                        isOpen={isAddGroupDialogOpen}
                        onSubmit={addGroupFormik.handleSubmit}
                        onClose={closeAddGroupDialog}
                    >
                        <div className="b-form">
                            <div className="b-form-row">
                                <div className="b-form-cell b-form-cell__4">
                                    <Input
                                        name="name"
                                        type="text"
                                        value={addGroupFormik.values.name}
                                        placeholder={locale.admin.popup.group?.name}
                                        error={addGroupFormik.errors.name}
                                        onChange={addGroupFormik.handleChange}
                                    />
                                </div>
                                <div className="b-form-cell b-form-cell__8">
                                    <Input
                                        name="description"
                                        type="text"
                                        value={addGroupFormik.values.description}
                                        placeholder={locale.admin.popup.group?.description}
                                        error={addGroupFormik.errors.description}
                                        onChange={addGroupFormik.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </Dialog>
                    {deleteGroup && (
                        <Dialog
                            onClose={closeDeleteGroupDialog}
                            locale={locale}
                            title={locale.admin.popup.group.delete.replace(/%group%/gi, deleteGroup?.name)}
                            isOpen={true}
                            onSubmit={deleteGroupSubmit}
                            submitTitle={locale.buttons.delete}
                        >
                            <div className="ld-popup-message">{locale.admin.popup.group?.deleteMessage}</div>
                        </Dialog>
                    )}
                </div>
            )}
        </Fragment>
    );
};

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        lang: store.lang,
        groups: store.groups,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetGroups: () => dispatch(AdminActions.GetGroups()),
        CreateGroup: (group) => dispatch(AdminActions.CreateGroup(group)),
        DeleteGroup: (groupId) => dispatch(AdminActions.DeleteGroup(groupId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Groups);
