import { Api } from '@utils';

const ServiceActions = {
    SetLink: (links) => ({
        type: 'SET_LINKS',
        payload: links,
    }),
    GetLinks: () => (dispatch) => {
        Api.GetLinks().then(({ data }) => {
            dispatch(ServiceActions.SetLink(data));
        });
    },
    SetSettings: (settings) => ({
        type: 'SET_SETTINGS',
        payload: settings,
    }),
    GetSettings: () => (dispatch) => {
        Api.GetSettings().then(({ data }) => {
            dispatch(ServiceActions.SetSettings(data));
        });
    },
    SetCases: (cases) => ({
        type: 'SET_CASES',
        payload: cases,
    }),
    SetCasesReady: (isReady) => ({
        type: 'SET_CASES_READY',
        payload: isReady,
    }),
    GetCases: (filter, filterObject) => (dispatch) => {
        dispatch(ServiceActions.SetCasesReady(false));
        Api.GetCases(filter, filterObject).then(({ data }) => {
            localStorage.setItem('case_filter', JSON.stringify(filterObject));
            dispatch(ServiceActions.SetCases(data));
            dispatch(ServiceActions.SetCaseFilter(filterObject));
        });
    },
    SetCaseItem: (caseItem) => ({
        type: 'SET_CASE_ITEM',
        payload: caseItem,
    }),
    GetCaseItem: (id) => (dispatch) => {
        Api.GetCaseItem(id)
            .then(({ data }) => {
                dispatch(ServiceActions.SetCaseItem(data));
            })
            .catch((err) => {
                if (err) {
                    console.log(err);
                    window.location.href = '/cases';
                }
            });
    },
    SetCaseServiceData: (caseItem) => ({
        type: 'SET_CASE_SERVICEDATA',
        payload: caseItem,
    }),
    GetCaseServiceData: (id, documentFolderId) => (dispatch) => {
        Api.GetCaseServiceData(id, documentFolderId).then(({ data }) => {
            dispatch(ServiceActions.SetCaseServiceData(data));
        });
    },
    UploadFileToFolder: (options, caseId, documentFolderId) => (dispatch) => {
        Api.UploadFileToFolder(options).then(() => {
            dispatch(ServiceActions.GetCaseServiceData(caseId, documentFolderId));
        });
    },
    DeleteFile: (fileId, caseId, documentFolderId) => (dispatch) => {
        Api.DeleteFile(fileId).then(() => {
            dispatch(ServiceActions.GetCaseServiceData(caseId, documentFolderId));
        });
    },
    SetCaseSummary: (caseItem) => ({
        type: 'SET_CASE_SUMMARY',
        payload: caseItem,
    }),
    GetCaseSummary: (id) => (dispatch) => {
        Api.GetCaseSummary(id).then(({ data }) => {
            dispatch(ServiceActions.SetCaseSummary(data));
        });
    },
    SetTaskStatus: (taskItem) => ({
        type: 'SET_TASK_STATUS',
        payload: taskItem,
    }),
    ChangeTaskStatus: (taskId) => (dispatch) => {
        Api.ChangeTaskStatus(taskId).then(({ data }) => {
            dispatch(ServiceActions.SetTaskStatus(data));
        });
    },
    ClearSelectedData: () => ({
        type: 'CLEAR_SELECTED_DATA',
    }),
    SetArchiveCases: (archiveCases) => ({
        type: 'SET_ARCHIVE_CASES',
        payload: archiveCases,
    }),
    GetArchiveCases: () => (dispatch) => {
        Api.GetArchiveCases().then(({ data }) => {
            dispatch(ServiceActions.SetArchiveCases(data));
        });
    },
    SetCasesCategories: (casesCategories) => ({
        type: 'SET_CASES_CATEGORIES',
        payload: casesCategories,
    }),
    SetCasesCategoriesReady: (isReady) => ({
        type: 'SET_CASES_CATEGORIES_READY',
        payload: isReady,
    }),
    GetCasesCategories: () => (dispatch) => {
        dispatch(ServiceActions.SetCasesCategoriesReady(false));
        Api.GetCasesCategories().then(({ data }) => {
            dispatch(ServiceActions.SetCasesCategories(data));
        });
    },
    SetProductsCategories: (productsCategories) => ({
        type: 'SET_PRODUCTS_CATEGORIES',
        payload: productsCategories,
    }),
    SetProductsCategoriesReady: (isReady) => ({
        type: 'SET_PRODUCTS_CATEGORIES_READY',
        payload: isReady,
    }),
    GetProductsCategories: () => (dispatch) => {
        dispatch(ServiceActions.SetProductsCategoriesReady(false));
        Api.GetProductsCategories().then(({ data }) => {
            dispatch(ServiceActions.SetProductsCategories(data));
        });
    },
    FilterCases: (idCategory) => ({
        type: 'SET_CATEGORY_ID',
        payload: idCategory,
    }),
    SetProducts: (products) => ({
        type: 'SET_PRODUCTS',
        payload: products,
    }),
    SetProductsReady: (isReady) => ({
        type: 'SET_PRODUCTS_READY',
        payload: isReady,
    }),
    GetProducts: () => (dispatch) => {
        dispatch(ServiceActions.SetProductsReady(false));
        Api.GetProducts().then(({ data }) => {
            dispatch(ServiceActions.SetProducts(data));
        });
    },
    SetCreateCase: (createdCase) => ({
        type: 'SET_CREATE_CASE',
        payload: createdCase,
    }),
    CreateCase: (productId) => {
        return (dispatch) => {
            return Api.CreateCase(productId).then(({ data }) => {
                dispatch(ServiceActions.SetCreateCase(data));
            });
        };
    },
    SetProfileSummary: (cases) => ({
        type: 'SET_PROFILE_SUMMARY',
        payload: cases,
    }),
    GetProfileSummary: () => (dispatch) => {
        Api.GetProfileSummary().then(({ data }) => {
            dispatch(ServiceActions.SetProfileSummary(data));
        });
    },
    SetDeleteCase: (caseId) => ({
        type: 'SET_DELETE_CASE',
        payload: caseId,
    }),
    DeleteCase: (caseId) => (dispatch) => {
        Api.DeleteCase(caseId).then(() => {
            dispatch(ServiceActions.SetDeleteCase(caseId));
        });
    },
    SetArchiveCase: (caseId) => ({
        type: 'SET_ARCHIVE_CASE',
        payload: caseId,
    }),
    ToArchiveCase: (caseId) => (dispatch) => {
        Api.ToArchiveCase(caseId).then(() => {
            dispatch(ServiceActions.SetArchiveCase(caseId));
        });
    },
    SetVisibilityBot: (visibilityBot) => ({
        type: 'SET_VISIBILITY_BOT',
        payload: visibilityBot,
    }),
    ChangeVisibilityBot: (visibilityBot) => (dispatch) => {
        dispatch(ServiceActions.SetVisibilityBot(visibilityBot));
    },
    SetTasks: (tasks) => ({
        type: 'SET_TASKS',
        payload: tasks,
    }),
    SetFolders: (folders) => ({
        type: 'SET_FOLDERS',
        payload: folders,
    }),
    SetFiles: (files) => ({
        type: 'SET_FILES',
        payload: files,
    }),
    SetUser: (user) => ({
        type: 'SET_USER',
        payload: user,
    }),
    GetProductsByCategoryAndType: (category, type) => async (dispatch) => {
        const response = await Api.GetProductListByCategoryAndType(category, type);
        dispatch(ServiceActions.SetProductsDictionary(response.data.list));
    },
    SetProductsDictionary: (products) => ({
        type: 'SET_PRODUCTS_DICTIONARY',
        payload: products,
    }),
    GetProductTypes: () => async (dispatch) => {
        const response = await Api.GetProductTypeList();
        dispatch(ServiceActions.SetProductTypesDictionary(response.data.list));
    },
    SetProductTypesDictionary: (productTypes) => ({
        type: 'SET_PRODUCT_TYPES_DICTIONARY',
        payload: productTypes,
    }),
    GetProductStatusList: (productId) => async (dispatch) => {
        const response = await Api.GetProductStatusList(productId);
        dispatch(ServiceActions.SetProductStatusesDictionary(response.data.list));
    },
    SetProductStatusesDictionary: (productStatuses) => ({
        type: 'SET_PRODUCT_STATUSES_DICTIONARY',
        payload: productStatuses,
    }),
    SetProductsCategoriesDictionary: (productCategories) => ({
        type: 'SET_PRODUCT_CATEGORIES_DICTIONARY',
        payload: productCategories,
    }),
    SetCaseFilter: (caseFilter) => ({
        type: 'SET_CASE_FILTER',
        payload: caseFilter,
    }),
    LoadCaseFilter: () => (dispatch) => {
        const caseFilterData = localStorage.getItem('case_filter');
        if (caseFilterData) {
            const caseFilter = JSON.parse(caseFilterData);
            dispatch(ServiceActions.SetCaseFilter(caseFilter));
        } else {
            const defaultFilter = {
                category: [{ id: 0, checked: true }],
                type: [{ id: 0, checked: true }],
                product: [{ id: 0, checked: true }],
                status: [{ id: 0, checked: true }],
            };
            localStorage.setItem('case_filter', JSON.stringify(defaultFilter));
            dispatch(ServiceActions.SetCaseFilter(defaultFilter));
        }
    },
    GetProductCategoriesDictionary: () => (dispatch) => {
        Api.GetProductCategoriesList().then(({ data }) => {
            dispatch(ServiceActions.SetProductsCategoriesDictionary(data.list));
        });
    },
    SetCaseFilterDictionaries: (dictionaries) => ({
        type: 'SET_CASE_FILTER_DICTIONARIES',
        payload: dictionaries,
    }),
    GetCaseFilterDictionaries: (filter) => (dispatch) => {
        Api.GetCaseFilterDictionaries(filter).then((data) => {
            dispatch(
                ServiceActions.SetCaseFilterDictionaries({
                    ready: true,
                    ...data,
                })
            );
        });
    },
};
export default ServiceActions;
