const initialState = {
    locale: null,
    loginFB: true,
    currency: 'rub',
    isCustomInstances: false,
    lang: 'ru',
    allowRestrictEmailSignUp: false
}

export function rootReducer(state = initialState, {type, payload}) {
    switch (type) {
        case 'SET_SETTINGS':
            return {
                ...state,
                ...payload
            };
        default:
            return state;
    }
}
