import React from 'react';
import {useFormik} from 'formik';
import {Controlled as CodeMirror} from 'react-codemirror2';
import * as Yup from 'yup';
import {Title, Input, SelectDynamic, Select} from '@components';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import AddLinks from '../../containers/Admin/Links';

const getCurrencies = () => ({
    usd: '$',
    eur: '€',
    rub: '₽',
});

const getLocales = () => ({
    en: 'English',
    ru: 'Русский',
});

const FormSettingsAdmin = ({settings, locale, onSubmit}) => {

    const validationSchema = Yup.object({
        name: Yup.string()
            .max(512)
            .required(),
        settings: Yup.object({
            folderInCaseOne: Yup.string().max(512),
            itemsOnPage: Yup.string()
                .matches(/^\d+$/, locale.errors.numbersOnly)
                .required(),
        }),
    });

    var {values, errors, setFieldValue, handleChange, handleSubmit} = useFormik({
        initialValues: settings,
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    return (
        <form>
            <div className="b-form">
                <Title
                    title={locale.admin.menu.settings}
                    buttonText={locale.admin.popup.actions.save}
                    buttonClick={handleSubmit}
                    buttonIcon='save'
                />
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__3">
                        <Input
                            name="name"
                            placeholder={locale.admin.popup.settings.sitename}
                            value={values.name}
                            error={errors.name}
                            type="text"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__3">
                        <Input
                            name="settings.folderInCaseOne"
                            placeholder={locale.admin.popup.settings.folderInCaseOne}
                            value={values.settings.folderInCaseOne}
                            error={errors.settings?.folderInCaseOne}
                            type="text"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__2">
                        <Select
                            name="settings.currency"
                            placeholder={locale.admin.popup.settings.currency}
                            dataList={getCurrencies()}
                            value={values.settings.currency}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__2">
                        <Input
                            name="settings.itemsOnPage"
                            placeholder={locale.admin.popup.settings.itemsonpage}
                            value={values.settings.itemsOnPage}
                            error={errors.settings?.itemsOnPage}
                            type="text"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__2">
                        <Select
                            name="locale"
                            placeholder={locale.admin.popup.settings.locale}
                            dataList={getLocales()}
                            value={values.locale}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                </div>
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__3">
                        <SelectDynamic
                            url="/api/admin/v1.0/suggest/bot"
                            name="settings.consultationBot"
                            placeholder={locale.admin.menu.consultationBot}
                            setFieldValue={setFieldValue}
                            value={values.settings.consultationBot}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__2">
                        <SelectDynamic
                            url="/api/admin/v1.0/caseone/users"
                            name="settings.responsible"
                            placeholder={locale.admin.popup.settings.caseuser}
                            setFieldValue={setFieldValue}
                            value={values.settings.responsible}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__2">
                        <SelectDynamic
                            url="/api/admin/v1.0/caseone/users"
                            name="settings.taskUser"
                            placeholder={locale.admin.popup.settings.taskuser}
                            setFieldValue={setFieldValue}
                            value={values.settings.taskUser}
                        />
                    </div>
                    <div className="b-form-cell b-form-cell__2">
                        <Select
                            name="settings.removeCase"
                            placeholder={locale.admin.popup.settings.removeCase}
                            dataList={{
                                'true': locale.admin.popup.product.auto,
                                'false': locale.admin.popup.product.manual
                            }}
                            value={values.settings.removeCase}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                    <div className='b-form-cell b-form-cell__3'>
                        <Select
                            name="settings.archiveCase"
                            placeholder={locale.admin.popup.settings.archiveCase}
                            dataList={{
                                'true': locale.admin.popup.product.auto,
                                'false': locale.admin.popup.product.manual
                            }}
                            value={values.settings.archiveCase}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                </div>
            </div>

            <AddLinks />

            <div className="ld-form">
                <div className="b-form-title">
                    <Title title={locale.admin.popup.settings.headercode}/>
                </div>
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__12">
                        <CodeMirror
                            value={values.settings.headerCode}
                            options={{lineWrapping: true, lineNumbers: true, mode: 'html'}}
                            onBeforeChange={(editor, data, value) => {
                                setFieldValue('settings.headerCode', value);
                            }}
                            className="ld-input-code"
                        />
                    </div>
                </div>
            </div>

            <div className="ld-form">
                <div className="b-form-title">
                    <Title title={locale.admin.popup.settings.bodycode}/>
                </div>
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__12">
                        <CodeMirror
                            value={values.settings.bodyCode}
                            options={{lineWrapping: true, lineNumbers: true, mode: 'html'}}
                            onBeforeChange={(editor, data, value) => {
                                setFieldValue('settings.bodyCode', value);
                            }}
                            className="ld-input-code"
                        />
                    </div>
                </div>
            </div>
            <div className="ld-form">
                <div className="b-form-title">
                    <Title title={locale.admin.popup.settings.emailStyle}/>
                </div>
                <div className="b-form-row">
                    <div className="b-form-cell b-form-cell__12">
                        <CodeMirror
                            value={values.settings.emailStyle}
                            options={{lineWrapping: true, lineNumbers: true, mode: "htmlembedded"}}
                            onBeforeChange={(editor, data, value) => {
                                setFieldValue('settings.emailStyle', value);
                            }}
                            className="ld-input-code"
                        />
                    </div>
                </div>
            </div>
        </form>
    );
};

export default FormSettingsAdmin;
