import React, {Fragment} from 'react';
import {Input} from '@components';

const TableAdminLanguage = ({item, locale, localeKey, onChange}) => {
    return (
        <Fragment>
            <div className="b-form-row ng-scope">
                <div className="b-form-cell b-form-cell__4">
                    <div className="b-input ng-scope">
                        <label className="b-input-container">
                            <Input
                                value={item._id}
                                name={`locale[${localeKey}]._id`}
                                type="text"
                                placeholder={locale.admin.popup.settings.id}
                                disabled="disabled"
                            />
                        </label>
                    </div>
                </div>
                <div className="b-form-cell b-form-cell__8">
                    <div className="b-input ng-scope">
                        <label className="b-input-container">
                            <Input
                                value={item.value}
                                name={`locale[${localeKey}].value`}
                                type="text"
                                placeholder={locale.admin.popup.settings.value}
                                onChange={onChange}
                            />
                        </label>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default TableAdminLanguage;
