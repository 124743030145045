import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ServiceActions } from '@redux';

import { Formatter } from '@utils';

import {
    Card,
    Title,
    Table,
    Empty,
    HeaderList,
    DropdownChangeName,
    DropdownChangePassword,
    DropdownConfirm,
} from '@components';
import CaseFilterLoader from './CaseFilterLoader';

const Cases = ({
    locale,
    cases,
    archiveCases,
    lang,
    GetArchiveCases,
    configuration,
    DeleteCase,
    ToArchiveCase,
    ChangeVisibilityBot,
    visibilityBot,
    isReady,
}) => {
    const [casesList, setCases] = useState([]);
    const [casesArchiveList, setArchiveCases] = useState([]);
    const history = useHistory();
    const params = useParams();

    const [subactionShow, setSubactionsShow] = useState(false);
    const [subactionTemplate, setsubactionTemplate] = useState(null);

    const [dropdownState, setDropdownState] = useState(false);

    const dropItemHandler = (item) => {
        if (item.url) {
            console.log(item);
            window.location.assign(item.url);
        }
    };

    const goTo = useCallback(
        (url) => {
            history.push(url);
        },
        [history]
    );
    const setFilter = useCallback(
        (id) => {
            let casesFiltered;
            let casesArchiveFiltered;

            if (id) {
                casesFiltered = cases.filter((item) => item.product.metadata.category === id);
                casesArchiveFiltered = archiveCases.filter((item) => item.product.metadata.category === id);

                setCases(casesFiltered);
                setArchiveCases(casesArchiveFiltered);
            } else {
                goTo('/cases');
                setCases(cases);
                setArchiveCases(archiveCases);
            }
        },
        [archiveCases, cases, goTo]
    );
    const clickAskLawyer = () => {
        if (visibilityBot) {
            ChangeVisibilityBot(false);
        } else {
            ChangeVisibilityBot(true);
        }
    };
    useEffect(() => {
        GetArchiveCases();
    }, [GetArchiveCases]);

    useEffect(() => {
        if (params.categoryId) {
            setFilter(params.categoryId);
        } else {
            setCases(cases);
            setArchiveCases(archiveCases);
        }
    }, [cases, archiveCases, params.categoryId, setFilter]);

    useEffect(() => {
        document.title = locale ? locale.tabs.myCases : 'Legal Desk';
    }, [locale]);

    // TODO Сделать обновления статуса в карточке дела

    let optionsDropdown;

    if (locale) {
        optionsDropdown = {
            iconClass: 'b-icon b-icon__menu b-icon__menu_profile',
            isIcon: true,
            title: 'Actions',
            right: 0,
            subactionShow,
            subactionWidth: '320px',
            subactionTemplate,
            top: '100%',
            items: [
                {
                    title: locale.dropmenu.items.changeName,
                    isIcon: false,
                    subactionTemplate: (
                        <DropdownChangeName
                            locale={locale}
                            setDropdownState={setDropdownState}
                            configuration={configuration}
                            backOnClick={() => setSubactionsShow(false)}
                        />
                    ),
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    },
                },
                {
                    title: locale.dropmenu.items.changePassword,
                    isIcon: false,
                    subactionTemplate: (
                        <DropdownChangePassword
                            locale={locale}
                            setDropdownState={setDropdownState}
                            backOnClick={() => setSubactionsShow(false)}
                        />
                    ),
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    },
                },
                {
                    action: dropItemHandler,
                    url: '/admin/products',
                    isIcon: false,
                    title: locale.dropmenu.items.admin,
                    hidden: !configuration.user.isAdmin,
                },
                {
                    title: locale.dropmenu.items.logOut,
                    isIcon: false,
                    subactionTemplate: (
                        <DropdownConfirm
                            title={locale.dropmenu.items.logOut}
                            cancelText={locale.buttons.cancel}
                            saveText={locale.dropmenu.items.logOut}
                            message={locale.authentication.logout.confirm.replace(
                                /%name%/gi,
                                `<b>${configuration.user.first_name} ${configuration.user.last_name}</b>`
                            )}
                            backOnClick={() => setSubactionsShow(false)}
                            saveOnClick={() => {
                                setSubactionsShow(false);
                                setDropdownState(false);
                                window.location.href = '/authentication/account/logout';
                            }}
                        />
                    ),
                    action: (item) => {
                        setsubactionTemplate(item.subactionTemplate);
                        setSubactionsShow(true);
                    },
                },
            ],
        };
    }

    return (
        <div>
            {locale && locale.card && (
                <Fragment>
                    <HeaderList
                        dropdownState={dropdownState}
                        setDropdownState={setDropdownState}
                        setSubactionsShow={setSubactionsShow}
                        onClick={() => clickAskLawyer()}
                        nameBtn={locale.tabs.askLawyer}
                        activeBtn={visibilityBot}
                        tabs={[
                            {
                                active: true,
                                name: locale.tabs.myCases,
                                url: '/cases',
                            },
                            {
                                active: false,
                                name: locale.tabs.legalServices,
                                url: '/products',
                            },
                            {
                                active: false,
                                name: locale.tabs.profile,
                                url: '/profile',
                            },
                        ]}
                        optionsDropdown={optionsDropdown}
                    />
                    <div className={visibilityBot ? 'l-content l-content__fill' : 'l-content'}>
                        <div className="b-content m-list__padding">
                            <div className="b-content-card-wrapper">
                                <div className="b-filter">
                                    <ul className="b-filter-list">
                                        <CaseFilterLoader />
                                    </ul>
                                </div>
                                <Title
                                    buttonClick={() => goTo('/products')}
                                    buttonText={locale.card.newCase}
                                    title={locale.card.activeCases}
                                />
                                {isReady.cases && (
                                    <div className="b-block-card-wrapper">
                                        <ul className="b-columns">
                                            {casesList.length ? (
                                                casesList.map((item, index) => {
                                                    const dateRegExp = new RegExp("\\d{4}-\\d{2}-\\d{2}")
                                                    const createdAt = dateRegExp.test(item.createdAt)
                                                        ? Formatter.formatDate(item.createdAt, lang)
                                                        : ''
                                                    return (
                                                        <Card
                                                            locale={locale}
                                                            id={item._id}
                                                            date={createdAt}
                                                            number={item.number}
                                                            numberImage={item.stylization}
                                                            title={item.name}
                                                            typeImage={item.product.metadata.serviceName}
                                                            DeleteCase={DeleteCase}
                                                            ToArchiveCase={ToArchiveCase}
                                                            status={!item.customStatus ? '' : item.customStatus.name}
                                                        />
                                                    )
                                                })
                                            ) : (
                                                <Empty
                                                    title={locale.card.emptyCaseList}
                                                    text={locale.card.emptyCaseListDescription}
                                                />
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="b-archive">
                                {casesArchiveList.length ? (
                                    <h2 className="b-archive-title">
                                        {locale && locale.card && locale.card.archiveCases}
                                    </h2>
                                ) : (
                                    ''
                                )}
                                <ul className="b-archive-list">
                                    {casesArchiveList &&
                                        casesArchiveList.map((item, index) => {
                                            const dateRegExp = new RegExp("\\d{4}-\\d{2}-\\d{2}")
                                            const createdAt = dateRegExp.test(item.createdAt)
                                                ? Formatter.formatDate(item.createdAt, lang)
                                                : ''
                                            return (
                                                <Table
                                                    id={item._id}
                                                    created={createdAt}
                                                    name={item.name}
                                                    number={item.number}
                                                    status={!item.customStatus ? '' : item.customStatus.name}
                                                />
                                            )
                                        })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </div>
    );
};

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        cases: store.cases,
        archiveCases: store.archiveCases,
        lang: store.lang,
        casesCategories: store.casesCategories,
        configuration: store.configuration,
        visibilityBot: store.visibilityBot,
        isReady: store.isReady,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        GetArchiveCases: () => dispatch(ServiceActions.GetArchiveCases()),
        DeleteCase: (caseId) => dispatch(ServiceActions.DeleteCase(caseId)),
        ToArchiveCase: (caseId) => dispatch(ServiceActions.ToArchiveCase(caseId)),
        ChangeVisibilityBot: (visibilityBot) => dispatch(ServiceActions.ChangeVisibilityBot(visibilityBot)),
        ResetCasesCategoriesReady: () => dispatch(ServiceActions.SetCasesCategoriesReady(false)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cases);
