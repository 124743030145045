import React, {Fragment} from 'react';
import PropTypes from 'prop-types';


/**
 * Попап для сабмита какой-либо формы.
 * Сабмитит форму методом GET относительно текущего URL.
 *
 * @param title Название попапа
 * @param form Компонент формы
 * @param setOpenPopup функция установки видимости попапа
 * @param openPopup признак того, что попап открыт
 * @param onSubmit функция перехвата сабмита форма, полученная из Formik
 * @returns {JSX.Element}
 * @constructor
 */
const Popup = ({title, form, setOpenPopup, openPopup, onAdd, buttonAdd, buttonCancel, formik}) => {
    return (
        <Fragment>
            <div className={openPopup ? 'b-modal-backdrop open js-modal' : 'b-modal-backdrop js-modal'}>
                <div className="b-modal-container">
                    <div className="b-modal-wrapper">
                        <div className="b-modal">
                            <div className="b-modal-header">
                                <div className="b-modal-title">{title}</div>
                            </div>
                            <div className="b-modal-content">
                                <form name="submitForm">
                                    {form}
                                </form>
                            </div>
                            <div className="b-modal-actions">
                                <button className="b-button b-button__cancel" onClick={() => {
                                    if (formik) {
                                        formik.setValues(null);
                                        formik.resetForm({values: ''});
                                    }
                                    setOpenPopup(false);
                                }}>{buttonCancel}</button>
                                <button className="b-button" onClick={() => {
                                    onAdd();
                                }}>{buttonAdd}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

Popup.propTypes = PropTypes.shape({
    title: PropTypes.string.isRequired,
    locale: PropTypes.object.isRequired,
    form: PropTypes.element.isRequired,
    openPopup: PropTypes.bool.isRequired,
    setOpenPopup: PropTypes.func.isRequired,
});

export default Popup;
