import React from 'react';

/**
 *
 * @param title Название диалога
 * @param children Элементы для рендеринга внутри диалога
 * @param locale Объект с локализацией
 * @param onClose Хэндлер кнопки закрытия диалога. Этот хэндлер должен закрыть диалог
 * @param onSubmit Хэндлер нажатия кнопки сабмита диалога. Если передан, то показать соответствующую кнопку(по-умолчанию скрыта).
 * @param isOpen признак того, что диалог открыт
 * @param submitTitle Название кнопки сабмита
 * @returns {JSX.Element}
 * @constructor
 */
const Dialog = ({children, title, locale, isOpen, onClose, onSubmit, submitTitle}) => {
    return (
        <div className={isOpen ? 'b-modal-backdrop open js-modal' : 'b-modal-backdrop js-modal'}>
            <div className="b-modal-container">
                <div className="b-modal-wrapper">
                    <div className="b-modal">
                        <div className="b-modal-header">
                            <div className="b-modal-title">{title}</div>
                        </div>
                        <div className="b-modal-content">{children}</div>
                        <div className="b-modal-actions">
                            <button className="b-button b-button__cancel" onClick={onClose}>
                                {locale.admin.popup.actions.cancel}
                            </button>
                            {onSubmit && (
                                <button className="b-button" type="button" onClick={onSubmit}>
                                    {submitTitle || locale.buttons.add}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dialog;
