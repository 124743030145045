import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormSettingsAdmin, Spinner} from '@components';

import {AdminActions, localeSelector, settingsSelector} from '@redux';

const Settings = () => {
    const dispatch = useDispatch();
    const [visibilitySpinner, setVisibilitySpinner] = useState(false);

    useEffect(() => {
        dispatch(AdminActions.GetAdminSettings());
    }, [dispatch]);

    const locale = useSelector(localeSelector);
    const settings = useSelector(settingsSelector);

    const onSubmit = (values) => {
        setVisibilitySpinner(true);
        dispatch(AdminActions.UpdateSettings(values))
            .finally(() => {
                setTimeout(() => {
                    setVisibilitySpinner(false);
                    window.location.reload();
                }, 500);
            });
    };

    return (
        <Fragment>
            {settings && locale && locale.card && (
                <>
                    <FormSettingsAdmin settings={settings} locale={locale} onSubmit={onSubmit}/>
                    {visibilitySpinner && (
                        <Spinner title={locale.card.loading}/>
                    )}
                </>
            )}
        </Fragment>
    );
};

export default Settings;
