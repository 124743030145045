import {Api} from '@utils';

const AuthActions = {
    SetSettings: (settings) => ({
        type: 'SET_SETTINGS',
        payload: settings
    }),
    GetSettings: () => dispatch => {
        Api.GetSettings().then(({data}) => {
            dispatch(AuthActions.SetSettings(data));
        })
    }
}

export default AuthActions;
