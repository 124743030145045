import { Api } from '@utils';

const AdminActions = {
    SetSettings: (settings) => ({
        type: 'SET_SETTINGS',
        payload: settings,
    }),
    GetSettings: () => (dispatch) => {
        Api.GetSettings().then(({ data }) => {
            dispatch(AdminActions.SetSettings(data));
        });
    },
    SetLinks: (links) => ({
        type: 'SET_LINKS',
        payload: links,
    }),
    GetLinks: () => (dispatch) => {
        Api.GetLinks().then(({ data }) => {
            dispatch(AdminActions.SetLinks(data))
        })
    },
    CreateLink: (link) => async (dispatch) => {
        try {
            await Api.UpdateLink(link);
            dispatch(AdminActions.GetLinks());
        } catch (error) {
            // TODO: Handle create group exception
        }
    },
    SetDeleteLink: (linkId) => ({
        type: 'SET_DELETE_LINK',
        payload: linkId
    }),
    DeleteLink: (linkId) => (dispatch) => {
        Api.DeleteLink(linkId).then(() => {
            dispatch(AdminActions.SetDeleteLink(linkId));
        })
    },
    SetInsertLink: (link) => ({
        type: 'SET_INSERT_LINK',
        payload: link,
    }),
    InsertLink: (values) => (dispatch) => {
        Api.InsertLink(values).then(({ data }) => {
            dispatch(AdminActions.SetInsertLink(data));
        });
    },
    SetProducts: (products) => ({
        type: 'SET_PRODUCTS',
        payload: products,
    }),
    GetProducts: () => (dispatch) => {
        Api.GetAdminProducts().then(({ data }) => {
            dispatch(AdminActions.SetProducts(data));
        });
    },
    SetUsers: (users) => ({
        type: 'SET_USERS',
        payload: users,
    }),
    GetUsers: (page, limit) => (dispatch) => {
        Api.GetUsers(page, limit).then(({ data }) => {
            dispatch(AdminActions.SetUsers(data));
        });
    },
    SetGroups: (groups) => ({
        type: 'SET_GROUPS',
        payload: groups,
    }),
    GetGroups: () => (dispatch) => {
        Api.GetGroups().then(({ data }) => {
            dispatch(AdminActions.SetGroups(data));
        });
    },
    SetCategories: (categories) => ({
        type: 'SET_CATEGORIES',
        payload: categories,
    }),
    GetCategories: () => (dispatch) => {
        Api.GetCategories().then(({ data }) => {
            dispatch(AdminActions.SetCategories(data));
        });
    },
    SetBotsAndPatterns: (botsAndPatterns) => ({
        type: 'SET_BOTS_AND_PATTERNS',
        payload: botsAndPatterns,
    }),
    GetBotsAndPatterns: () => (dispatch) => {
        Api.GetBotsAndPatterns().then(({ data }) => {
            dispatch(AdminActions.SetBotsAndPatterns(data));
        });
    },
    SetLanguage: (language) => ({
        type: 'SET_LANGUAGE',
        payload: language,
    }),
    GetLanguage: () => (dispatch) => {
        Api.GetLanguage().then(({ data }) => {
            dispatch(AdminActions.SetLanguage(data));
        });
    },
    SetBlocks: (blocks) => ({
        type: 'SET_BLOCKS',
        payload: blocks,
    }),
    GetBlocks: () => (dispatch) => {
        Api.GetBlocks().then(({ data }) => {
            dispatch(AdminActions.SetBlocks(data));
        });
    },
    SetAdminSettings: (settings) => ({
        type: 'SET_ADMIN_SETTINGS',
        payload: settings,
    }),
    GetAdminSettings: () => (dispatch) => {
        Api.GetAdminSettings().then(({ data }) => {
            dispatch(AdminActions.SetAdminSettings(data));
        });
    },
    SetUser: (user) => ({
        type: 'SET_USER',
        payload: user,
    }),
    UpdateUser: (user) => async (dispatch) => {
        try {
            const response = await Api.UpdateUser(user);
            dispatch(AdminActions.SetUser(response.data));
        } catch (error) {
            // TODO: Handle update user exception
        }
    },
    GetUser: (userId) => async (dispatch) => {
        try {
            const response = await Api.GetUser(userId);
            dispatch(AdminActions.SetUser(response.data));
        } catch (error) {
            // TODO: Handle read user exception
        }
    },
    SetUserCases: (userCases) => ({
        type: 'SET_USER_CASES',
        payload: userCases,
    }),
    GetUserCases: (userId) => async (dispatch) => {
        try {
            const response = await Api.GetUserCases(userId);
            dispatch(AdminActions.SetUserCases(response.data));
        } catch (error) {
            // TODO: Handle read cases exception
        }
    },
    GetGroup: (groupId) => async (dispatch) => {
        try {
            const response = await Api.GetGroup(groupId);
            dispatch(AdminActions.SetGroup(response.data));
        } catch (error) {
            // TODO: Handle read group exception
        }
    },
    GetUsersGroups: (id, page, limit) => async (dispatch) => {
        try {
            const response = await Api.GetUsersGroups(id, page, limit);
            dispatch(AdminActions.SetUsersGroups(response.data));
        } catch (error) {
            // TODO: Handle read users groups exception
        }
    },
    GetProductsGroups: () => async (dispatch) => {
        try {
            const response = await Api.GetProductsGroups();
            dispatch(AdminActions.SetProductsGroups(response.data));
        } catch (error) {
            // TODO: Handle read products groups exception
        }
    },
    SetGroup: (group) => ({
        type: 'SET_GROUP',
        payload: group,
    }),
    SetUsersGroups: (usersGroups) => ({
        type: 'SET_USERS_GROUPS',
        payload: usersGroups,
    }),
    SetProductsGroups: (productsGroups) => ({
        type: 'SET_PRODUCTS_GROUPS',
        payload: productsGroups,
    }),
    UpdateGroup: (group) => async (dispatch) => {
        try {
            const response = await Api.UpdateGroup(group);
            dispatch(AdminActions.SetGroup(response.data));
        } catch (error) {
            // TODO: Handle update group exception
        }
    },
    UpdateUserGroup: (userId, groupId, onUpdate) => async () => {
        try {
            await Api.UpdateUserGroup(userId, groupId);
            onUpdate && onUpdate();
        } catch (error) {
            // TODO: Handle assign user to group exception
        }
    },
    DeleteUserGroup: (userId, onDelete) => async () => {
        try {
            await Api.UpdateUserGroup(userId, null);
            onDelete && onDelete();
        } catch {
            // TODO: Handle error on delete user from group
        }
    },
    CreateGroup: (group) => async (dispatch) => {
        try {
            await Api.UpdateGroup(group);
            dispatch(AdminActions.GetGroups());
        } catch (error) {
            // TODO: Handle create group exception
        }
    },
    DeleteGroup: (groupId) => async (dispatch) => {
        try {
            await Api.DeleteGroup(groupId);
            dispatch(AdminActions.GetGroups());
        } catch (error) {
            // TODO: Handle delete group exception
        }
    },
    SetDeleteProduct: (productId) => ({
        type: 'SET_DELETE_PRODUCT',
        payload: productId,
    }),
    DeleteProduct: (productId) => (dispatch) => {
        Api.DeleteProduct(productId).then(() => {
            dispatch(AdminActions.SetDeleteProduct(productId));
        });
    },
    UpdateSettings: (settings) => async () => {
        try {
            await Api.UpdateAdminSettings(settings);
        } catch (error) {
            // TODO: Handle update settings exception
        }
    },
    SetInsertProduct: (product) => ({
        type: 'SET_INSERT_PRODUCT',
        payload: product,
    }),
    InsertProduct: (values) => (dispatch) => {
        Api.InsertProduct(values).then(({ data }) => {
            dispatch(AdminActions.SetInsertProduct(data));
        });
    },
    UpdateLanguage: (language, list) => async (dispatch) => {
        try {
            await Api.UpdateLanguage(language, list).then(({ data }) => {
                dispatch(AdminActions.SetLanguage(data));
            });
        } catch (error) {
            // TODO: Handle update language exception
        }
    },
    SetDeleteCategory: (categoryId) => ({
        type: 'SET_DELETE_CATEGORY',
        payload: categoryId,
    }),
    DeleteCategory: (categoryId) => (dispatch) => {
        Api.DeleteCategory(categoryId).then(() => {
            dispatch(AdminActions.SetDeleteCategory(categoryId));
        });
    },
    SetInsertCategory: (category) => ({
        type: 'SET_INSERT_CATEGORY',
        payload: category,
    }),
    InsertCategory: (values) => (dispatch) => {
        Api.InsertCategory(values).then(({ data }) => {
            dispatch(AdminActions.SetInsertCategory(data));
        });
    },
    ImportUsers: (files, onSuccess) => async () => {
        try {
            await Api.ImportUsers(files);
            onSuccess();
        } catch {
            // TODO: Handle import users errors
        }
    },
    SetProductsCategories: (productsCategories) => ({
        type: 'SET_PRODUCTS_CATEGORIES',
        payload: productsCategories,
    }),
    SetProductsCategoriesReady: (isReady) => ({
        type: 'SET_PRODUCTS_CATEGORIES_READY',
        payload: isReady,
    }),
    GetProductsCategories: () => (dispatch) => {
        dispatch(AdminActions.SetProductsCategoriesReady(false));
        Api.GetProductsCategories().then(({ data }) => {
            dispatch(AdminActions.SetProductsCategories(data));
        });
    },

    // Поиск пользователей
    SearchUsers: (pattern, page, limit) => (dispatch) => {
        Api.SearchUsers(pattern, page, limit)
            .then(({ data }) => {
                dispatch(AdminActions.SetUsers(data));
            })
            .catch((searchError) => {
                console.log(searchError);
            });
    },
};

export default AdminActions;
