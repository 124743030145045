import axios from './axios';

const categoriesEndpoint = '/api/admin/v1.0/categories';
const casesEndpoint = '/api/v1.0/cases';

const buildRequestQueryParameters = (values, name) => {
    return values.reduce((result, value) => [...result, `${name}[]=${value}`], []).join('&');
};

/**
 * Функция перевода объекта с id в массив идентификаторов.
 * Используется в .reduce массива с объектами.
 *
 * @param result
 * @param current
 * @returns {*[]|*}
 */
function transformFilterObjectToArrayId(result, current) {
    if (current.id !== 0 && current.checked) {
        return [...result, current.id];
    } else {
        return result;
    }
}

/**
 * Формирует список продуктов, которые выбраны в фильтры, для передачи в вызов API.
 *
 * @param product Фильтр продуктов
 * @param products Справочник продуктов
 * @returns {any[]}
 */
function getStatusDictionaryByProduct(product, products) {
    const dictionaryMap = new Map();
    product.forEach((productFilterItem) => {
        if (productFilterItem.id !== 0 && productFilterItem.checked) {
            const checkedProductEntity = products.find((productEntity) => productFilterItem.id === productEntity.id);
            if (checkedProductEntity?.statusField?.dictionary) {
                dictionaryMap.set(checkedProductEntity.statusField?.dictionary, true);
            }
        }
    });
    return Array.from(dictionaryMap.keys());
}

const Api = {
    /**
     * Authorisation API methods
     */
    GetSettings: () => axios.get('/api/settings'),
    Login: (values) => axios.post('/api/authentication/account/login', values),
    SignUp: (values) => axios.post('/api/authentication/account/signup', values),
    Reset: (values) => axios.post('/api/account/fogot', values),
    Recovery: (values, key) => axios.post('/api/authentication/account/password/recovery/' + key, values),

    /**
     * Service API methods
     */
    GetCases: (searchQuery) => {
        return axios.get(`/api/v1.0/cases/active?${searchQuery}`, { withCredentials: true });
    },
    GetCaseItem: (id) => axios.get(`${casesEndpoint}/${id}`),
    GetCaseSummary: (id) => axios.get(`${casesEndpoint}/${id}/summaryData`),
    ChangeTaskStatus: (taskId) => axios.get('/api/v1.0/task/' + taskId + '/changeStatus'),
    GetArchiveCases: () => axios.get('/api/v1.0/cases/archive', { withCredentials: true }),
    GetCaseServiceData: (id, documentFolderId) => axios.get(`${casesEndpoint}/${id}/${documentFolderId}/serviceData`),
    GetCasesCategories: () => axios.get('/api/v1.0/caseCategory'),
    GetProductsCategories: () => axios.get('/api/v1.0/category'),
    GetProfileSummary: () => axios.get('/api/v1.0/cases/profileSummaryData'),
    DeleteCase: (caseId) => axios.get(`${casesEndpoint}/${caseId}/delete`),
    ToArchiveCase: (caseId) => axios.get(`${casesEndpoint}/${caseId}/archive`),
    ChangeUserName: (values) => axios.post('/api/v1.0/user/changeName', values),
    ChangeUserPassword: (values) => axios.post('/api/v1.0/user/changePassword', values),

    UploadFileToFolder: (options) => axios.post('/api/v1.0/uploads', options),
    DeleteFile: (id) => axios.get('/api/v1.0/fileDelete/' + id),

    // Методы получения словарей для фильтра дел
    GetProductTypeList: () => axios.get('/api/v1.0/dictionaries/case-filter/product-types'),
    GetProductListByCategoryAndType: (category, type) => {
        const queryParams = [
            category?.length > 0 && buildRequestQueryParameters(category, 'category'),
            type?.length > 0 && buildRequestQueryParameters(type, 'type'),
        ]
            .filter((queryParam) => queryParam)
            .join('&');
        const url = `/api/v1.0/dictionaries/case-filter/products?${queryParams}`;
        return axios.get(url);
    },
    GetProductStatusList: (dictionary) => {
        const queryParams = buildRequestQueryParameters(dictionary, 'dictionary');
        return axios.get(`/api/v1.0/dictionaries/case-filter/product-statuses/?${queryParams}`);
    },
    GetProductCategoriesList: () => axios.get('/api/v1.0/dictionaries/case-filter/product-categories'),
    GetCaseFilterDictionaries: async (filters) => {
        const types = await Api.GetProductTypeList();
        const categories = await Api.GetProductCategoriesList();
        const categoryId = filters.category.reduce(transformFilterObjectToArrayId, []);
        const typeId = filters.type.reduce(transformFilterObjectToArrayId, []);
        const products = await Api.GetProductListByCategoryAndType(categoryId, typeId);

        const productId = getStatusDictionaryByProduct(filters.product, products.data.list);

        const statuses = await Api.GetProductStatusList(productId);

        const result = {
            productCategories: [...categories.data.list],
            productTypes: [...types.data.list],
            productStatuses: [...statuses.data.list],
            products: [...products.data.list],
        };

        return result;
    },

    /**
     * Admin API methods
     */
    GetUsers: (page, limit) => axios.get(`/api/admin/v1.0/users?page=${page}&limit=${limit}`),
    GetGroups: () => axios.get('/api/admin/v1.0/group'),
    GetLinks: () => axios.get('/api/admin/v1.0/link'),
    DeleteLink: (linkId) => axios.delete(`/api/admin/v1.0/links/${linkId}`),
    UpdateLink: (link) => axios.post('/api/admin/v1.0/links', link),
    GetLink: (linkId) => axios.get(`/api/admin/v1.0/links/${linkId}`),
    InsertLink: (values) => axios.post('/api/admin/v1.0/links', values),

    GetCategories: () => axios.get('/api/admin/v1.0/category'),
    // GetProductsCategories: () => axios.get('/api/v1.0/productCategory'),
    GetLanguage: () => axios.get('/api/admin/v1.0/language'),
    GetBlocks: () => axios.get('/api/admin/v1.0/suggest/practiceArea'),
    GetAdminSettings: () => axios.get('/api/admin/v1.0/settings'),
    GetProducts: () => axios.get('/api/v1.0/products'),
    GetAdminProducts: () => axios.get('/api/admin/v1.0/products'),
    CreateCase: (productId) => axios.get('/api/v1.0/stripe/products/' + productId + '/create_case'),
    GetUser: (userId) => axios.get(`/api/admin/v1.0/users/${userId}`),
    UpdateUser: (user) => axios.post(`/api/admin/v1.0/user`, user),
    GetUserCases: (userId) => axios.get(`/api/admin/v1.0/cases/${userId}`),
    GetGroup: (groupId) => axios.get(`/api/admin/v1.0/group/${groupId}`),
    GetUsersGroups: (groupId, page, limit) =>
        axios.get(`/api/admin/v1.0/users/groups/${groupId}?page=${page}&limit=${limit}`),
    GetProductsGroups: () => axios.get('/api/admin/v1.0/products/groups'),
    UpdateGroup: (group) => axios.post('/api/admin/v1.0/group', group),
    DeleteProduct: (productId) => axios.delete('/api/admin/v1.0/products/' + productId),
    GetProduct: (productId) => axios.get('/api/admin/v1.0/products/' + productId),
    UpdateProduct: (values) => axios.post('/api/admin/v1.0/products', values),
    UpdateProductPosition: (productId, product) => axios.post(`/api/v1.0/admin/product/${productId}`, product),
    InsertProduct: (values) => axios.post('/api/admin/v1.0/products', values),
    DeleteGroup: (groupId) => axios.delete(`/api/admin/v1.0/group/${groupId}`),
    UpdateUserGroup: (userId, groupId) =>
        axios.post('/api/admin/v1.0/updateUserGroup', {
            groupId,
            _id: userId,
        }),
    UpdateAdminSettings: (settings) => axios.post('/api/admin/v1.0/settings', settings),
    UpdateLanguage: (lang, list) =>
        axios.post('/api/admin/v1.0/language', {
            lang,
            list,
        }),
    GetSubBlocks: (blockId) => axios.get('/api/admin/v1.0/blocks/' + blockId),
    UpdateSubBlocks: (blocks, blockId) => axios.post('/api/admin/v1.0/blocks/' + blockId, blocks),
    DeleteCategory: (categoryId) => axios.delete(`${categoriesEndpoint}/${categoryId}`),
    GetCategory: (categoryId) => axios.get(`${categoriesEndpoint}/${categoryId}`),
    UpdateCategory: (category) => axios.post(categoriesEndpoint, category),
    InsertCategory: (values) => axios.post(categoriesEndpoint, values),
    ImportUsers: (files) => {
        const formData = new FormData();
        formData.append('usersFile', files[0]);
        return axios.post('/api/admin/v1.0/users', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    },
    GetBotsAndPatterns: () => axios.get('/api/admin/v1.0/getBotsAndPatterns'),
    SearchUsers: (pattern, page, limit) =>
        axios.get(`/api/admin/v1.0/users/search?page=${page}&search=${pattern}&limit=${limit}`),
};

export default Api;
