import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
    localeSelector,
    userSelector,
    userCasesSelector,
    AdminActions,
    activeDirectoryConfigurationSelector,
} from '@redux';
import FormUserAdmin from '../../components/Forms/FormUserAdmin';

const User = () => {
    const dispatch = useDispatch();
    const {id} = useParams();

    useEffect(() => {
        dispatch(AdminActions.GetUser(id));
        dispatch(AdminActions.GetUserCases(id));
    }, [dispatch, id]);

    useEffect(() => {
        return () => {
            dispatch(AdminActions.SetUser(null));
        };
    }, [dispatch]);

    const locale = useSelector(localeSelector);
    const user = useSelector(userSelector);
    const userCases = useSelector(userCasesSelector);
    const activeDirectoryConfiguration = useSelector(activeDirectoryConfigurationSelector);

    const isFormDataReady = user && userCases && activeDirectoryConfiguration;

    return (
        <>
            {isFormDataReady && (
                <FormUserAdmin
                    locale={locale}
                    user={user}
                    userCases={userCases}
                    isActiveDirectoryUser={activeDirectoryConfiguration.active}
                />
            )}
        </>
    );
};

export default User;
