import React, {Fragment, useState, useRef, useEffect} from 'react';
import {useOutsideClick} from '@utils';

const Select = ({placeholder, dataList, value, setFieldValue, name, disabled}) => {
    const ref = useRef();
    const refButton = useRef();
    const [selectValue, setSelectValue] = useState(value);
    const [selectActive, setSelectActive] = useState(false);

    useEffect(() => {
        setSelectValue(value + '');
    }, [value]);

    // Хук для обработки клика вне элемента
    useOutsideClick(ref, refButton, selectActive, () => {
        setSelectActive(false);
    });

    return (
        <Fragment>
            <div ref={refButton} onClick={() => {
                if (disabled) {
                    return false;
                }
                setSelectActive(!selectActive);
            }}
                 className={selectValue ? (selectActive ? 'b-selectbox edit active not-empty' : 'b-selectbox edit not-empty') : (selectActive ? 'b-selectbox active' : 'b-selectbox')}>
                <i className="b-icon b-icon__down"/>
                {selectValue && (
                    <div className="b-selectbox-subtitle">{placeholder}</div>
                )}
                <div className="b-selectbox-title js-selectbox-title">
                    {Object.keys(dataList).length ? Object.keys(dataList).map((item) => item === selectValue ? dataList[item] : '') : placeholder}
                </div>
                <div className="b-input-list" ref={ref}>
                    <ul className="b-input-list-inner">
                        {Object.keys(dataList).length ? Object.keys(dataList).map((item, index) => (
                            <li key={index}
                                className={item === selectValue ? 'b-input-list-item active' : 'b-input-list-item'}
                                onClick={() => {
                                    setSelectValue(item);
                                    setFieldValue(name, item);
                                }}>{dataList[item]}</li>
                        )) : (
                            <div className="b-selectbox-list-empty">No matches</div>
                        )}
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default Select;
