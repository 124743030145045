import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {AdminActions, groupSelector, productsGroupsSelector, usersGroupsSelector, localeSelector} from '@redux';
import FormGroupAdmin from '../../components/Forms/FormGroupAdmin';

export const Group = () => {
    const [usersPage, setUsersPage] = useState(1);
    const onNextPageClick = (event, page) => setUsersPage(page);
    const onPrevPageClick = (event, page) => setUsersPage(page);

    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(AdminActions.GetGroup(id));
        dispatch(AdminActions.GetProductsGroups());
    }, [dispatch, id]);

    const limit = 15; // Количество пользователей на странице в таблице
    useEffect(() => {
        dispatch(AdminActions.GetUsersGroups(id, usersPage, limit));
    }, [dispatch, id, usersPage]);

    const locale = useSelector(localeSelector);
    const group = useSelector(groupSelector);
    const {list: usersGroups, itemsOnPage: usersItemsOnPage, pages: usersPages, total: usersTotal} = useSelector(
        usersGroupsSelector
    );
    const productsGroups = useSelector(productsGroupsSelector);

    const onSubmit = (values) => {
        dispatch(AdminActions.UpdateGroup(values));
    };

    const refreshUsersGroup = () => {
        dispatch(AdminActions.GetUsersGroups(id, usersPage, limit));
    };

    const addUserToGroup = (values) => {
        const {user} = values;
        dispatch(
            AdminActions.UpdateGroup({
                ...group,
                users: [...group.users, user._id],
            })
        );
        dispatch(AdminActions.UpdateUserGroup(user._id, id, refreshUsersGroup));
    };

    const deleteUserFromGroup = (userId) => {
        dispatch(
            AdminActions.UpdateGroup({
                ...group,
                users: [...group.users.filter((id) => id !== userId)],
            })
        );
        dispatch(AdminActions.DeleteUserGroup(userId, refreshUsersGroup));
    };

    const isFormDataLoaded = group && locale && usersGroups && productsGroups;

    return (
        <>
            {isFormDataLoaded && (
                <FormGroupAdmin
                    group={group}
                    locale={locale}
                    usersGroups={usersGroups}
                    productsGroups={productsGroups}
                    onSubmit={onSubmit}
                    addUserToGroup={addUserToGroup}
                    deleteUserFromGroup={deleteUserFromGroup}
                    itemsOnUsersPage={usersItemsOnPage}
                    totalUsers={usersTotal}
                    totalUsersPage={usersPages}
                    usersPage={usersPage}
                    onPrevPageClick={onPrevPageClick}
                    onNextPageClick={onNextPageClick}
                />
            )}
        </>
    );
};

export default Group;
