import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import {
    ProductsContainer,
    UsersContainer,
    GroupsContainer,
    CategoriesContainer,
    LanguageContainer,
    BlocksContainer,
    SettingsContainer,
    UserContainer,
    GroupContainer,
} from '@containers';

const AdminRoutes = (
    <Switch>
        <Route path="/products" exact component={ProductsContainer} />
        <Route path="/products/type/:categoryId" exact component={ProductsContainer} />
        <Route path="/users/list/:page" component={UsersContainer} />
        <Route path="/users/:id/card" component={UserContainer} />
        <Route exact path="/groups" component={GroupsContainer} />
        <Route path="/groups/:id/card" component={GroupContainer} />
        <Route path="/categories" component={CategoriesContainer} />
        <Route path="/language" component={LanguageContainer} />
        <Route path="/blocks" component={BlocksContainer} />
        <Route path="/settings" component={SettingsContainer} />
        <Redirect from="/" to="/products" />
    </Switch>
);

export default AdminRoutes;
