import React, {Fragment, useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {
    Title,
    TableAdmin,
    Popup,
    FormBlocksAdmin,
    Spinner
} from '@components';

import {Api} from '@utils';
import {AdminActions} from '@redux';

const Blocks = ({GetBlocks, locale, lang, blocks}) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [titlePopUp, setTitlePopUp] = useState(false);
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState(false);

    const GetSubBlocks = (blockId) => {
        setList(false);
        Api
            .GetSubBlocks(blockId)
            .then(({data}) => {
                setLoading(false);
                setList(data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const UpdateSubBlocks = (blocks, blockId) => {
        Api
            .UpdateSubBlocks(blocks, blockId)
            .then(({data}) => {
                setOpenPopup(false);
                setList(data);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        GetBlocks()
    }, [GetBlocks]);

    return (
        <Fragment>
            {locale && locale.card && (
                <div>
                    <Title
                        title={locale.admin.menu.blocks}
                    />
                    {blocks ? blocks.map(item => (
                        <TableAdmin
                            data={{
                                name: {
                                    type: 'title',
                                    data: item.name
                                }
                            }}
                            lang={lang}
                            onClick={() => {
                                setTitlePopUp(item.name);
                                setOpenPopup(true);
                                setLoading(true);
                                GetSubBlocks(item._id);
                            }}
                        />
                    )) : (
                        <Spinner
                            title={locale.card.loading}
                        />
                    )}
                    <Popup
                        form={<FormBlocksAdmin
                            locale={locale}
                            loading={loading}
                            list={list}
                        />}
                        title={titlePopUp}
                        locale={locale}
                        openPopup={openPopup}
                        setOpenPopup={setOpenPopup}
                        buttonAdd={locale.buttons.save}
                        buttonCancel={locale.admin.popup.actions.cancel}
                        onAdd={() => {
                            UpdateSubBlocks(list, list._id);
                        }}
                    />
                </div>
            )}
        </Fragment>
    )
}

const mapStateToProps = (store) => {
    return {
        locale: store.locale,
        lang: store.lang,
        blocks: store.blocks
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        GetBlocks: () => dispatch(AdminActions.GetBlocks())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Blocks);
