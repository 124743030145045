const initialState = {
    locale: null,
    currency: 'rub',
    isCustomInstances: false,
    lang: 'ru',
    products: null,
    productsCategories: [],
    users: null,
    groups: null,
    links: null,
    categories: null,
    language: null,
    blocks: null,
    settings: null,
    botsAndPatterns: null,
    admin: {
        users: {
            list: null,
        },
        user: null,
        userCases: null,
        group: null,
        usersGroups: {
            list: null,
        },
        productsGroups: null,
    },
    isReady: {
        productsCategories: false,
    },
};

export function rootReducer(state = initialState, {type, payload}) {
    switch (type) {
        case 'SET_SETTINGS':
            return {
                ...state,
                ...payload,
            };
        case 'SET_PRODUCTS':
            return {
                ...state,
                products: payload.list,
            };
        case 'SET_USERS':
            return {
                ...state,
                admin: {
                    ...state.admin,
                    users: {
                        ...payload,
                    },
                },
            };
        case 'SET_GROUPS':
            return {
                ...state,
                groups: payload.list,
            };
        case 'SET_LINKS':
            return {
                ...state,
                links: payload.list
            };
        case 'SET_DELETE_LINK':
            return {
                ...state,
                links: state.links.filter((item) => item._id !== payload),
            }
        case 'SET_INSERT_LINK':
            payload = [payload];
            return {
                ...state,
                links: [...state.links, ...payload],
            };
        case 'SET_BOTS_AND_PATTERNS':
            return {
                ...state,
                botsAndPatterns: payload.list,
            };
        case 'SET_CATEGORIES':
            return {
                ...state,
                categories: payload.list,
            };
        case 'SET_LANGUAGE':
            return {
                ...state,
                language: payload.list,
            };
        case 'SET_BLOCKS':
            return {
                ...state,
                blocks: payload.list,
            };
        case 'SET_ADMIN_SETTINGS':
            return {
                ...state,
                settings: payload,
            };
        case 'SET_USER':
            return {
                ...state,
                admin: {
                    ...state.admin,
                    user: payload,
                },
            };
        case 'SET_USER_CASES':
            return {
                ...state,
                admin: {
                    ...state.admin,
                    userCases: payload.list,
                },
            };
        case 'SET_GROUP':
            return {
                ...state,
                admin: {
                    ...state.admin,
                    group: payload,
                },
            };
        case 'SET_USERS_GROUPS':
            return {
                ...state,
                admin: {
                    ...state.admin,
                    usersGroups: {
                        ...payload,
                    },
                },
            };
        case 'SET_PRODUCTS_GROUPS':
            return {
                ...state,
                admin: {
                    ...state.admin,
                    productsGroups: payload.list,
                },
            };
        case 'SET_DELETE_PRODUCT':
            return {
                ...state,
                products: state.products.filter((item) => item._id !== payload),
            };
        case 'SET_INSERT_PRODUCT':
            payload = [payload];
            return {
                ...state,
                products: [...state.products, ...payload],
            };
        case 'SET_DELETE_CATEGORY':
            return {
                ...state,
                categories: state.categories.filter((item) => item._id !== payload),
            };
        case 'SET_INSERT_CATEGORY':
            payload = [payload];
            return {
                ...state,
                categories: [...state.categories, ...payload],
            };
        case 'SET_PRODUCTS_CATEGORIES':
            return {
                ...state,
                productsCategories: payload.list,
                isReady: {
                    ...state.isReady,
                    productsCategories: true,
                },
            };
        case 'SET_PRODUCTS_CATEGORIES_READY':
            return {
                ...state,
                isReady: {
                    ...state.isReady,
                    productsCategories: payload,
                },
            };
        default:
            return state;
    }
}
