import {Api} from '@utils';

const AuthADActions = {
    SetSettings: (settings) => ({
        type: 'SET_SETTINGS',
        payload: settings
    }),
    GetSettings: () => dispatch => {
        Api.GetSettings().then(({data}) => {
            dispatch(AuthADActions.SetSettings(data));
        })
    }
}

export default AuthADActions;
