import React, {Fragment} from 'react';

const Bot = ({configuration, isVisibleBot, isNotFixed, isBotOnly, caseDialogId}) => {
    let botUrl = 'https://' + configuration['form.one'] + '/embed/dialog/' + configuration.user.consultationDialogId + '?show_header=0';

    if (caseDialogId) {
        botUrl = 'https://' + configuration['form.one'] + '/embed/dialog/' + caseDialogId + '?show_header=0';
    }
    return (
        <Fragment>
            {isNotFixed ? (
                <iframe title="title1" className="b-bot-frame ask-layer" src={botUrl}/>
            ) : isBotOnly ? (
                <div className="b-main-content b-main-content__full">
                    <iframe title="title2" className="b-bot-frame" src={botUrl}/>
                </div>
            ) : (
                <div className={isVisibleBot ? 'b-bot__wrapper b-bot__wrapper--visible' : 'b-bot__wrapper'}>
                    <iframe title="title3" className="b-bot-frame ask-layer teeeeeest" src={botUrl}/>
                </div>
            )}
        </Fragment>
    )
}

export default Bot;
