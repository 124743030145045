import React, {Fragment} from 'react';

const TableFiles = ({file, deleteFile, index, locale, style}) => {
    return (
        <Fragment>
            <li className="b-list-item b-list-item__folder" style={style ? style : {}}>
                <a className="b-list-item-link" href={'/api/v1.0/download/' + file._id}>
                    <i className="b-icon b-icon__doc"/>
                    <h4 className="b-list-item-title">
                        {file.name}
                    </h4>
                </a>
                <span className="b-link b-link__right" onClick={() => deleteFile()}>
                    {locale.documents.delete}
                </span>
            </li>
        </Fragment>
    )
}

export default TableFiles;
